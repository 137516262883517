export function displayTitle(str) {
    if (!str || str === '') return ''
    let list = str.split(/[_]|[-]|[ ]/);
    let ret = [];
    if (list.length === 1) {
        list = list[0].split('');
        list[0] = list[0].toUpperCase()
        return list.join('');
    }
    for (let i of list) {
        ret.push(displayTitle(i))
    }
    return ret.join(' ');
}

export function getInitials(str) {
    if (str === '') return '';
    let list = str.split(/[_]|[-]|[ ]/),
        ret = '';
    for (let w of list)
        if (w[0]) {
            ret += w[0].toUpperCase();
        }
    return ret;
}