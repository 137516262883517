import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'; //, useDispatch, 
import { Link } from 'react-router-dom';
import { getStaticData, getStaticPageDataById } from '../../actions/staticPages';
import axios from 'axios';
// import { GET_STATIC_DATA } from '../../actions/types';
// import { upload, searchUsers, searchCatalogs } from "../../actions/auth";


const StaticPages = ({ detail, getStaticData, getStaticPageDataById }) => {
     const [data, setData] = useState([detail]);
     // const dispatch = useDispatch();

     useEffect(() => {

          getStaticData();
          axios.get('/api/staticPages/staticPagesData')
               .then(res => setData(res.data.staticPages))
               .catch(e => console.log(e));
          const script = document.createElement("script");
          script.src = "assets/js/content.js";
          script.async = true;
          document.body.appendChild(script);

     }, [getStaticData]);

     const getID = (id) => {
          getStaticPageDataById(id);
     }

     return (
          <div className="container-fluid">
               <div className="row">
                    <div className="col-md-12">
                         <div className="card card-custom gutter-b">
                              <div className="card-header pt-4 border-0">
                                   <h3 className="text-primary">
                                        Static Pages
                                   </h3>
                              </div>
                              <div className="card-body">
                                   <div className="table-responsive">
                                        <table id="example2" className="table">
                                             <thead className=" text-primary">
                                                  <tr>
                                                       <th>Title</th>
                                                       <th>Modify </th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {data.map((da, index) => (
                                                       <tr key={index}>
                                                            <td><b>{da.title}</b></td>
                                                            <td>
                                                                 <Link
                                                                      className="btn btn-primary"
                                                                      to={{
                                                                           pathname: `./staticPageEditor`,
                                                                           // params: `${da.content}`,
                                                                           _id: `${da._id}`,
                                                                           // title: `${da.title}`,
                                                                           // metaData: `${da.metaData}`
                                                                      }}
                                                                      onClick={() => getID(da._id)}
                                                                 >
                                                                      <div className="d-flex align-content-center">
                                                                           <i className="fas fa-edit pr-2"></i>
                                                                           <span>Edit</span>
                                                                      </div>
                                                                      </Link>
                                                            </td>
                                                       </tr>
                                                  ))}
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
}

const mapStateToProps = (state) => {
     return {
          detail: state.staticPages,
     }
};

export default connect(mapStateToProps, { getStaticData, getStaticPageDataById })(StaticPages);