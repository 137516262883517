import React, { Fragment, useEffect } from "react";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import setAuthToken from "../../utils/setAuthToken";
import EditArticle from "../../helper/editArticle";
import axios from "axios";
// let flagCount = 1;
const ListAllArticles = (_) => {
  const [articles, setArticles] = React.useState("");
  const [edit, setEdit] = React.useState(0);
  const [id, setId] = React.useState("");
  let [titleArticle, setTitle] = React.useState("");

  // setTimeout(() => {
  //   flagCount = 1;
  // }, 1000);

  // if (flagCount === 1) {
  //   flagCount = 0;
  //   getContentBasedOnTitle();
  // }

  useEffect(() => {
    getContentBasedOnTitle();
  }, []);

  function handleBack() {
    // flagCount = 0;
    getContentBasedOnTitle();
    setEdit(0);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    article(titleArticle);
    setTitle("");
    // flagCount = 1;
  };

  const onChange = (e) => {
    return setTitle(e.target.value);
  };

  // Add article
  async function article(title) {
    await axios
      .post("api/help/addArticle", { title })
      .then((_) => {
        getContentBasedOnTitle();
      });
  }

  async function getContentBasedOnTitle() {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    let res = await axios.get("api/help/getAllArticles");
    setArticles(res.data);
  }

  async function handleDelete(e, deleteId) {
    e.preventDefault();
    const body = JSON.stringify({ article_id: deleteId });
    await axios({
      method: "post",
      url: "api/help/deleteArticle",
      data: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
    getContentBasedOnTitle();
  }

  const handleSelectChange = (e, _id) => {
    const status = e.target.value;
    if (status === 'Published' || status === 'Drafted') {
      axios.post(`api/help/updateArticleByStatus/?status=${status}&articleId=${_id}`)
        .then(_ => { 
          getContentBasedOnTitle();
        })
        .catch(error => console.log('error', error));
    }
  }

  return (
    <Fragment>
      {!edit ? (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
              <div className="card card-custom gutter-b">
                  <div className="card-header border-0 pt-4">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="text-primary">
                        All Articles
                      </h3>
                      <h5 className="mt-0 font-size-sm text-muted">
                        You can find all articles here
                      </h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <form className="form" onSubmit={(e) => onSubmit(e)}>
                      <div className="row">
                        <div className="col-md">
                          <div className="form-group">
                            <label className="bmd-label-floating" htmlFor="article-title">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="article-title"
                              name="title"
                              value={titleArticle}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-end mr-4 mb-4">
                        <button
                          type="submit"
                          className='btn btn-primary'
                        >
                          <div className="d-flex align-content-center">
                            <i className="fas fa-plus pr-2"></i>
                            <span>Add Article</span>
                          </div>
                          
                        </button>
                      </div>
                      <div className="col-12">
                        {typeof articles.articles != "undefined"
                          ? articles.articles.map(function (data, index) {
                            return (
                            <div key={index} className="row justify-content-between py-2 align-items-center" style={{ borderBottom: "1px solid lightgrey" }}>
                              <div
                                className="col-12 col-md-4 align-self-center my-1 justify-content-start"
                                key={data._id}
                              >
                                  <span className="mr-3">{index + 1} -</span>
                                  <span>{data.title}</span>
                              </div>

                              <div className="input-group article_class my-1 col-12 col-md-4 justify-content-end">
                                <select className="custom-select" value={data.status ? data.status : 'Drafted'} onChange={(e) => handleSelectChange(e, data._id)}>
                                  <option value="Drafted">Draft</option>
                                  <option value="Published">Publish</option>
                                </select>
                              </div>

                              <div className="d-flex col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-1 my-1 justify-content-end pr-4 pr-md-0">
                                <button
                                  className="btn btn-primary ml-1 d-flex align-items-center"
                                  onClick={() => {
                                    setId(data._id);
                                    setEdit(1);
                                  }}
                                >
                                <i className="fa fa-edit mr-1 mb-1" />
                                  
                                  Edit
                                </button>
                                <button
                                  className="btn btn-danger ml-1 d-flex align-items-center"
                                  id={data._id}
                                  onClick={(e) => {
                                    handleDelete(e, data._id);
                                  }}
                                ><i className="fa fa-trash mr-1" />
                                  Delete
                                </button>
                              </div>
                            </div>
                            );
                          })
                          : "Nothing"}
                      </div>

                      <div className="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <EditArticle articleId={id} handleBack={handleBack} />
        )}
    </Fragment>
  );
};

export default ListAllArticles;
