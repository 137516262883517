import React, { Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { updateBusinessInfo, searchUsers } from "../../actions/businessInfo";
// import EditorContainer from "../../helper/CategoryEditor";
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';


const BusinessInfo = (props) => {

  const editorRef = useRef(null);

  const { updateBusinessInfo, searchUsers } = props;
  const [uploadFormData, setUploadFormData] = useState({
    user_email: "",
    title: "",
    buyerDescription: "",
    sellerDiscription: '',
    type: '',
    paymentStatus: '',
    // user_email_doc: "",
    // facebook_title: "",
    // facebook_description: "",
    // twitter_title: "",
    // twitter_description: "",
  });
  //   let { author, title, article_id, content, commentable, status } = formData;
  //   const [catalogsList, setCatalogsList] = useState([catalogs]);

  let usersList = [];

  // search users - loading emails...
  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback(usersList);
    } else {
      searchUsers(inputValue, (users) => {
        usersList = users.map((user) => ({
          label: user.email,
          value: user._id,
        }));
        callback(usersList);
      });
      //   setCatalogsList(catalogs);
    }
  };

  const onSearchChange = (selectedItem, event) => {
    // console.log('event : ', event)
    if (selectedItem === null && event.action === "clear") {
      // clear event is fired, reset the selected item...
      usersList = [];
      setUploadFormData({
        ...uploadFormData,
        user_email: '',
        email: '',
        buyerDescription: '',
        sellerDiscription: '',
        type: '',
        paymentStatus: ''
      });
    } else {
      // item is selected, set state here...
      if (event.name === "user_email") {
        // searchCatalogs(selectedItem.value, (catalogs) => {

        //   if (catalogs === undefined) {
        //     setCatalogsList([]);
        //   }

        //   else {
        //     setCatalogsList(
        //       catalogs.map((catalog) => ({
        //         // label: catalog.slug,
        //         label: catalog.displayName,
        //         link: catalog.link,
        //         value: catalog._id,
        //       }))
        //     );
        //   }
        // });
      }
      // update file state...
      setUploadFormData({
        ...uploadFormData,
        [event.name]: selectedItem.value,
      });

      let userId = (selectedItem.value)
      getDiscription(userId)
    }
  };


  const getDiscription = (userId) => {
    if (userId) {
      axios.get(`/api/users/getDiscription/${userId}`)
        .then(res => {
          const { _id, email, buyerDescription, sellerDiscription, type, sellerSubscriptionPaymentStatus } = res.data;
          // console.log("res ", res.data);

          setUploadFormData({
            ...uploadFormData,
            user_email: _id || '',
            email: email || '',
            buyerDescription: buyerDescription || '',
            sellerDiscription: sellerDiscription || '',
            type: type || '',
            paymentStatus: sellerSubscriptionPaymentStatus
          });
        })
        .catch(er => console.error(er));
    }
  }

  // console.log("uploadFormData ", uploadFormData);

  //   const onCatalogChange = (selectedCatalog, event) => {
  //     console.log("onCatalogChange: ", selectedCatalog);
  //     setUploadFormData({
  //       ...uploadFormData,
  //       [event.name]: selectedCatalog.value,
  //     });
  //   };

  //   const onFileChange = (e) => {
  //     setUploadFormData({
  //       ...uploadFormData,
  //       [e.target.name]: e.currentTarget.files[0],
  //     });
  //   };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // console.log('uploadFormData.user_email',uploadFormData.user_email);
    // alert(document.getElementsByName("user_email")[0].value);
    if (document.getElementsByName("user_email")[0].value === "") {
      alert("Please select any user to update Business info.");
      return false;
    }
    // formData.append("user_email_doc", uploadFormData.user_email_doc);
    formData.append("user_email", uploadFormData.user_email);
    formData.append("title", uploadFormData.title);
    formData.append("buyerDescription", uploadFormData.buyerDescription);
    formData.append("sellerDiscription", uploadFormData.sellerDiscription);
    // formData.append("facebook_title", uploadFormData.facebook_title);
    // formData.append("facebook_description", uploadFormData.facebook_description);
    // formData.append("twitter_title", uploadFormData.twitter_title);
    // formData.append("twitter_description", uploadFormData.twitter_description);

    // console.log("finally submitting form: ", formData);
    // return false;
    // formData.append("user_email_doc", uploadFormData.user_email_doc);
    // formData.append("user_email", uploadFormData.user_email);
    // formData.append("catalog", uploadFormData.catalog);
    // formData.append("csvFile", uploadFormData.csvFile);
    // for(let [name, value] of formData) {
    //     console.log(`${name} = ${value}`); // key1=value1, then key2=value2
    //   }
    updateBusinessInfo(formData);
  };

  // function newEdit(check) {
  //   setUploadFormData({ ...uploadFormData, description: check });
  // }

  function ChangBuyerDisc(e) {
    uploadFormData.buyerDescription = e.target.getContent()
  }

  function ChangSellerDisc(e) {
    uploadFormData.sellerDiscription = e.target.getContent()
  }

  return (
    <Fragment>
      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-4">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="text-primary">
                      Update Business Info
                    </h3>
                    <h5 className="mt-0 font-size-sm text-muted">
                      Search user to update business info
                    </h5>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    <div className="form-group">
                      <label htmlFor="user_email" style={{ fontWeight: 'bold', fontSize: 14 }}>Email address / User name <span className="text-danger">*</span></label>
                      <AsyncSelect placeholder="Select user email / user name" loadingMessage={() => "Searching through users"} name="user_email" id="user_email" onChange={onSearchChange} isClearable defaultOptions loadOptions={loadOptions} required />
                      <small id="" className="form-text text-muted">This email / username is used to find user.</small>
                    </div>

                    {uploadFormData.type === "Buyer" ?

                      <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label htmlFor="description" style={{ fontWeight: 'bold', fontSize: 14 }}> Buyer Description </label>
                          </div>
                        </div>
                        <Editor
                          apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                          onInit={(evt, editor) => editorRef.current = editor}
                          initialValue={uploadFormData.buyerDescription}
                          init={{
                            max_height: 200,
                            menubar: false,
                            // forced_root_block: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | preview',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
                          }}
                          onChange={ChangBuyerDisc}
                        />
                      </div> 
                      :
                      uploadFormData.type === "Seller" && uploadFormData.paymentStatus !== 'Paid' ?

                        <div className="form-group">
                          <div className="row">
                            <div className="col">
                              <label htmlFor="description" style={{ fontWeight: 'bold', fontSize: 14 }}> Seller Description </label>
                            </div>
                          </div>
                          <Editor
                            apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={uploadFormData.sellerDiscription}
                            init={{
                              max_height: 200,
                              menubar: false,
                              // forced_root_block: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | preview',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
                            }}
                            onChange={ChangSellerDisc}
                          />
                        </div> 
                        :
                        uploadFormData.type === "Seller" && uploadFormData.paymentStatus === 'Paid' ?
                        <>
                          <div className="form-group">
                            <div className="row">
                              <div className="col">
                                <label htmlFor="description" style={{ fontWeight: 'bold', fontSize: 14 }}> Buyer Description </label>
                              </div>
                            </div>
                            <Editor
                              apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                              onInit={(evt, editor) => editorRef.current = editor}
                              initialValue={uploadFormData.buyerDescription}
                              init={{
                                max_height: 200,
                                menubar: false,
                                // forced_root_block: false,
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                  'bold italic backcolor | alignleft aligncenter ' +
                                  'alignright alignjustify | bullist numlist outdent indent | ' +
                                  'removeformat | preview',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
                              }}
                              onChange={ChangBuyerDisc}
                            />
                          </div>

                          <div className="form-group">
                            <div className="row">
                              <div className="col">
                                <label htmlFor="description" style={{ fontWeight: 'bold', fontSize: 14 }}> Seller Description </label>
                              </div>
                            </div>
                            <Editor
                              apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                              onInit={(evt, editor) => editorRef.current = editor}
                              initialValue={uploadFormData.sellerDiscription}
                              init={{
                                max_height: 200,
                                menubar: false,
                                // forced_root_block: false,
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                  'bold italic backcolor | alignleft aligncenter ' +
                                  'alignright alignjustify | bullist numlist outdent indent | ' +
                                  'removeformat | preview',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
                              }}
                              onChange={ChangSellerDisc}
                            />
                          </div>
                        </>
                        : <div></div>
                    }


                    <div className="form-group">
                      {/* <EditorContainer test={uploadFormData.description} edited={newEdit} /> */}
                      <button
                        className='btn btn-primary  pull-right'
                        style={{ float: 'right', marginRight: '0.1rem' }}
                      >
                        <div className="d-flex align-content-center">
                          <i className="fas fa-save pr-2"></i>
                          <span>Update</span>
                        </div>
                      </button>
                    </div>

                    {/* <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label htmlFor="description">Company Description <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <textarea className="form-control" id="description" name="description" rows="5" onChange={e => setUploadFormData({...uploadFormData,[e.target.name]: e.target.value,})} required/>
                      <small id="" className="form-text text-muted"></small>
                    </div>
                    <div className="row justify-content-end mr-4 mb-4">
                      <button
                        type="submit"
                        className='btn btn-primary'
                      >
                        <div className="d-flex align-content-center">
                          <i className="fas fa-save pr-2"></i>
                          <span>Update</span>
                        </div>
                      </button>
                    </div> */}
                    {/* <div className="clearfix"></div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

BusinessInfo.propTypes = {
  updateBusinessInfo: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  updateBusinessInfo,
  searchUsers,
})(BusinessInfo);

