import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Select from "react-select";
import AsyncSelect from "react-select/async";
import { addFormCatalog, searchCategory } from "../../../actions/category";
import { searchUsers } from "../../../actions/auth"; //, searchCatalogs  upload,

const AddCatalog = (props) => {
	const { addFormCatalog, searchCategory, searchUsers } = props;
	// const { catalogs } = props;
	const [uploadFormData, setUploadFormData] = useState({
		user_email_doc: "",
		user_email: "",
		category_parent_id: "",
		title: "",
		metaTitle: "",
		description: "",
	});

	// const [catalogsList, setCatalogsList] = useState([catalogs]);

	let usersList = [];
	let catList = [];

	// search users - loading emails...
	const loadOptions = (inputValue, callback) => {
		// console.log("==========================")
		if (!inputValue) {
			callback(usersList);
		} else {
			searchUsers(inputValue, (users) => {
				usersList = users.map((user) => ({
					label: user.email,
					value: user._id,
				}));
				callback(usersList);
			});
			// setCatalogsList(catalogs);
		}
	};

	// search users - loading emails...
	const loadOptionsCatalog = (inputValue, callback) => {
		// console.log("==========================")
		if (!inputValue) {
			callback(catList);
		} else {
			if (inputValue.length > 2) {
				searchCategory(inputValue, (users) => {
					catList = users.map((user) => ({
						label: user.link,
						value: user._id,
					}));
					callback(catList);
				});
			}
		}
	};

	const onSearchChange = (selectedItem, event) => {
		// console.log("==========================")
		// console.log('event : ', event)
		if (selectedItem === null && event.action === "clear") {
			// clear event is fired, reset the selected item...
			usersList = [];
		} else {

			// update file state...
			setUploadFormData({
				...uploadFormData,
				[event.name]: selectedItem.value,
			});
		}
	};

	const onCatalogChange = (selectedCatalog, event) => {
		// console.log("onCatalogChange: ", selectedCatalog);
		if (selectedCatalog === null && event.action === "clear") {
			// clear event is fired, reset the selected item...
			catList = [];
		} else {

			// update file state...
			setUploadFormData({
				...uploadFormData,
				[event.name]: selectedCatalog.value,
			});
		}

	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		if (document.getElementsByName("category_parent_id")[0].value === "") {
			alert("Please select any user to update meta tags.");
			return false;
		}
		formData.append("userId", uploadFormData.user_email);
		formData.append("categoryId", uploadFormData.category_parent_id);
		formData.append("metaTitle", uploadFormData.metaTitle);
		formData.append("metaDescription", uploadFormData.description);

		addFormCatalog(formData);
	};

	return (
		<Fragment>
			<div className="content">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-8">
							{/* <nav aria-label="breadcrumb" role="navigation">
								<ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to="/listCatalogs">Catalog</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Add Catalog</li>
								</ol>
							</nav> */}
							<div className="card card-custom gutter-b">
								<div className="card-header border-0 pt-4">
									<h3 className="text-primary">
										Add Catalog (with meta tags)
									</h3>

								</div>
								<div className="card-body pt-0">
									<form className="form" onSubmit={(e) => onSubmit(e)}>
										<div className="form-group">
											<label htmlFor="user_email">Email address</label>
											<AsyncSelect placeholder="Select user email" loadingMessage={() => "Searching through users"} name="user_email" id="user_email" onChange={onSearchChange} isClearable defaultOptions loadOptions={loadOptions} />
											<small id="user_email" className="form-text text-muted">Search user email.</small>
										</div>
										<div className="form-group">
											<label htmlFor="category_parent_id">Parent Category Name <span className="text-danger">*</span></label>
											<AsyncSelect placeholder="Select Parent category name" loadingMessage={() => "Searching through parent categories"} name="category_parent_id" id="category_parent_id" onChange={onCatalogChange} isClearable defaultOptions loadOptions={loadOptionsCatalog} required />
											<small id="" className="form-text text-muted">Search category name.</small>
										</div>
										<div className="form-group">
											<div className="row">
												<div className="col">
													<label htmlFor="title">Meta Title </label>
												</div>
											</div>
											<input type="text" className="form-control" htmlFor="metaTitle" name="metaTitle" onChange={e => setUploadFormData({ ...uploadFormData, [e.target.name]: e.target.value, })} maxLength="70" />
											<small id="" className="form-text text-muted">( max 70 character )</small>
										</div>
										<div className="form-group">
											<div className="row">
												<div className="col">
													<label htmlFor="description">Meta Description </label>
												</div>
											</div>
											<textarea className="form-control" htmlFor="description" name="description" rows="5" onChange={e => setUploadFormData({ ...uploadFormData, [e.target.name]: e.target.value, })} maxLength="170" />
											<small id="" className="form-text text-muted">( max 170 character )</small>
										</div>
										<div className="row justify-content-end mr-4">
											<button
												type="submit"
												className='btn btn-primary'
											>
												<div className="d-flex align-content-center">
													<i className="fas fa-save pr-2"></i>
													<span>Update</span>
												</div>
											</button>
										</div>
										{/* <div className="clearfix"></div> */}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

AddCatalog.propTypes = {
	addFormCatalog: PropTypes.func.isRequired,
	searchUsers: PropTypes.func.isRequired,
	searchCategory: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.auth.user
});

export default connect(mapStateToProps, {
	addFormCatalog,
	searchUsers,
	searchCategory,
})(AddCatalog);

