/* eslint-disable */
"use strict";

import { SBUtil } from "../../components/util.js";

var SBLayoutHeaderMobile = function() {
    // Private properties
    var _element;
    var _object;

    // Get height
    var _getHeight = function() {
        var height;

        height = SBUtil.actualHeight(_element);

        return height;
    }

    // Public methods
	return {
		init: function(id) {
            _element = SBUtil.getById(id);
		},

        isFixed: function() {
            return SBUtil.hasClass(SBUtil.getBody(), 'header-mobile-fixed')
        },

        getElement: function() {
            return _element;
        },

        getHeader: function() {
            return _object;
        },

        getHeight: function() {
            return _getHeight();
        }
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = SBLayoutHeaderMobile;
}

export default SBLayoutHeaderMobile;