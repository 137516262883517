import React, { Fragment, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";
// import AsyncSelect from "react-select/async";
// import { searchCategory } from "../../../actions/category";
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import PaidVendorsList from "./paidVendorsList"


const AddCatAndDisc = (props) => {

    // const { searchCategory } = props;

    const editorRef = useRef(null);

    const [globalCatValue, setGlobalCatValue] = useState({
        bus_type: "",
        category_ids: [],
        user_ids: [],
        slug: "",
        heading: "",
        index: true,
        enable: true,
        title: "",
        meta_description: "",
        description: "",
    });

    const [icon, setIcon] = useState(null);

    // const [catArray, setCatArray] = useState({ data: [] })
    const [alerts, setAlerts] = useState('');
    const [error, setError] = useState(false);
    const [slugMsg, setSlugMsg] = useState('')
    const [loading, setLoading] = useState(false);

    const [userArray, setUserArray] = useState({ data2: [] })

    // let usersList = [];
    // search users - loading emails...
    // const loadOptions = (inputValue, callback) => {
    //     if (!inputValue) {
    //         callback(usersList);
    //     } else {
    //         if (inputValue.length > 2) {
    //             searchCategory(inputValue, (users) => {
    //                 usersList = users.map((user) => ({
    //                     label: user.link,
    //                     link: user.name,
    //                     value: user._id,
    //                 }));
    //                 callback(usersList);
    //             });

    //         }
    //     }
    // };

    // const onSearchChange = (selectedItem, event) => {
    //     // console.log('event : ', event)
    //     if (selectedItem === null && event.action === "clear") {
    //         usersList = [];
    //     } else {
    //         if (globalCatValue.category_ids.length < 100 && globalCatValue.category_ids.includes(selectedItem.value) === false) {
    //             setGlobalCatValue({
    //                 ...globalCatValue,
    //                 [event.name]: [...globalCatValue.category_ids, selectedItem.value],
    //             });
    //             setCatArray({
    //                 data: [...data, { _id: selectedItem.value, link: selectedItem.label }],
    //             })
    //         }
    //     }
    // };

    // function dltCategories(props) {
    //     for (let i = 0; i < globalCatValue.category_ids.length; i++) {

    //         let arr = globalCatValue.category_ids

    //         if (globalCatValue.category_ids[i] === props) {

    //             arr.splice(i, 1);
    //             setGlobalCatValue({
    //                 ...globalCatValue,
    //                 category_ids: arr
    //             })

    //         }
    //     }

    //     for (let i = 0; i < catArray.data.length; i++) {
    //         let arr2 = catArray.data
    //         if (catArray.data[i]._id === props) {
    //             arr2.splice(i, 1);
    //             setCatArray({
    //                 data: arr2
    //             })
    //         }
    //     }
    // }

    // console.log('catArray ', catArray)

    const changeSlug = (e) => {
        setGlobalCatValue({ ...globalCatValue, [e.target.name]: e.target.value, });
        setSlugMsg('')
    }

    const changeIndex = (e) => {
        if (e.target.value === 'index') {
            setGlobalCatValue({ ...globalCatValue, index: true })
        } else if (e.target.value === 'noindex') {
            setGlobalCatValue({ ...globalCatValue, index: false })
        }
    }

    const changeEnable = (e) => {
        if (e.target.value === 'enable') {
            setGlobalCatValue({ ...globalCatValue, enable: true })
        } else if (e.target.value === 'disable') {
            setGlobalCatValue({ ...globalCatValue, enable: false })
        }
    }

    function ChangeCatDisc(e) {
        globalCatValue.description = e.target.getContent()
    }

    const getUserData = (user) => {

         var arr1 = [...userArray.data2]

        var abc = []
        arr1 && arr1.map((item) => {
            abc.push(item._id);
            return ''
        })

        if (abc.includes(user._id)) {
            alert('This User Already Selected');
            return []
        }

        setUserArray({ data2: [...userArray.data2, user] })

        setGlobalCatValue({
            ...globalCatValue,
            user_ids: [...globalCatValue.user_ids, user._id],
        })
    }

    function dltUserTag(props) {

        for (let i = 0; i < globalCatValue.user_ids.length; i++) {

            let arr = globalCatValue.user_ids

            if (globalCatValue.user_ids[i] === props) {

                arr.splice(i, 1);
                setGlobalCatValue({
                    ...globalCatValue,
                    user_ids: arr
                })

            }
        }

        for (let i = 0; i < userArray.data2.length; i++) {
            let arr2 = userArray.data2
            if (userArray.data2[i]._id === props) {
                arr2.splice(i, 1);
                setUserArray({
                    data2: arr2
                })
            }
        }
    }
    // console.log('userArray ', userArray);

    const onFileChange = (e) => {
        var file = document.querySelector("#logo");
        if (file.files.length) {
            const name = file.files[0].name;
            if (/\.(jpe?g|png|webp)$/i.test(name) === false) {
                return setAlerts("Selected Image type Not supported!");
            }
            setIcon(e.currentTarget.files[0])
        }
    };


    async function uploadIcon(slugData) {
        if (slugData) {
            let formData = new FormData();
            formData.append('file', icon);
            await axios.post(`/api/users/upload_Vl_icon?type=${slugData.type}&&slug=${slugData.slug}`, formData)  // value=upload&&
            // axios.post("/api/users/upload_Vl_icon", formData)
                .then(res => {
                    if (res.status === 200) {
                        console.log('icon res ', res.data);
                        setAlerts(res.data)
                    }
                }).catch(er => console.log(er));
        }
    }

    let type = ['distributors', 'manufacturers', 'wholesalers', 'all']    // 'dropshippers', 

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            if (globalCatValue.bus_type === '' || globalCatValue.slug === '' || globalCatValue.heading === '' || globalCatValue.title === '' || globalCatValue.description === '' || icon === null)  {
                alert("Please Fill Required fields.");
                return false;
            } else {
                setLoading(true);
                let res = await axios.post("/api/users/addVendorsBySlug", globalCatValue);
                if (res.status === 200) {
                    // console.log("res ", res.data);
                    if (res.data.status === 'false') {
                        setLoading(false);
                        setSlugMsg(res.data.message)
                    } else {
                        await uploadIcon(res.data.data)
                        setLoading(false);
                        document.documentElement.scrollTop = 0;
                        setAlerts(res.data.message)
                        setGlobalCatValue({
                            bus_type: "",
                            category_ids: [],
                            userIds: [],
                            slug: "",
                            heading: "",
                            index: true,
                            enable: true,
                            title: "",
                            meta_description: "",
                            description: "",
                        })
                        // setCatArray({
                        //     data: []
                        // })
                        setUserArray({
                            data2: []
                        })
                        setIcon(null)
                        setTimeout(() => {
                            setAlerts('')
                        }, 2000);
                    }
                } else {
                    console.log(res.message);
                    setLoading(false);
                    setError(true);
                    document.documentElement.scrollTop = 0;
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            }
        } catch (err) {
            console.log("err ", err);
        }
    };

    // console.log('globalCatValue ', globalCatValue);

    // const { data } = catArray;
    const { data2 } = userArray
    return (
        <Fragment>
            <div className="content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-10">

                            {/* begin::Alert */}
                            {alerts !== '' && (
                                <div className="alert alert-custom alert-light-primary fade show mb-10" role="alert">
                                    <div className="alert-text font-weight-bold">
                                        {alerts}
                                    </div>
                                    <div className="alert-close" onClick={() => setAlerts('')}>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">
                                                <i className="sbi sbi-close"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            )}

                            {error && (
                                <div className="alert alert-custom alert-light-danger fade show mb-10" role="alert">
                                    <div className="alert-text font-weight-bold">
                                        Oops something went wrong while processing your request!
                                    </div>
                                    <div className="alert-close" onClick={() => setError(false)}>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">
                                                <i className="sbi sbi-close"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            )}
                            {/* end::Alert */}

                            <div className="card card-custom gutter-b">
                                <div className="card-header border-0 pt-10">
                                    <h3 className="text-primary">
                                        Add User And Description
                                    </h3>
                                </div>
                                <div className="card-body pt-1">
                                    <form className="form" onSubmit={(e) => onSubmit(e)}>

                                        <div className="row">
                                            <div className="col-md-3 mb-5">
                                                <label htmlFor="text"></label>
                                                <input type="text" className="form-control mt-2" htmlFor="text" name="text" value="www.seebiz.com/vendors/" disabled />
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="category_ids">Select Business Type <span className="text-danger">*</span></label>
                                                    <select
                                                        className='form-control form-control-md'
                                                        name="bus_type"
                                                        value={globalCatValue.bus_type}
                                                        onChange={e => setGlobalCatValue({ ...globalCatValue, [e.target.name]: e.target.value, })}
                                                        style={{ display: 'block' }}
                                                    >
                                                        <option value="" label="Select Type" />
                                                        {
                                                            type.map((item) => <option value={item} label={item} key={item} />)
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col">
                                                            <label htmlFor="slug">Slug <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control" htmlFor="slug" name="slug" value={globalCatValue.slug}
                                                        onChange={changeSlug} maxLength="70" />
                                                    <small id="" className="form-text text-danger">{slugMsg}</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-xl-5 col-lg-5 col-form-label" style={{ fontWeight: 'bold' }}>Upload Vendor Listing Icon <span className="text-danger">*</span></label>
                                            <div className="col-lg-6 col-xl-6">
                                                <input
                                                    accept=".jpeg, .jpg, .png, .webp"
                                                    placeholder="Choose File"
                                                    className="mt-3"
                                                    name="logo"
                                                    id="logo"
                                                    type="file"
                                                    onChange={onFileChange}
                                                />
                                                <span className="form-text text-muted">
                                                    Allowed file types: png, jpg, jpeg, webp.
                                                </span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="heading">Page Heading <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <input type="text" className="form-control" htmlFor="heading" name="heading" value={globalCatValue.heading}
                                                onChange={e => setGlobalCatValue({ ...globalCatValue, [e.target.name]: e.target.value, })} maxLength="70" />
                                        </div>

                                        {/* <div className="form-group">
                                            <label htmlFor="category_ids">Search Category Name</label>
                                            <AsyncSelect placeholder="Select category name" loadingMessage={() => "Searching through parent categories"}
                                                name="category_ids" id="category_ids" onChange={onSearchChange} isClearable defaultOptions onSelectResetsInput={false} loadOptions={loadOptions} />
                                        </div>
                                        {data && data.map((item, key) => {
                                            return <span className="cross_btn mt-1" key={key}>{item.link}<i className="fas fa-times-circle"
                                                onClick={() => { dltCategories(item._id) }}
                                            ></i></span>
                                        })} */}

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="heading">Select Vendors from List<span className="text-danger"> *</span></label>
                                                </div>
                                            </div>
                                            {/* <div className="row"> */}
                                                {data2 && data2.map((item, key) => {
                                                    return <span className="cross_btn mt-1" key={key}>{item.link}<i className="fas fa-times-circle"
                                                        onClick={() => { dltUserTag(item._id) }}
                                                    ></i></span>
                                                })}
                                            {/* </div> */}
                                            <PaidVendorsList parentCallback={getUserData} />
                                        </div>

                                        <div className="form-group row mt-2">
                                            {/* <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                Status
                                            </label> */}
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="radio-inline">
                                                    <label className="radio">
                                                        <input
                                                            type="radio"
                                                            name="index"
                                                            value="index"
                                                            onChange={changeIndex}
                                                            checked={globalCatValue.index === true ? true : false}
                                                        />
                                                        <span></span>index
                                                    </label>

                                                    <label className="radio">
                                                        <input
                                                            type="radio"
                                                            name="noindex"
                                                            value="noindex"
                                                            onChange={changeIndex}
                                                            checked={globalCatValue.index === false ? true : false}
                                                        />
                                                        <span></span> no index
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-xl-6">
                                                <div className="radio-inline">
                                                    <label className="radio">
                                                        <input
                                                            type="radio"
                                                            name="enable"
                                                            value="enable"
                                                            onChange={changeEnable}
                                                            checked={globalCatValue.enable === true ? true : false}
                                                        />
                                                        <span></span>Enable
                                                    </label>

                                                    <label className="radio">
                                                        <input
                                                            type="radio"
                                                            name="disable"
                                                            value="disable"
                                                            onChange={changeEnable}
                                                            checked={globalCatValue.enable === false ? true : false}
                                                        />
                                                        <span></span>Disable
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="title">Meta Title <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <input type="text" className="form-control" htmlFor="title" name="title" value={globalCatValue.title}
                                                onChange={e => setGlobalCatValue({ ...globalCatValue, [e.target.name]: e.target.value, })} maxLength="70" />
                                            <small id="" className="form-text text-muted">( max 70 character )</small>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="meta_description">Meta Description</label>
                                                </div>
                                            </div>
                                            <textarea className="form-control" htmlFor="meta_description" name="meta_description" rows="5" value={globalCatValue.meta_description}
                                                onChange={e => setGlobalCatValue({ ...globalCatValue, [e.target.name]: e.target.value, })} maxLength="170" />
                                            <small id="" className="form-text text-muted">( max 170 character )</small>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="description"> Discription <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <Editor
                                                apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={globalCatValue.description}
                                                init={{
                                                    max_height: "auto",
                                                    menubar: false,
                                                    // forced_root_block: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | link | code | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | preview | fullscreen',
                                                    rel_list: [
                                                        { title: 'None', value: '' },
                                                        { title: 'No Follow', value: 'nofollow' },
                                                        { title: 'No Follow No Opener No Referrer', value: 'nofollow noopener noreferrer' },
                                                        { title: 'No Opener No Referrer', value: 'noopener noreferrer' },
                                                        { title: 'UGC', value: 'ugc' },
                                                        { title: 'Sponsored', value: 'sponsored' }
                                                    ],
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
                                                }}
                                                onChange={ChangeCatDisc}
                                            />
                                        </div>

                                        <div className="row justify-content-end mr-4 mb-4">
                                            <Link
                                                to="#"
                                                className="btn btn-secondary mr-5"
                                            >
                                                Cancel
                                            </Link>

                                            {loading ? (
                                                <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                                            ) : <button type="submit" className="btn btn-primary" disabled={loading}>
                                                <div className="d-flex align-content-center">
                                                    <i className="fas fa-save pr-2"></i>
                                                    <span>ADD</span>
                                                </div>
                                            </button>
                                            }
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

// AddCatAndDisc.propTypes = {
//     searchCategory: PropTypes.func.isRequired
// };

// const mapStateToProps = (state) => ({
//     user: state.auth.user
// });

// export default connect(mapStateToProps, {
//     searchCategory,
// })(AddCatAndDisc);

export default AddCatAndDisc;

