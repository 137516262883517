import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { updateMetaTags, searchUsers } from "../../actions/metaTags";
import axios from "axios";


const MetaTags = (props) => {
  const { updateMetaTags, searchUsers } = props;
  const [uploadFormData, setUploadFormData] = useState({
    // user_email_doc: "",
    user_email: "",
    title: "",
    description: "",
    facebook_title: "",
    facebook_description: "",
    twitter_title: "",
    twitter_description: "",
  });

  let usersList = [];

  // search users - loading emails...
  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback(usersList);
    } else {
      searchUsers(inputValue, (users) => {
        usersList = users.map((user) => ({
          label: user.email,
          value: user._id,
        }));
        callback(usersList);
      });
    //   setCatalogsList(catalogs);
    }
  };

  const onSearchChange = (selectedItem, event) => {
    // console.log('selectedItem : ', selectedItem);
    if (selectedItem === null && event.action === "clear") {
      // clear event is fired, reset the selected item...
      usersList = [];
      setUploadFormData({
        ...uploadFormData,
        // user_email_doc: "",
        user_email: "",
        title: "",
        description: "",
        facebook_title: "",
        facebook_description: "",
        twitter_title: "",
        twitter_description: "",
      });
    } else {
      // item is selected, set state here...
      if (event.name === "user_email") {
        // searchCatalogs(selectedItem.value, (catalogs) => {

        //   if (catalogs === undefined) {
        //     setCatalogsList([]);
        //   }

        //   else {
        //     setCatalogsList(
        //       catalogs.map((catalog) => ({
        //         // label: catalog.slug,
        //         label: catalog.displayName,
        //         link: catalog.link,
        //         value: catalog._id,
        //       }))
        //     );
        //   }
        // });
      }
      // update file state...
      setUploadFormData({
        ...uploadFormData,
        [event.name]: selectedItem.value,
      });

      let userId = (selectedItem.value)
      getDiscription(userId)
    }
  };

  const getDiscription = (userId) => {
    if (userId) {
      axios.get(`/api/users/getDiscription/${userId}`)
        .then(res => {
          const { _id, metaTags } = res.data;

          setUploadFormData({
            ...uploadFormData,
            user_email: _id || '',
            title: metaTags.title ? metaTags.title : '',
            description: metaTags.description ? metaTags.description : "",
            facebook_title: metaTags.facebook_description ? metaTags.facebook_description : "",
            facebook_description: metaTags.title ? metaTags.title : "",
            twitter_title: metaTags.twitter_title ? metaTags.twitter_title : "",
            twitter_description: metaTags.twitter_description ? metaTags.twitter_description : "",
          });
        })
        .catch(er => console.error(er));
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // console.log('uploadFormData.user_email',uploadFormData.user_email);
    // alert(document.getElementsByName("user_email")[0].value);
    if (document.getElementsByName("user_email")[0].value === "") {
      alert("Please select any user to update meta tags.");
      return false;
    }
    // formData.append("user_email_doc", uploadFormData.user_email_doc);
    formData.append("user_email", uploadFormData.user_email);
    formData.append("title", uploadFormData.title);
    formData.append("description", uploadFormData.description);
    formData.append("facebook_title", uploadFormData.facebook_title);
    formData.append("facebook_description", uploadFormData.facebook_description);
    formData.append("twitter_title", uploadFormData.twitter_title);
    formData.append("twitter_description", uploadFormData.twitter_description);
    
    // console.log("finally submitting form: ", formData);
    // return false;
    // formData.append("user_email_doc", uploadFormData.user_email_doc);
    // formData.append("user_email", uploadFormData.user_email);
    // formData.append("catalog", uploadFormData.catalog);
    // formData.append("csvFile", uploadFormData.csvFile);
    // for(let [name, value] of formData) {
    //     console.log(`${name} = ${value}`); // key1=value1, then key2=value2
    //   }
      updateMetaTags(formData);
  };

  return (
    <Fragment>
      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-4">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="text-primary">
                      Update User Meta Tags
                    </h3>
                    <h5 className="mt-0 font-size-sm text-muted">
                      Here is a subtitle for this table
                    </h5>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    <div className="form-group">
                      <label htmlFor="user_email">Email address / User name <span className="text-danger">*</span></label>
                      <AsyncSelect placeholder="Select user email / user name" loadingMessage={() => "Searching through users"} name="user_email" id="user_email" onChange={onSearchChange} isClearable defaultOptions loadOptions={loadOptions} required/>
                      <small id="" className="form-text text-muted">This email / username is used to find user.</small>
                    </div>
                    <div className="form-group">
                      <div className="row">
                          <div className="col">
                            <label htmlFor="title">Meta Title <span className="text-danger">*</span></label>
                          </div>
                      </div>
                      <input type="text" className="form-control" htmlFor="title" name="title" value={uploadFormData.title}/*onChange={onSearchChange}*/
                      onChange={e => setUploadFormData({...uploadFormData,[e.target.name]: e.target.value,})} maxLength="70" required/>
                      <small id="" className="form-text text-muted">( max 70 character )</small>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label htmlFor="description">Meta Description <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <textarea className="form-control" htmlFor="description" name="description" rows="5" value={uploadFormData.description}
                      onChange={e => setUploadFormData({...uploadFormData,[e.target.name]: e.target.value,})} maxLength="170" required/>
                      <small id="" className="form-text text-muted">( max 170 character )</small>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label htmlFor="facebook_title">Facebook Meta Title <span className="text-danger">*</span></label>
                        </div>
                      </div>                        
                      <input type="text" className="form-control" htmlFor="facebook_title" name="facebook_title" value={uploadFormData.facebook_title}
                      onChange={e => setUploadFormData({...uploadFormData,[e.target.name]: e.target.value,})}  maxLength="70" required/>
                      <small id="" className="form-text text-muted">( max 70 character )</small>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label htmlFor="facebook_description">Facebook Meta Description <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <textarea className="form-control" htmlFor="facebook_description" name="facebook_description"  rows="5" value={uploadFormData.facebook_description}
                      onChange={e => setUploadFormData({...uploadFormData,[e.target.name]: e.target.value,})} maxLength="170" required/>
                      <small id="" className="form-text text-muted">( max 170 character )</small>
                    </div>
                    <div className="row justify-content-end mr-4 mb-4">
                      <button
                        type="submit"
                        className='btn btn-primary'
                      >
                        <div className="d-flex align-content-center">
                          <i className="fas fa-save pr-2"></i>
                          <span>Update</span>
                        </div>
                      </button>
                    </div>
                    {/* <div className="clearfix"></div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MetaTags.propTypes = {
  updateMetaTags: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  updateMetaTags,
  searchUsers,
})(MetaTags);

