/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";




export function OtherMenuList({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    const { user, isAuthenticated } = useSelector((state) => state.auth)
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {isAuthenticated ? (
                    <>

                        {(user.role === "admin" || user.role === "dataentry_user") ? (
                            <>
                                {/* List Admin User start*/}
                                <li className={`menu-item ${getMenuItemActive(
                                    "/listAdminUsers"
                                )}`}>
                                    <NavLink
                                        className="menu-link "
                                        to="/listAdminUsers"
                                    >
                                        <i className="fas fa-brands fa-user-secret menu-icon"> </i>
                                        <span className="menu-text">List Admin Users</span>
                                    </NavLink>
                                </li>
                                {/* List Admin User End*/}

                                {/* List Admin User start*/}
                                <li className={`menu-item ${getMenuItemActive(
                                    "/listCronJobs"
                                )}`}>
                                    <NavLink
                                        className="menu-link "
                                        to="/listCronJobs"
                                    >
                                        <i className="fas fa-crosshairs menu-icon"> </i>
                                        <span className="menu-text">List CronJob Results</span>
                                    </NavLink>
                                </li>
                                {/* List Admin User End*/}

                                {/* Post Start */}
                                <li className={`menu-item ${getMenuItemActive(
                                    "/listSendedEmails"
                                )}`}>
                                    <NavLink
                                        className="menu-link "
                                        to="/listSendedEmails"
                                    >
                                        <i className="fas fa-envelope menu-icon"> </i>
                                        <span className="menu-text">List Sended Emails</span>
                                    </NavLink>
                                </li>
                                {/* Post End*/}
                            </>
                        ) : (
                            ""
                        )}

                    </>
                ) : (
                    ""
                )}
                {/*end::1 Level*/}
                {/** END HERE */}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
