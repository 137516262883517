import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Select from "react-select";
import axios from 'axios';
import AsyncSelect from "react-select/async";
import { addFormData, searchCategory } from "../../../actions/category";
import { ModalProgressBar } from "../../../_seebizsol/_partials/controls";


const AddCategory = (props) => {
	const { searchCategory } = props;  // addFormData

	const [alert, setAlert] = useState({});
	const [timer, setTimer] = useState(0);
	const [load, setLoad] = useState(false);

	// Success Alert handler
	const successAlertHandler = (res) => {
		document.documentElement.scrollTop = 0;
		let alrt = { id: 5, alertType: "success", msg: res.data.message || res.data || 'Successfully update user Info' };
		setAlert(alrt);
		if (timer) {
			clearTimeout(timer);
		}

		let time = setTimeout(() => {
			setAlert({});
		}, 4000);
		setTimer(time);
	};

	// Catch Alert handler
	const catchAlertHanlder = (er) => {
		let alrt = {};
		document.documentElement.scrollTop = 0;
		if (er.status === false) {
			alrt = { id: 1, alertType: "danger", msg: er.message };

		} else if (!er.response || !er.response.data) {
			alrt = { id: 1, alertType: "danger", msg: 'Oops something went wrong while processing your request!' };

		} else if (er.response.data.message) {
			alrt = { id: 1, alertType: "danger", msg: er.response.data.message || 'Oops something went wrong while processing your request!' };

		} else {
			alrt = { id: 2, alertType: "danger", msg: '' };
			alrt.msg = 'Oops something went wrong while processing your request!';
		}
		setAlert(alrt);
		if (timer) {
			clearTimeout(timer);
		}
		setLoad(false);
		let time = setTimeout(() => {
			setAlert({});
		}, 4000);
		setTimer(time);
	};

	// const { catalogs } = props;
	const [uploadFormData, setUploadFormData] = useState({
		category_parent_id: "",
		title: "",
		index: true,
		metaTitle: "",
		metaDescription: "",
	});
	//   let { author, title, article_id, content, commentable, status } = formData;
	// const [catalogsList, setCatalogsList] = useState([catalogs]);

	const [icon, setIcon] = useState(null);

	let usersList = [];

	// search users - loading emails...
	const loadOptions = (inputValue, callback) => {
		if (!inputValue) {
			callback(usersList);
		} else {
			// console.log('inputValue.length', inputValue.length);
			if (inputValue.length > 2) {
				searchCategory(inputValue, (users) => {
					usersList = users.map((user) => ({
						label: user.link,
						link: user.name,
						value: user._id,
					}));
					callback(usersList);
				});

			}
			//   setCatalogsList(catalogs);
		}
	};

	const onSearchChange = (selectedItem, event) => {
		// console.log('event : ', event)
		if (selectedItem === null && event.action === "clear") {
			// clear event is fired, reset the selected item...
			usersList = [];
		} else {
			setUploadFormData({
				...uploadFormData,
				[event.name]: selectedItem.value,
			});
		}
	};

	const changeIndex = (e) => {
		if (e.target.value === 'index') {
			setUploadFormData({ ...uploadFormData, index: true })
		} else if (e.target.value === 'noindex') {
			setUploadFormData({ ...uploadFormData, index: false })
		}
	}

	const onFileChange = (e) => {
        var file = document.querySelector("#logo");
        if (file.files.length) {
            const name = file.files[0].name;
            if (/\.(jpe?g|png|webp)$/i.test(name) === false) {
                return alert("Selected Image type Not supported!");
            }
            setIcon(e.currentTarget.files[0])
        }
    };


	async function uploadIcon(catLink) {
        if (catLink) {
			let formData = new FormData();
            formData.append('file', icon);
            await axios.post(`/api/category/upload_category_icon?value=upload&&catLink=${catLink}`, formData)
                .then(res => {
                    if (res.status === 200) {
                        console.log('icon res ', res.data.message);
                        // setAlerts(res.data)
                    }
                }).catch(er => console.log(er.message));
        }
    }

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoad(true);
		// const formData = new FormData();

		if (uploadFormData.category_parent_id === "" || uploadFormData.title === "" || icon === null) {
			catchAlertHanlder({status: false, message: 'Please Select Required Fields'})
			return false;
		}

		// formData.append("category_parent_id", uploadFormData.category_parent_id);
		// formData.append("category_name", uploadFormData.title);
		// formData.append("index", uploadFormData.index);
		// formData.append("metaTitle", uploadFormData.metaTitle);
		// formData.append("metaDescription", uploadFormData.metaDescription);

		axios.post("api/category/addCategoryforApproval", uploadFormData)
			.then(async res => {
				// console.log("res == ", res.data);
				if (res.status === 200) {
					if (res.data.status === 'create' && icon) {
						let catLink = res.data.data
						await uploadIcon(catLink)
						successAlertHandler(res);
						setLoad(false);
						setUploadFormData({
							category_parent_id: "",
							title: "",
							index: true,
							metaTitle: "",
							metaDescription: "",
						});
						setIcon(null)
					} else {
						successAlertHandler(res);
						setLoad(false);
					}
				}
			}).catch(er => catchAlertHanlder(er));

		// addFormData(formData);
	};

	return (
		<Fragment>
			<div className="content">
				<div className="container-fluid">
					{alert.msg &&
						<div className="row justify-content-center">
							<div className={`alert alert-${alert.alertType}`} style={{ zIndex: '9' }} role="alert">
								{alert.msg}
							</div>
						</div>
					}
					<div className="row justify-content-center">
						<div className="col-md-8">
							{/* <nav aria-label="breadcrumb" role="navigation">
								<ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to="/listCategories">Category</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Add Category</li>
								</ol>
							</nav> */}
							<div className="card card-custom gutter-b">
								{load && <ModalProgressBar />}
								<div className="card-header border-0 pt-4">
									<h3 className="text-primary">
										Add Category (with meta tags)
									</h3>
									{/* <h4 className="card-title">Add Category (with meta tags)</h4>
									<p className="card-category"></p> */}
								</div>
								<div className="card-body pt-1">
									<form className="form" onSubmit={(e) => onSubmit(e)}>

										<div className="form-group row">
											<label className="col-xl-5 col-lg-5 col-form-label" style={{ fontWeight: 'bold' }}>Upload Category Icon <span className="text-danger">*</span></label>
											<div className="col-lg-6 col-xl-6">
												<span className="form-text text-muted">
													Allowed file types: png, jpg, jpeg, webp.
												</span>
												<input
													accept=".jpeg, .jpg, .png, .webp"
													placeholder="Choose File"
													className="mt-3"
													name="logo"
													id="logo"
													type="file"
													onChange={onFileChange}
												/>
											</div>
										</div>

										<div className="form-group">
											<label htmlFor="category_parent_id">Parent Category Name <span className="text-danger">*</span></label>
											<AsyncSelect placeholder="Select Parent category name" loadingMessage={() => "Searching through parent categories"} name="category_parent_id" id="category_parent_id" onChange={onSearchChange} isClearable defaultOptions onSelectResetsInput={false} loadOptions={loadOptions} />
											<small id="" className="form-text text-muted">Search category name.</small>
										</div>
										<div className="form-group">
											<div className="row">
												<div className="col">
													<label htmlFor="title">Name <span className="text-danger">*</span></label>
												</div>
											</div>
											<input type="text" className="form-control" htmlFor="title" name="title" value={uploadFormData.title} onChange={e => setUploadFormData({ ...uploadFormData, [e.target.name]: e.target.value, })} maxLength="40" />
											<small id="" className="form-text text-muted">( max 40 character )</small>
										</div>

										<div className="form-group row">
											<div className="col-lg-6 col-xl-6">
												<div className="radio-inline">
													<label className="radio">
														<input
															type="radio"
															name="index"
															value="index"
															onChange={changeIndex}
															checked={uploadFormData.index === true ? true : false}
														/>
														<span></span>index
													</label>

													<label className="radio">
														<input
															type="radio"
															name="noindex"
															value="noindex"
															onChange={changeIndex}
															checked={uploadFormData.index === false ? true : false}
														/>
														<span></span> no index
													</label>
												</div>
											</div>
										</div>

										<div className="form-group">
											<div className="row">
												<div className="col">
													<label htmlFor="title">Meta Title </label>
												</div>
											</div>
											<input type="text" className="form-control" htmlFor="metaTitle" name="metaTitle" value={uploadFormData.metaTitle} onChange={e => setUploadFormData({ ...uploadFormData, [e.target.name]: e.target.value, })} maxLength="70" />
											<small id="" className="form-text text-muted">( max 70 character )</small>
										</div>
										<div className="form-group">
											<div className="row">
												<div className="col">
													<label htmlFor="metaDescription">Meta Description </label>
												</div>
											</div>
											<textarea className="form-control" htmlFor="metaDescription" name="metaDescription" rows="5" value={uploadFormData.metaDescription} onChange={e => setUploadFormData({ ...uploadFormData, [e.target.name]: e.target.value, })} maxLength="170" />
											<small id="" className="form-text text-muted">( max 170 character )</small>
										</div>
										<div className="row justify-content-end mr-4 mb-4">
											<button
												type="submit"
												className='btn btn-primary'
											>
												<div className="d-flex align-content-center">
													<i className="fas fa-save pr-2"></i>
													<span>Add for Approval</span>
												</div>
											</button>
										</div>
										{/* <div className="clearfix"></div> */}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

AddCategory.propTypes = {
	addFormData: PropTypes.func.isRequired,
	searchCategory: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.auth.user
});

export default connect(mapStateToProps, {
	addFormData,
	searchCategory,
})(AddCategory);

