import {
  CONTENT_ADDED,
  ALL_ARTICLES,
  GET_CONTENT_TITLES,
  UPDATE_ARTICLE,
} from "../actions/types";

const initialState = {
  articles: [],
  content_title: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_ARTICLES:
      return { ...state, articles: payload.articles };

    case CONTENT_ADDED:
      return { ...state, ...payload };

    case GET_CONTENT_TITLES:
      return { ...state, content_title: payload.msg };

    case UPDATE_ARTICLE:
      return { ...state, ...payload };

    default:
      return state;
  }
}
