import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import StaticEditorTextArea from '../../helper/staticPageEditor';
import { useHistory } from 'react-router';
import { useSubheader } from '../../_seebizsol/layout';

const About = ({ data, location }) => {
     const subheader = useSubheader();
     subheader.setTitle('Static Page Editor')
     const history = useHistory()
     let page = Object.values(data).filter(val => val._id === location._id);
     const [currPage, setCurrPage] = useState(...page);
     const [alert,setAlert] = useState({message:''})
     
     useEffect(() => {
          setCurrPage({ ...currPage });
     }, [location._id, currPage]) // empty array before

     const onChange = (e) => {
          setCurrPage({ ...currPage, [e.target.name]: e.target.value });
     }
     const onSubmit = async (e) => {
          e.preventDefault();
          await updateDataById(currPage._id, currPage.title, currPage.metaData, currPage.content);
     };

     function newEdit(check) {
          setCurrPage({ ...currPage, content: check });
     }

     const updateDataById = async (_id, title, metaData, content) => {
          let body = { _id, content, metaData, title }
          let res = await axios.post("/api/staticPages/updateData", body)

          if (res.data.message === "success") {
               setCurrPage({ ...res.data.payload });
               setAlert({message:"Page Updated Successfully"})
               setTimeout(() => {
                    setAlert({message:''})
               }, 2500);
          }
          else {

          }

     }
     if(!location._id){
          history.push('/staticpages');
          return <></>
     }
     return (
          <Fragment>
               <div className="content">
                    <div className="container-fluid">
                         <div className="row">
                              <div className="col-md-12">
                                   <div className="card">
                                        <div className="card-header card-header-primary">
                                             <h3 className="card-title">{currPage.title}</h3>
                                             <p className="card-category">You can Edit {currPage.title} Page here</p>
                                        </div>
                                        <div className="card-body">
                                             <form className="form" onSubmit={(e) => onSubmit(e)}>
                                                  <div className={"alert alert-"+(alert.type?alert.type:"success")} style={{display:alert.message?'block':'none' }}>{alert.message}</div>
                                                  <div className="row">
                                                       <div className="col-md">
                                                            <div className="form-group">
                                                                 <label className="bmd-label-floating">
                                                                      Page Title
                                                                 </label>
                                                                 <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      htmlFor="author"
                                                                      name="title"
                                                                      value={currPage.title}
                                                                      autoFocus={true}
                                                                      onChange={(e) => onChange(e)}
                                                                 />
                                                            </div>
                                                       </div>
                                                  </div>

                                                  <div className="row">
                                                       <div className="col-md">
                                                            <div className="form-group">
                                                                 <label className="bmd-label-floating">
                                                                      {" "}
                                                                      meta Data
                                                                 </label>
                                                                 <textarea
                                                                      className="form-control"
                                                                      rows="5"
                                                                      name="metaData"
                                                                      value={currPage.metaData}
                                                                      onChange={(e) => onChange(e)}
                                                                 ></textarea>
                                                            </div>
                                                       </div>
                                                  </div>

                                                  <label className="bmd-label-floating">Page Content</label>
                                                  <StaticEditorTextArea test={currPage.content} edited={newEdit} />
                                             </form>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     );
}

const mapStateToProps = (state) => {
     return {
          data: state.staticPages,
     };
};

export default connect(mapStateToProps, {})(About);