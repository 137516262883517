import React,{useEffect,useRef} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Alert = ({ alerts }) =>{
  const al = useRef(null);
  useEffect(()=>{
    if (al.current) {
      // al.current.scrollIntoView();
      window.scrollTo(0,0);
    }
    return;
});
  return( <>
  {alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <div  key={alert.id} ref={al} className="d-flex justify-content-center">
    <div  key={alert.id} className={`alert alert-${typeof alert.alertType==='string'?alert.alertType:alert.alertType?'success':'danger'}`}>
      {alert.msg}
    </div>
    </div>))}
  </>)
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
