import React, { useState, useEffect } from "react";
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import { useHistory, useLocation } from "react-router-dom";
import EditorContainer from "../../helper/CategoryEditor";
import { useSubheader } from "../../_seebizsol/layout";

const EditProduct = () => {
  const subheader = useSubheader();
  let history = useHistory();
  let productId = (new URLSearchParams(useLocation().search)).get("productId");
  const [product, setProduct] = useState({
    productId: '', title: '', index: true,
    description: '', price: 0, type: 'product',
    isGray: false
  });
  const [alert, setAlert] = useState({});
  const [timer, setTimer] = useState(0);
  useEffect(()=>{subheader.setTitle("Edit Product")});

  const [error, setError] = useState({ nameErr: '' })


  // Success Alert handler
  const successAlertHandler = (res) => {
    document.documentElement.scrollTop = 0;
    let alrt = { id: 5, alertType: "success", msg: res.data.message || 'Oops something went wrong while processing your request!' };
    setAlert(alrt);
    if(timer) {
      clearTimeout(timer);
    }

    let time = setTimeout(() => {
      setAlert({});
    }, 4000);
    setTimer(time);
  };

   // Catch Alert handler
  const catchAlertHanlder = (er) => {
    let alrt = {};
    document.documentElement.scrollTop = 0;
    if (!er.response || !er.response.data) {
      alrt = { id: 1, alertType: "danger", msg: 'Oops something went wrong while processing your request!' };
      
    } else if (er.response.data.message) {
      alrt = { id: 1, alertType: "danger", msg: er.response.data.message || 'Oops something went wrong while processing your request!' };
      
    } else {
      alrt = { id: 2, alertType: "danger", msg: '' };
      alrt.msg = 'Oops something went wrong while processing your request!';
    }
    setAlert(alrt);
    if (timer) {
      clearTimeout(timer);
    }
    let time = setTimeout(() => {
      setAlert({});
    }, 4000);
    setTimer(time);
  };

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    axios.get(`/api/product/getInfo/${productId}`)
      .then(res => {
        const { _id, title, price, description, index, isGray } = res.data;
        setProduct({
          productId: _id,
          title,
          description: description || '',
          price: price || 0,
          index: index,
          isGray: isGray || false
        });
      })
      .catch(er => console.log(er));
      return () => {
        clearTimeout(timer);
      }
    
  }, [productId, timer]);

  const changeProductName = (e) => {
    if (e.target.value.length < 1) {
        setError({ ...error, nameErr: 'Product name cannot be empty' })
    } else if (e.target.value.length > 100) {
        setError({ ...error, nameErr: 'Product name cannot be more than 100 character' })
    } else {
        setError({ ...error, nameErr: '' })
    }

    setProduct({ ...product, title: e.target.value })
}

  const changeIndex = (e) => {
    if (e.target.value === 'index') {
      setProduct({ ...product, index: true })
    } else if (e.target.value === 'noindex') {
      setProduct({ ...product, index: false })
    }
  }

  const changeIsGray = (e) => {
    setProduct({ ...product, isGray: e.target.checked })
  }

  const onSubmit = (ev) => {
    ev.preventDefault();
    // if(!product.title || !product.title.length) {
    //   return alert('Product Name Required');
    // }
    if (product.title === '') {
      setError({ ...error, nameErr: 'Product name cannot be empty.' })
    } else if (product.title.length > 100) {
      setError({ ...error, nameErr: 'Product name cannot be more than 100 character.' })
    } else if (error.nameErr === '') {
      axios.post("/api/product/editProduct", product)
        .then(res => {
          if (res.status === 200) {
            successAlertHandler(res);
          }
        }).catch(er => catchAlertHanlder(er));
    }
  };

  function newEdit(check) {
		setProduct({ ...product, description: check });
	}

  const goBack = () => {
    history.push("/listProducts");
  };

  return (
    <div className="content">
      <div className="container-fluid">
        {alert.msg &&
            <div className="row justify-content-center">
              <div className={`alert alert-${alert.alertType}`} style={{zIndex: '9'}} role="alert">
                {alert.msg}
              </div>
            </div>
        }
        { product.productId ?
          <div className="row justify-content-center">
            <div className="col-md-8">
            <div className="card card-custom gutter-b">
                  <div className="card-header border-0 pt-4">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="text-primary">
                         Product
                      </h3>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label className="bmd-label-floating">
                            Product Name
                        </label>
                          <input type="text" className="form-control" name="title" value={product.title} required
                            onChange={changeProductName}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label className="bmd-label-floating">
                            Price
                        </label>
                          <input type="number" className="form-control" name="price" value={product.price}
                            onChange={e => setProduct({ ...product, price: +e.target.value })} />
                        </div>
                      </div> */}
                    </div>
                    {error.nameErr ?
                      <div className="form-group row">
                        <div className="col-lg-6 col-md-6">
                          <p style={{ color: 'red' }}>{error.nameErr}</p>
                        </div>
                      </div> : ''
                    }

                    <div className="form-group row mt-2">
                      <div className="col-lg-6 col-xl-6">
                        <div className="radio-inline">
                          <label className="radio">
                            <input
                              type="radio"
                              name="index"
                              value="index"
                              onChange={changeIndex}
                              checked={product.index === true ? true : false}
                            />
                            <span></span>index
                          </label>

                          <label className="radio">
                            <input
                              type="radio"
                              name="noindex"
                              value="noindex"
                              onChange={changeIndex}
                              checked={product.index === false ? true : false}
                            />
                            <span></span> no index
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6 col-xl-6">
                        <div className="checkbox-inline">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="isGray"
                              checked={product.isGray}
                              onChange={changeIsGray}
                            />
                            <span></span>
                            <p className="form-text" style={{ fontSize: 13, marginBottom: 0 }}>Is Gray {product.isGray ? '(True)' : '(False)'}</p>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label htmlFor="description">Description </label>
                        </div>
                      </div>
                      <EditorContainer test={product.description} edited={newEdit} />
                    </div>
                    <button className="btn btn-default d-flex align-items-center" onClick={goBack}><i className="fa fa-chevron-left" />Back</button>
                  </form>
                </div>
              </div>
            </div>
          </div> :
          ''
        }
      </div>
    </div>
  );
};

export default EditProduct;