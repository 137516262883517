import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

export default function CustomAccordion({ commentTitle }) {
  return (
    <Accordion allowZeroExpanded={true}>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>No</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>{commentTitle}</p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}
