import React, { useEffect, useState, useRef } from 'react';
import { useSubheader } from "../../../_seebizsol/layout";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { searchCategory } from "../../../actions/category";
import AsyncSelect from "react-select/async";
import axios from 'axios';
import { Button, InputGroup, Form, FormControl } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { DropzoneArea } from 'material-ui-dropzone';
import { ColorPicker } from 'material-ui-color';
import { ModalProgressBar } from "../../../_seebizsol/_partials/controls";
import { Link } from 'react-router-dom';


function ProductEdit(props) {

    const { searchCategory } = props;

    const editorRef = useRef(null);
    const scrollRef = useRef(null)


    const subheader = useSubheader();
    useEffect(() => { subheader.setTitle("Edit Product"); })
    let history = useHistory();

    let productId = (new URLSearchParams(useLocation().search)).get("productId");

    const [alert, setAlert] = useState({});
    const [timer, setTimer] = useState(0);

    const [loading, setloading] = useState(false);

    // Success Alert handler
    const successAlertHandler = (res) => {
        document.documentElement.scrollTop = 0;
        let alrt = { id: 5, alertType: "success", msg: res.data.message || 'Oops something went wrong while processing your request!' };
        setAlert(alrt);
        if (timer) {
            clearTimeout(timer);
        }

        let time = setTimeout(() => {
            setAlert({});
        }, 4000);
        setTimer(time);
    };

    // Catch Alert handler
    const catchAlertHanlder = (er) => {
        let alrt = {};
        document.documentElement.scrollTop = 0;
        if (er.data && er.data.message) {
            alrt = { id: 1, alertType: "danger", msg: er.data.message || 'Oops something went wrong while processing your request!' };

        } else if (!er.response || !er.response.data) {
            alrt = { id: 1, alertType: "danger", msg: 'Oops something went wrong while processing your request!' };

        } else if (er.response.data.message) {
            alrt = { id: 1, alertType: "danger", msg: er.response.data.message || 'Oops something went wrong while processing your request!' };

        } else {
            alrt = { id: 2, alertType: "danger", msg: '' };
            alrt.msg = 'Oops something went wrong while processing your request!';
        }
        setAlert(alrt);
        if (timer) {
            clearTimeout(timer);
        }
        let time = setTimeout(() => {
            setAlert({});
        }, 4000);
        setTimer(time);
    };

    const [product, setProduct] = useState({
        productId: '', title: '', categories: '', catalogCategory: [], categoryId: '', sku: '', productType: 'simpleProduct', readytoship: false, tierPrice: [], groupPrice: [],
        minOrderQuantity: {}, minGroupOrderQuantity: {}, leadTime: [], getQuotes: false, getOrders: false, isCustomization: false, logoCustomization: {}, index: true,
        graphicsCutomization: {}, packagingCustomization: {}, BuySamples: false, keyWords: [], shortDescription: '', measureUnit: {}, lotsize: '', VariationCount: '',
        merchant_id: {}, shipments: [], payments: [], product_main_image: '', product_main_video: '', product_description_images: [], product_images: [], isGray: false,
        product_video: '', Color: [], Diameter: [], Length: [], Material: [], Power: [], Size: [], Voltage: [], Volume: [], Packaging: [], samples: [], description: '', packgingUnit: ''
    });

    const [grouplist, setGrouplist] = useState([])

    const [keyTag, setKeyTag] = useState([])

    const [catalog, setCatalog] = useState([])

    var packgingUnitValues = ['Pair','Set','Bundle','Pack','Bag','Spool','Can','Jar','Tin','Roll','Bale','Tube','Reel','Case','Box','Carton','Container','Barrel','Drum','Pallet']

    const BucketUrl = `${process.env.REACT_APP_BUCKET_URL}${process.env.REACT_APP_PRODUCT_IMAGE_URL}`;

    const [unitVal, setUnitVal] = useState([])
    useEffect(() => {
        axios.get('/api/product/units')
            .then(res => {
                if (res.data !== undefined) {
                    // console.log("unitVal ", res.data)
                    setUnitVal(res.data);
                }
            })
    }, [])
    // console.log("unitVal ", unitVal)


    useEffect(() => {

        axios.get(`/api/product/getProductById/${productId}`)
            .then(res => {
                // console.log("res ", res.data)

                const { _id, title, categories, catalogCategory, sku, productType, readytoship, tierPrice, groupPrice, minOrderQuantity, minGroupOrderQuantity,
                    leadTime, getQuotes, getOrders, isCustomization, logoCustomization, graphicsCutomization, packagingCustomization, keyWords, index,
                    shortDescription, description, measureUnit, lotsize, merchant_id, shipments, payments, product_main_image, product_main_video, product_images,
                    product_video, Color, Diameter, Length, Material, Power, Size, Voltage, Volume, Packaging, samples, VariationCount, packgingUnit, category, isGray
                } = res.data;


                var totalImgTags = description ? description.match(/<img .*?>/g) : ''
                var newImages = [];
                if (totalImgTags && totalImgTags.length) {
                    for (let i = 0; i < totalImgTags.length; i++) {
                        let removeInitialTag = totalImgTags[i].replace('<img src="' + BucketUrl + '/', '');
                        newImages.push({ 'original': removeInitialTag.replace('" alt="" />', '') })
                    }
                }

                var sample = false
                if (samples && samples.length > 0) {
                    sample = true
                }

                setProduct({
                    productId: _id,
                    title: title || '',
                    categories: categories || '',
                    catalogCategory: catalogCategory || [],
                    categoryId: category || '',
                    sku: sku || '',
                    index: index,
                    productType: productType || '',
                    readytoship: readytoship || false,
                    tierPrice: tierPrice || [],
                    groupPrice: groupPrice || [],
                    minOrderQuantity: minOrderQuantity || {},
                    minGroupOrderQuantity: minGroupOrderQuantity || {},
                    leadTime: leadTime || [],
                    getQuotes: getQuotes || false,
                    getOrders: getOrders || false,
                    isCustomization: isCustomization || false,
                    logoCustomization: logoCustomization || {},
                    graphicsCutomization: graphicsCutomization || {},
                    packagingCustomization: packagingCustomization || {},
                    BuySamples: sample || false,
                    keyWords: keyWords || [],
                    shortDescription: shortDescription || '',
                    measureUnit: measureUnit || '',
                    lotsize: lotsize || '',
                    description: description || '',
                    merchant_id: merchant_id || {},
                    shipments: shipments || [],
                    payments: payments || [],
                    product_description_images: newImages || [],
                    product_main_image: product_main_image || '',
                    product_main_video: product_main_video || '',
                    product_images: product_images || [],
                    product_video: product_video || '',
                    Color: Color || [],
                    Diameter: Diameter || [],
                    Length: Length || [],
                    Material: Material || [],
                    Power: Power || [],
                    Size: Size || [],
                    Voltage: Voltage || [],
                    Volume: Volume || [],
                    Packaging: Packaging || [],
                    samples: samples || [],
                    VariationCount: VariationCount || '',
                    packgingUnit: packgingUnit || '',
                    isGray: isGray || false
                });

                if (merchant_id._id) {
                    axios.get(`/api/product/myContactGroups/${merchant_id._id}`)
                        .then(res => {
                            setGrouplist(res.data);
                        })

                    axios.get(`/api/catalogs/list/${merchant_id._id}`)
                        .then(res => {
                            if (res.data !== undefined) {
                                setCatalog(res.data);
                            }
                        })
                }

                var newkeyArr = []  // ...keyTag
                if (keyWords.length > 0) {
                    keyWords.map((item) => {
                        newkeyArr.push(item.word)
                        return item
                    })
                    setKeyTag(newkeyArr)
                }
            })
            .catch(er => console.log(er));

    }, [productId, BucketUrl]);




    let userId = product.merchant_id._id
    // useEffect(() => {

    //     axios.get(`/api/product/myContactGroups/${userId}`)
    //         .then(res => {
    //             setGrouplist(res.data);
    //         })
    // }, [userId])

    // console.log("contactGroup ", grouplist);


    const [error, setError] = useState({
        tierErr: '', groupErr: '', nameErr: '', skuErr: '', indexErr: '', leadErr: '', imageErr: '', unitErr: '', lotsizeErr: '', attrErr: '', sampleErr: ''
    })

    const onSubmit = (ev) => {
        ev.preventDefault();

        const found = attributes.find(element => element !== pricAttr);
        if (found) {
            let newArr = [...product[found]];
            newArr.map((item, i) => {
                if (item.price) {
                    item.price = ''
                }
                return item
            })
            setProduct({
                ...product,
                [found]: newArr
            })
        }
        var newtag = [...tag];
        newtag.map((item, i) => {
            if (item.name !== pricAttr) {
                item.price = ''
            }
            return item
        })
        setTag(newtag)

        if (product.productType === 'productWithVeriablePrice') {
            product.tierPrice = []
            product.groupPrice = []
        }

        product.VariationCount = combinations.length
        // product.VariationCount = parseFloat(combinations.length/2)

        if (product.groupPrice[0] && product.groupPrice[0].groupId === '') {
            product.groupPrice = []
        }


        if (product.title === '') {
            setError({ ...error, nameErr: 'Product name cannot be empty.' })
            document.documentElement.scrollTop = 0;

        } else if (product.title.length > 100) {
            setError({ ...error, nameErr: 'Product name cannot be more than 100 character.' })
            document.documentElement.scrollTop = 0;

        } else if (product.sku === '') {
            setError({ ...error, skuErr: 'Product SKU cannot be empty.' })
            document.documentElement.scrollTop = 0;

        } else if (product.index === undefined) {
            setError({ ...error, indexErr: 'Product Select Index.' })
            document.documentElement.scrollTop = 0;

        } else if (product.leadTime[0] && product.leadTime[0].quantity === '') {
            setError({ ...error, leadErr: 'Please add some quantity.' })
            document.documentElement.scrollTop = 250;

        } else if (product.leadTime[0] && product.leadTime[0].estimatedTime === '') {
            setError({ ...error, leadErr: 'Please add Est. Time' })
            document.documentElement.scrollTop = 250;

        } else if (product.product_images.length < 1 && product.product_main_video === '') {
            setError({ ...error, imageErr: 'Please add atleast one product main video/image.' })
            document.documentElement.scrollTop = 1000;

        } else if (product.measureUnit.unitDisplay === '') {
            setError({ ...error, unitErr: 'Please Select Unit of Measure' })
            document.documentElement.scrollTop = 1800;

        } else if (product.lotsize === '') {
            setError({ ...error, lotsizeErr: 'Please add lot size' })
            document.documentElement.scrollTop = 1800;

        } else if (product.productType !== 'productWithVeriablePrice' && product.tierPrice.length === 0) {
            setError({ ...error, tierErr: 'Please add Tier Price' })
            document.documentElement.scrollTop = 2000;

        } else if (product.tierPrice.length > 3) {
            setError({ ...error, tierErr: 'Maximum 3 Tier Prices are Alowed' })
            document.documentElement.scrollTop = 2200;

        } else if (product.tierPrice[0] && product.tierPrice[0].quantity === '') {
            setError({ ...error, tierErr: 'Please add Tier Price' })
            document.documentElement.scrollTop = 2000;

        } else if (product.productType !== 'productWithVeriablePrice' && (product.tierPrice[0].price === null || product.tierPrice[0].price === '')) {
            setError({ ...error, tierErr: 'Please add Tier Price/Unit' })
            document.documentElement.scrollTop = 2000;

            // } else if (product.groupPrice.length === 0) {
            //     setError({ ...error, groupErr: 'Please add Group Price' })
            //     document.documentElement.scrollTop = 2000;

        } else if (product.groupPrice[0] && product.groupPrice[0].groupId !== '' && product.groupPrice[0].price === '') {
            setError({ ...error, groupErr: 'Please add Group Price' })
            document.documentElement.scrollTop = 2200;

        } else if (product.productType === 'variableProduct' && attributes.length < 1) {
            setError({ ...error, attrErr: 'Please add primary attributes.' })
            document.documentElement.scrollTop = 2000;

        } else if (product.productType === 'productWithVeriablePrice' && pricAttr === '') {
            setError({ ...error, attrErr: 'Please select pricing Attributes' })
            document.documentElement.scrollTop = 2200;

        } else if ((product.productType === 'variableProduct' || product.productType === 'productWithVeriablePrice') && tag.length < 1) {
            setError({ ...error, attrErr: 'Please add Attributes Value.' })
            document.documentElement.scrollTop = 2000;

        } else if (product.BuySamples === true && product.samples.length === 0) {
            setError({ ...error, sampleErr: 'Please add Samples Value' })

        } else if (product.BuySamples === true && product.samples[0] && product.samples[0].noOfUnits === '') {
            setError({ ...error, sampleErr: 'Please add Samples Value' })

        } else if (product.BuySamples === true && product.samples[0] && !product.samples[0].samplePrice) {
            setError({ ...error, sampleErr: 'Please add Unit Price' })

        } else if (product.BuySamples === true && product.productType === 'variableProduct' && product.samples[0] && !product.samples[0].variationFirstAttribute) {
            setError({ ...error, sampleErr: 'Please Select Variation' })

        } else if (product.BuySamples === true && product.productType === 'variableProduct' && product.samples[0] && !product.samples[0].variationFirstAttribute.name) {
            setError({ ...error, sampleErr: 'Please Select Variation' })

        } else if (error.tierErr === '' && error.groupErr === '' && error.nameErr === '' && error.skuErr === '' && error.indexErr === '' && error.leadErr === '' &&
            error.lotsizeErr === '' && error.unitErr === '' && error.attrErr === '' && error.sampleErr === '') {
            setloading(true);
            document.documentElement.scrollTop = 0;
            axios.post("/api/product/updateProduct", product)
                .then(res => {
                    if (res.status === 200 && !res.data.status) {
                        const { _id, title, sku, index, productType, getQuotes, getOrders, isCustomization, logoCustomization, graphicsCutomization, packagingCustomization,
                            shortDescription, measureUnit, lotsize, minOrderQuantity, tierPrice, minGroupOrderQuantity, groupPrice, description, product_images, isGray,
                            product_main_image, product_video, Color, Diameter, Length, Material, Power, Size, Voltage, Volume, Packaging, samples, keyWords, packgingUnit
                        } = res.data.data;

                        var totalImgTags = description ? description.match(/<img .*?>/g) : ''
                        var newImages = [];
                        if (totalImgTags && totalImgTags.length) {
                            for (let i = 0; i < totalImgTags.length; i++) {
                                let removeInitialTag = totalImgTags[i].replace('<img src="' + BucketUrl + '/', '');
                                newImages.push({ 'original': removeInitialTag.replace('" alt="" />', '') })
                            }
                        }

                        var sample = false
                        if (samples && samples.length > 0) {
                            sample = true
                        }

                        setProduct({
                            ...product,
                            productId: _id,
                            title: title || '',
                            sku: sku || '',
                            index: index,
                            productType: productType || '',
                            getQuotes: getQuotes || false,
                            getOrders: getOrders || false,
                            isCustomization: isCustomization || false,
                            logoCustomization: logoCustomization || {},
                            graphicsCutomization: graphicsCutomization || {},
                            packagingCustomization: packagingCustomization || {},
                            shortDescription: shortDescription || '',
                            measureUnit: measureUnit || '',
                            lotsize: lotsize || '',
                            minOrderQuantity: minOrderQuantity || {},
                            tierPrice: tierPrice || [],
                            minGroupOrderQuantity: minGroupOrderQuantity || {},
                            groupPrice: groupPrice || [],
                            description: description || '',
                            product_main_image: product_main_image || '',
                            product_images: product_images || [],
                            product_description_images: newImages || [],
                            product_video: product_video || '',
                            Color: Color || [],
                            Diameter: Diameter || [],
                            Length: Length || [],
                            Material: Material || [],
                            Power: Power || [],
                            Size: Size || [],
                            Voltage: Voltage || [],
                            Volume: Volume || [],
                            Packaging: Packaging || [],
                            samples: samples || [],
                            BuySamples: sample || false,
                            keyWords: keyWords || [],
                            packgingUnit: packgingUnit || '',
                            isGray: isGray
                        })
                        successAlertHandler(res);
                        setloading(false);
                    } else if (res.data.status === 500) {
                        catchAlertHanlder(res);
                        setloading(false);
                    }
                }).catch(er => {
                    catchAlertHanlder(er);
                    setloading(false);
                });
        }
    }


    // Start Basic Info
    let usersList = [];

    const loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            callback(usersList);
        } else {
            if (inputValue.length > 2) {
                searchCategory(inputValue, (users) => {
                    usersList = users.map((user) => ({
                        label: user.link,
                        link: user.name,
                        value: user._id,
                    }));
                    callback(usersList);
                });
            }
        }
    };

    const onSearchChange = async (selectedItem, event) => {

        if (selectedItem === null && event.action === "clear") {
            // clear event is fired, reset the selected item...
            usersList = [];
        } else {
            // console.log("selectedItem ", selectedItem)
            var cat;
            var data = { userId: userId, categoryId: selectedItem.value }

            await axios.post("/api/catalogs/buildCatalog", data)
                .then(res => {
                    var result = res.data.data
                    if (result) {
                        cat = result._id
                    }
                    // console.log("result ", res.data)
                })

            catalog.map((item) => {
                if (item.catalogCategory === selectedItem.link) {
                    cat = item._id
                }
                return item
            })

            // console.log('cat ', cat);
            let newPr = { ...product };
            newPr.categories = selectedItem.label
            newPr.catalogCategory = cat
            newPr.categoryId = selectedItem.value
            setProduct(newPr)
        }
    };

    const CreatSKU = async () => {
        try {
            if (product.title && product.catalogCategory[0]) {
                const body = {
                    title: product.title,
                    catalog: product.catalogCategory[0]
                }
                const res = await axios.post('/api/product/generateSKU', body)
                setProduct({
                    ...product,
                    sku: res.data
                })
                setError({ ...error, skuErr: '' })
            }

        } catch (err) {
            console.log(err);
        }
    }

    const changeProductName = (e) => {
        if (e.target.value.length < 1) {
            setError({ ...error, nameErr: 'Product name cannot be empty' })
        } else if (e.target.value.length > 100) {
            setError({ ...error, nameErr: 'Product name cannot be more than 100 character' })
        } else {
            setError({ ...error, nameErr: '' })
        }

        setProduct({ ...product, title: e.target.value })
    }

    const changeSKU = (e) => {
        if (e.target.value.length < 1) {
            setError({ ...error, skuErr: 'Product SKU cannot be empty' })
        } else {
            setError({ ...error, skuErr: '' })
        }
        setProduct({ ...product, sku: e.target.value })
    }

    const changeIndex = (e) => {
        if (e.target.value === 'True') {
            setProduct({ ...product, index: true })
        } else if (e.target.value === 'False') {
            setProduct({ ...product, index: false })
        }
        setError({ ...error, indexErr: '' })
    }


    const changeIsGray = (e) => {
        setProduct({ ...product, isGray: e.target.checked })
    }

    const changeProductType = (e) => {
        if (e.target.value === 'simpleProduct') {
            setProduct({
                ...product,
                Color: [],
                Diameter: [],
                Length: [],
                Material: [],
                Power: [],
                Size: [],
                Voltage: [],
                Volume: [],
                Packaging: [],
                productType: e.target.value
            })
            setTag([]);
            setAttributes([]);
            setError({ ...error, attrErr: '' });

        } else if (e.target.value === 'variableProduct') {
            const found = attributes.find(element => element === pricAttr);
            if (found) {
                let newArr = [...product[found]];
                newArr.map((item, i) => {
                    if (item.price) {
                        item.price = ''
                    }
                    return item
                })
                setProduct({
                    ...product,
                    [found]: newArr
                })
            }
            var newtag = [...tag];
            newtag.map((item, i) => {
                if (item.name === pricAttr) {
                    item.price = ''
                }
                return item
            })
            setTag(newtag)

            setPricAttr('')
            setProduct({
                ...product,
                productType: e.target.value
            })
            setError({ ...error, attrErr: '' });

        } else {
            setProduct({
                ...product,
                productType: e.target.value
            })
        }
    }

    const changeReadyToShip = (e) => {
        if (e.target.value === 'Yes') {
            setProduct({
                ...product,
                readytoship: true,
                leadTime: []
            })
        } else if (e.target.value === 'No') {
            setProduct({
                ...product,
                readytoship: false,
                leadTime: [{ quantity: '', estimatedTime: '' }],
                tierPrice: [{ quantity: '', price: '' }]
            })
        }

    }

    const changeCustomization = (e) => {
        if (e.target.value === 'Yes') {
            setProduct({
                ...product,
                isCustomization: true
            })
        } else if (e.target.value === 'No') {
            setProduct({
                ...product,
                isCustomization: false,
                logoCustomization: { customization: false },
                graphicsCutomization: { customization: false },
                packagingCustomization: { customization: false },
            })
        }

    }

    const setBuySample = (e) => {
        if (e.target.checked === false) {
            setProduct({ ...product, BuySamples: e.target.checked, samples: [] })
            setError({ ...error, sampleErr: '' })
        } else {
            setProduct({ ...product, BuySamples: e.target.checked })
        }
    }

    const changeKeywords = (e) => {
        e.key === 'Enter' && e.preventDefault();

        if (e.key === 'Enter' && e.target.value !== '') {
            let newArr = [...product.keyWords];

            var value;
            newArr.forEach((item) => {
                if (item.word === e.target.value) {
                    value = e.target.value
                }
                return item
            })

            if (value !== e.target.value) {
                newArr.push({ word: e.target.value })
                setKeyTag([...keyTag, e.target.value])
                setProduct({
                    ...product,
                    keyWords: newArr
                })
            }
            e.target.value = ''
        }
        // e => setProduct({ ...product, keyWords: e.target.value })
    }

    function dltKeyTag(val) {
        let newArr = [...keyTag];

        for (let i = 0; i < newArr.length; i++) {

            if (newArr[i] === val) {
                newArr.splice(i, 1);
                setKeyTag(newArr)
            }
        }

        let newArr2 = [...product.keyWords];

        for (let i = 0; i < newArr2.length; i++) {

            if (newArr2[i].word === val) {
                newArr2.splice(i, 1);
                setProduct({
                    ...product,
                    keyWords: newArr2
                })
            }
        }
    }

    function ChangShortDisc(e) {
        product.shortDescription = e.target.getContent()
    }


    // Start Additional Information
    function ChangDisc(e) {
        product.description = e.target.getContent()
    }


    // Start Set Prices
    const changeUnit = (e) => {
        let unitValue = JSON.parse(e.target.value);
        let newPr = { ...product };
        if (unitValue.unitDisplay === '') {
            newPr.measureUnit = unitValue
            newPr.minOrderQuantity = { ...product.minOrderQuantity, unit: unitValue.unitDisplay }
            newPr.minGroupOrderQuantity = { ...product.minGroupOrderQuantity, unit: unitValue.unitDisplay }
            setError({ ...error, unitErr: 'Please Select Unit of Measure' })
            setProduct(newPr)
        } else {
            newPr.measureUnit = unitValue
            newPr.minOrderQuantity = { ...product.minOrderQuantity, unit: unitValue.unitDisplay }
            newPr.minGroupOrderQuantity = { ...product.minGroupOrderQuantity, unit: unitValue.unitDisplay }
            setProduct(newPr)
            setError({ ...error, unitErr: '' })
        }
    }

    const changeLotSize = (e) => {
        if (isNaN(e.target.value)) {
            setProduct({ ...product, lotsize: '' })
            setError({ ...error, lotsizeErr: 'Please add lot size' })
        } else if (e.target.value <= 0) {
            setProduct({ ...product, lotsize: Number(e.target.value) })
            setError({ ...error, lotsizeErr: 'Lot size should be greater than 0' })
        } else {
            setProduct({
                ...product,
                lotsize: Number(e.target.value),
                minOrderQuantity: { ...product.minOrderQuantity, value: Number(e.target.value) },
                minGroupOrderQuantity: { ...product.minGroupOrderQuantity, value: Number(e.target.value) }
            })
            setError({ ...error, lotsizeErr: '' })
        }
    }

    const changePackageUnit = (e) => {
        setProduct({ ...product, packgingUnit: e.target.value })
    }

    const updateTier = (index, item, arr) => e => {
        let newArr = [...product.tierPrice];
        if (e.target.name === 'quantity') {
            if (isNaN(e.target.value)) {
                setError({ ...error, tierErr: 'Please add Number' })
                newArr[index] = { ...item, [e.target.name]: '' };
            } else if (e.target.value <= 0) {
                setError({ ...error, tierErr: "Quantity should be greater then 0." })
                newArr[index] = { ...item, [e.target.name]: Number(e.target.value) };
            } else if (arr[index - 1] !== undefined && arr[index - 1].quantity + 1 > e.target.value) {
                setError({ ...error, tierErr: `Quantity should be greater then ${arr[index - 1].quantity}.` })
                newArr[index] = { ...item, [e.target.name]: Number(e.target.value) };
            } else if (index > 2) {
                setError({ ...error, tierErr: 'Maximum 3 Tier Price Allowed.' })
                newArr[index] = { ...item, [e.target.name]: Number(e.target.value) };
            } else {
                setError({ ...error, tierErr: '' })
                newArr[index] = { ...item, [e.target.name]: Number(e.target.value) };
            }
        } else if (e.target.name === 'price') {
            if (isNaN(e.target.value)) {
                setError({ ...error, tierErr: 'Please add Number' })
                newArr[index] = { ...item, [e.target.name]: '' };
            } else if (e.target.value <= 0) {
                setError({ ...error, tierErr: "Quantity should be greater then 0." })
                newArr[index] = { ...item, [e.target.name]: (e.target.value) };
            } else {
                setError({ ...error, tierErr: '' })
                newArr[index] = { ...item, [e.target.name]: (e.target.value) };
            }
        }
        setProduct({ ...product, tierPrice: newArr })
    }

    const addTier = () => {
        let newArr = [...product.tierPrice, { quantity: '', price: '' }];
        setProduct({ ...product, tierPrice: newArr })
    }

    function dltTier(i) {
        let newArr = [...product.tierPrice];
        newArr.splice(i, 1);
        setProduct({ ...product, tierPrice: newArr })
        setError({ ...error, tierErr: '' })
    }

    const updateGroup = (index, item) => e => {
        // console.log("item ", e.target.value)
        var newArr = [...product.groupPrice];
        if (e.target.name === 'price') {
            if (isNaN(e.target.value)) {
                setError({ ...error, groupErr: 'Please add Number' })
                newArr[index] = { ...item, price: '' };
            } else if (e.target.value <= 0) {
                setError({ ...error, groupErr: "Quantity should be greater then 0." })
                newArr[index] = { ...item, price: (e.target.value) };
            } else if (index > 2) {
                setError({ ...error, groupErr: 'Maximum 3 Group Price Allowed.' })
                newArr[index] = { ...item, price: (e.target.value) };
            } else {
                setError({ ...error, groupErr: '' })
                newArr[index] = { ...item, price: (e.target.value) };
            }
        } else if (e.target.name === 'groupId') {
            newArr[index] = { ...item, groupId: e.target.value };
            setError({ ...error, groupErr: '' })
        }
        setProduct({ ...product, groupPrice: newArr })
    }

    const addGroup = () => {
        let newArr = [...product.groupPrice, { groupId: '', price: '' }];
        setProduct({ ...product, groupPrice: newArr })
    }

    function dltGroup(i) {
        let newArr = [...product.groupPrice];
        newArr.splice(i, 1);
        setProduct({ ...product, groupPrice: newArr })
    }

    const updateleadTime = (index, item, arr) => e => {

        if (e.target.name === 'quantity') {
            let newArr = [...product.leadTime];

            if (isNaN(e.target.value)) {
                setError({ ...error, leadErr: 'Please add Number' })
                newArr[index] = { ...item, quantity: '' };
            } else if (e.target.value <= 0) {
                setError({ ...error, leadErr: "Quantity should be greater then 0." })
                newArr[index] = { ...item, quantity: Number(e.target.value) };
            } else if (arr[index - 1] !== undefined && arr[index - 1].quantity + 1 > e.target.value) {
                setError({ ...error, leadErr: `Quantity should be greater then ${arr[index - 1].quantity}.` })
                newArr[index] = { ...item, quantity: Number(e.target.value) };
            } else if (index > 2) {
                setError({ ...error, leadErr: 'Maximum 3 Tier Price Allowed.' })
                newArr[index] = { ...item, quantity: Number(e.target.value) };
            } else {
                setError({ ...error, leadErr: '' })
                newArr[index] = { ...item, quantity: Number(e.target.value) };
            }

            let newArr2 = [...product.tierPrice];
            newArr2[index] = { quantity: Number(e.target.value), price: '' };
            setProduct({ ...product, leadTime: newArr, tierPrice: newArr2 })

        } else if (e.target.name === 'estimatedTime') {
            let newArr = [...product.leadTime];
            if (isNaN(e.target.value)) {
                setError({ ...error, leadErr: 'Please add Number' })
                newArr[index] = { ...item, [e.target.name]: '' };
            } else if (e.target.value <= 0) {
                setError({ ...error, leadErr: "Quantity should be greater then 0." })
                newArr[index] = { ...item, [e.target.name]: Number(e.target.value) };
            } else {
                setError({ ...error, leadErr: '' })
                newArr[index] = { ...item, [e.target.name]: Number(e.target.value), unit: 'Days' };
            }
            setProduct({ ...product, leadTime: newArr })
        }
    }

    const addleadTime = () => {
        let newArr = [...product.leadTime, { quantity: '', estimatedTime: '' }];
        setProduct({ ...product, leadTime: newArr })
    }

    function dltleadTime(i) {
        let newArr = [...product.leadTime];
        newArr.splice(i, 1);

        let newArr2 = [...product.tierPrice];
        newArr2.splice(i, 1);
        setProduct({ ...product, leadTime: newArr, tierPrice: newArr2 })
    }

    function changeMinimumOrderValue(value, type) {
        let newValue = 1;
        let oldValue = 0;
        let lotSize = 1;
        if (type === 'minOrder') {
            if (product.minOrderQuantity.value) {
                oldValue = product.minOrderQuantity.value;
            } else {
                oldValue = 0;
            }
        } else if (type === 'minGroup') {
            if (product.minGroupOrderQuantity.value) {
                oldValue = product.minGroupOrderQuantity.value;
            } else {
                oldValue = 0;
            }
            // console.log('here->', oldValue)
        }
        if (product.lotsize !== '') {
            lotSize = product.lotsize;
        }

        if (value === "plus") {
            newValue = oldValue + lotSize
        } else if (value === "minus" && oldValue > 0) {
            newValue = oldValue - lotSize
        } else if (value === "minus" && oldValue === 0) {
            newValue = 0;
        }
        if (type === 'minOrder') {
            setProduct({ ...product, minOrderQuantity: { ...product.minOrderQuantity, value: newValue } })
        } else if (type === 'minGroup') {
            setProduct({ ...product, minGroupOrderQuantity: { ...product.minGroupOrderQuantity, value: newValue } })
        }
    }


    // Start Shipping & Payment Details
    let List = [];

    const loadShipment = (inputValue, callback) => {
        if (!inputValue) {
            callback(List);
        }
        else {
            if (inputValue.length >= 1) {
                if (typeof inputValue !== 'string') { return; }
                if (inputValue === '') {
                    callback(List);
                } else {
                    axios.get(`/api/product/service/search?query=${inputValue}&limit=${10}`)
                        .then(res => {
                            if (res.data !== undefined) {
                                let services = res.data
                                // console.log("service ", services);
                                List = services.map((item) => ({
                                    label: item.serviceName,
                                    value: { service: item._id, serviceName: item.serviceName }
                                }));
                                callback(List);
                            }
                        })
                }
            }
        }
    };

    var prevShipment = [];
    const onSearchShipment = (selectedItem, event) => {
        if (selectedItem === null && event.action === "clear") {
            List = [];
        } else {

            if (product.shipments.length > 0) {
                prevShipment = product.shipments.map((e) => { return e });
            }

            var serviceNames = []
            prevShipment.forEach((item) => {
                if (item.serviceName === selectedItem.value.serviceName) {
                    serviceNames.push(item.serviceName)
                }
                return item
            })

            if (product.shipments.length !== 4 && !serviceNames.includes(selectedItem.value.serviceName)) {
                setProduct({
                    ...product,
                    shipments: [...product.shipments, selectedItem.value]
                })
            }
        }
    }

    function dltShipment(props) {
        for (var i = 0; i < product.shipments.length; i++) {

            if (product.shipments[i].serviceName === props) {
                product.shipments.splice(i, 1)
                setProduct({
                    ...product,
                    shipments: [...product.shipments]
                })
            }

        }
    }

    let List2 = [];

    const loadPayment = (inputValue, callback) => {
        if (!inputValue) {
            callback(List2);
        }
        else {
            if (inputValue.length >= 1) {
                if (typeof inputValue !== 'string') { return; }
                if (inputValue === '') {
                    callback(List2);
                } else {
                    axios.get(`/api/product/payment/search?query=${inputValue}&limit=${10}`)
                        .then(res => {
                            if (res.data !== undefined) {
                                let payments = res.data
                                // console.log("payments ", payments);
                                List2 = payments.map((item) => ({
                                    label: item.methodName,
                                    value: { paymentMethod: item._id, methodName: item.methodName }
                                }));
                                callback(List2);
                            }
                        })
                }
            }
        }
    };

    var prevPayment = [];
    const onSearchPayment = (selectedItem, event) => {
        if (selectedItem === null && event.action === "clear") {
            List2 = [];
        } else {

            if (product.payments.length > 0) {
                prevPayment = product.payments.map((e) => { return e });
            }

            var methodNames = []
            prevPayment.forEach((item) => {
                if (item.methodName === selectedItem.value.methodName) {
                    methodNames.push(item.methodName)
                }
                return item
            })

            if (product.payments.length !== 4 && !methodNames.includes(selectedItem.value.methodName)) {
                setProduct({
                    ...product,
                    payments: [...product.payments, selectedItem.value]
                })
            }
        }
    }

    function dltPayment(props) {
        for (var i = 0; i < product.payments.length; i++) {

            if (product.payments[i].methodName === props) {
                product.payments.splice(i, 1)
                setProduct({
                    ...product,
                    payments: [...product.payments]
                })
            }

        }
    }


    // Start Upload Image
    const [imgAlert, setImgAlert] = useState({ imgErr: '', GallErr: '' });

    const changeImageVideo = async (files) => {

        var file;
        for (let i = 0; i < files.length; i++) {
            file = files[i]
        }

        if (file && (file.type === "video/mp4" || file.type === " video/webm" || file.type === "video/ogg")) {

            if (product.product_main_video !== '') {
                setImgAlert({ imgErr: 'Error: You Can upload only one Video' })
                setTimeout(() => {
                    setImgAlert({ imgErr: '' })
                }, 2000);
            } else if (file && file.size < 607950) {
                let node = document.querySelector('#img');
                node.innerHTML = '<div class="spinner-border text-muted" style="width:2.8em !important; height:2.8em!important;border: 0.25em solid blue !important;border-right-color: transparent !important;"></div>'
                node.disabled = true;
                // console.log('node ', node);
                let formData;
                formData = new FormData();
                formData.append('file', file);

                let res = await axios.post(`/api/product/uploadVideos/${userId}`, formData);
                if (res.status === 200) {
                    let vidRes = res.data

                    node.disabled = false;
                    setProduct({
                        ...product,
                        product_main_video: vidRes.product_videos,
                        product_video: { video: vidRes.product_videos }
                    })
                    setError({ ...error, imageErr: '' })
                    node.innerHTML = '';
                    // console.log("vidRes ", vidRes);
                }
            } else if (file && file.size > 607950) {
                setImgAlert({ imgErr: 'Error: Video size is greater than 30 sec.' })
                setTimeout(() => {
                    setImgAlert({ imgErr: '' })
                }, 2000);
            }

        } else {
            var img;
            var _URL = window.URL;

            if (product.product_main_image !== '') {
                setImgAlert({ imgErr: 'Error: You can upload only one image' })
                setTimeout(() => {
                    setImgAlert({ imgErr: '' })
                }, 2000);
            } else if (file) {
                img = new Image();
                img.onload = async function () {
                    if (this.width >= 400 && this.height >= 400) {
                        let node = document.querySelector('#img');
                        node.innerHTML = '<div class="spinner-border text-muted" style="width:2.8em !important; height:2.8em!important;border: 0.25em solid blue !important;border-right-color: transparent !important;"></div>'
                        node.disabled = true;
                        // console.log('node ', node);
                        let formData;
                        formData = new FormData();
                        formData.append('file', file);

                        let res = await axios.post(`/api/product/uploadDiscImage/${userId}`, formData);
                        if (res.status === 200) {
                            let imgRes = res.data

                            node.disabled = false;
                            var newImages = [...product.product_images];
                            newImages.push(imgRes.product_images)
                            setProduct({
                                ...product,
                                product_main_image: imgRes.product_images.original,
                                product_images: newImages
                            })
                            setError({ ...error, imageErr: '' })
                            node.innerHTML = '';
                            // console.log("imgRes ", imgRes)
                        }
                    } else {
                        setImgAlert({ imgErr: 'Error: Image dimensions are less then 400 * 400.' })
                        setTimeout(() => {
                            setImgAlert({ imgErr: '' })
                        }, 2000);
                    }
                }
                img.src = _URL.createObjectURL(file);
            }

        }
    }

    const changeImageGallery = async (files) => {
        if (product.product_images.length === 5) {
            setImgAlert({ GallErr: 'Error: You can upload only 4 images' })
            setTimeout(() => {
                setImgAlert({ GallErr: '' })
            }, 2000);
        } else if (files.length > 0) {
            var file;
            for (let i = 0; i < files.length; i++) {
                file = files[i]
            }

            var img;
            var _URL = window.URL;
            if (file) {
                img = new Image();
                img.onload = async function () {
                    if (this.width >= 400 && this.height >= 400) {
                        // console.log("Width:" + this.width + "   Height: " + this.height)
                        var node = document.querySelector('#img2');
                        node.innerHTML = '<div class="spinner-border text-muted" style="width:2.8em !important; height:2.8em!important;border: 0.25em solid blue !important;border-right-color: transparent !important;"></div>'
                        node.disabled = true;
                        // console.log('node ', node);
                        var formData;
                        formData = new FormData();
                        formData.append('file', file);

                        let res = await axios.post(`/api/product/uploadDiscImage/${userId}`, formData);
                        if (res.status === 200) {
                            let imgRes = res.data

                            node.disabled = false;
                            var newImages = [...product.product_images];
                            newImages.push(imgRes.product_images)
                            setProduct({
                                ...product,
                                product_images: newImages
                            })
                            node.innerHTML = '';
                            // console.log("imgRes ", imgRes)
                        }
                    } else {
                        setImgAlert({ GallErr: 'Error: Image dimensions are less then 400 * 400.' })
                        setTimeout(() => {
                            setImgAlert({ GallErr: '' })
                        }, 2000);
                    }
                };
                img.src = _URL.createObjectURL(file);
            }

            // if (file.size > 76817) {
            // } else if (file.size < 76817) {
            // }
        }
        // console.log('files', files)
    }


    function dltImage(item) {

        for (var i = 0; i < product.product_images.length; i++) {

            if (product.product_images[i].original === item) {

                product.product_images.splice(i, 1);

                setProduct({
                    ...product,
                    product_images: product.product_images,
                    product_main_image: '',
                })

            }
        }

        // if (product.product_images.length > 1) {

        //     for (var i = 0; i < 1; i++) {

        //         product.product_images.splice(i, 1);

        //         setProduct({
        //             ...product,
        //             product_images: product.product_images
        //         })
        //     }

        // } else {
        //     setProduct({
        //         ...product,
        //         product_main_image: '',
        //         product_images: []
        //     })
        // }

    }

    function dltVideo() {
        setProduct({
            ...product,
            product_main_video: '',
            product_video: ''
        })
    }

    function dltImageGall(item) {
        // console.log('item ', item)

        for (var i = 0; i < product.product_images.length; i++) {

            if (product.product_images[i] === item) {

                product.product_images.splice(i, 1);

                setProduct({
                    ...product,
                    product_images: product.product_images
                })

            }
        }
    }



    // Start Add Attributes
    const [attributeList, setAttributeList] = useState(['Volume', 'Voltage', 'Length', 'Power', 'Diameter', 'Color', 'Size', 'Material', 'Packaging'])

    const [attributes, setAttributes] = useState([])

    const [addAttributes, setAddAttributes] = useState([])

    const [tag, setTag] = useState([])

    const [totalAttr, setTotalAttr] = useState([]);


    function changePrimaryAttr(name) {
        if (attributes.length < 2) {
            setAttributes([...attributes, name])

            for (var i = 0; i < attributeList.length; i++) {

                if (attributeList[i] === name) {

                    attributeList.splice(i, 1);
                }

            }
        }
        if (attributes.length <= 2) {
            setError({ ...error, attrErr: '' })
        }
    }

    function changeAdditionalAttr(name) {

        setAddAttributes([...addAttributes, name])

        for (var i = 0; i < attributeList.length; i++) {

            if (attributeList[i] === name) {

                attributeList.splice(i, 1);
            }

        }
    }

    const [opt, setOpt] = useState({ vol: 'm', len: 'km' })

    const changeValume = (e) => {
        setOpt({
            ...opt,
            vol: e.target.value
        })
    }

    const changeLength = (e) => {
        setOpt({
            ...opt,
            len: e.target.value
        })
    }

    const [pricAttr, setPricAttr] = useState('')
    function ChangePricingAttr(prop) {
        setPricAttr(prop)

        setError({ ...error, attrErr: '', tierErr: '' });
    }

    function changeUnitValue(e, val) {
        // console.log('val ', val, 'value ', e.target.value);
        let newArr = [...product[val.name]];

        newArr.map((item, i) => {
            if (item[val.name] === val.value) {
                if (isNaN(e.target.value)) {
                    item.price = ''
                } else {
                    item.price = (e.target.value)
                }
            }
            return item
        })
        // console.log('newArr ', newArr);
        setProduct({
            ...product,
            [val.name]: newArr
        })

        var newtag = [...tag];
        newtag.map((item, i) => {
            if (item.value === val.value) {
                if (isNaN(e.target.value)) {
                    item.price = ''
                } else {
                    item.price = (e.target.value)
                }
            }
            return item
        })
        // console.log("newtag ", newtag);
        setTag(newtag)
    }
    // console.log("Product ", product.index);
    // console.log('tag ', tag);

    const attrTag = (e, type) => {
        // console.log("type ", type);
        e.key === 'Enter' && e.preventDefault();

        if (e.key === 'Enter' && e.target.value !== '') {
            if (e.target.name === 'Volume') {

                let newArr = [...product[e.target.name]];
                newArr.push({ [e.target.name]: `${e.target.value} ${opt.vol}`, attrType: type })

                setTag([...tag, { name: e.target.name, value: `${e.target.value} ${opt.vol}` }])
                setProduct({
                    ...product,
                    [e.target.name]: newArr
                })

                let newAtt = [...totalAttr]
                let initialArr = [];
                newAtt.map((item, i) => {
                    var key = Object.keys(item)
                    initialArr.push(key[0])
                    if (key.includes(e.target.name)) {
                        newAtt[i] = { [e.target.name]: newArr };
                    }
                    return key
                })

                if (initialArr.includes(e.target.name) === false) {
                    newAtt.push({ [e.target.name]: newArr })
                }
                setTotalAttr(newAtt)

            } else if (e.target.name === 'Length') {

                let newArr = [...product[e.target.name]];
                newArr.push({ [e.target.name]: `${e.target.value} ${opt.len}`, attrType: type })

                setTag([...tag, { name: e.target.name, value: `${e.target.value} ${opt.len}` }])
                setProduct({
                    ...product,
                    [e.target.name]: newArr
                })

                let newAtt = [...totalAttr]
                let initialArr = [];
                newAtt.map((item, i) => {
                    var key = Object.keys(item)
                    initialArr.push(key[0])
                    if (key.includes(e.target.name)) {
                        newAtt[i] = { [e.target.name]: newArr };
                    }
                    return key
                })

                if (initialArr.includes(e.target.name) === false) {
                    newAtt.push({ [e.target.name]: newArr })
                }
                setTotalAttr(newAtt)

            } else {

                let newArr = [...product[e.target.name]];
                newArr.push({ [e.target.name]: e.target.value, attrType: type })

                setTag([...tag, { name: e.target.name, value: e.target.value }])
                setProduct({
                    ...product,
                    [e.target.name]: newArr
                })

                let newAtt = [...totalAttr]
                let initialArr = [];
                newAtt.map((item, i) => {
                    var key = Object.keys(item)
                    initialArr.push(key[0])
                    if (key.includes(e.target.name)) {
                        newAtt[i] = { [e.target.name]: newArr };
                    }
                    return key
                })

                if (initialArr.includes(e.target.name) === false) {
                    newAtt.push({ [e.target.name]: newArr })
                }
                setTotalAttr(newAtt)
            }
            e.target.value = ''
            setError({ ...error, attrErr: '' });
        }
    }


    function dltTag(val) {
        let newArr = [...tag];

        for (let i = 0; i < newArr.length; i++) {

            if (newArr[i].value === val.value) {
                newArr.splice(i, 1);
                setTag(newArr)
            }
        }

        let newAtt = [...totalAttr]
        newAtt.map((item) => {
            return Object.entries(item).map(([key, value]) => {
                if (key.includes(val.name)) {
                    for (let i = 0; i < value.length; i++) {

                        if (value[i][val.name] === val.value) {
                            value.splice(i, 1);
                        }
                    }
                }
                return key
            })
        })
        setTotalAttr(newAtt)

        let newArr2 = [...product[val.name]];

        for (let i = 0; i < newArr2.length; i++) {

            if (newArr2[i][val.name] === val.value) {
                newArr2.splice(i, 1);
                setProduct({
                    ...product,
                    [val.name]: newArr2
                })
            }
        }
    }

    function dltAttrInput(name) {
        let newArr = [...attributes];
        let newArr2 = [...attributeList];

        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i] === name) {
                newArr.splice(i, 1);
                newArr2.push(name);

                setAttributes(newArr)
                setAttributeList(newArr2)
                setProduct({
                    ...product,
                    [name]: []
                })
            }
        }

        let newAtt = [...totalAttr]
        newAtt.map((item, i) => {
            var key = Object.keys(item)
            if (key.includes(name)) {
                newAtt.splice(i, 1);
            }
            return key
        })
        setTotalAttr(newAtt)

        var filteredArray = tag.filter(e => e.name !== name)
        setTag(filteredArray)
    }

    function dltAddAttrInput(name) {
        let newArr = [...addAttributes];
        let newArr2 = [...attributeList];

        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i] === name) {
                newArr.splice(i, 1);
                newArr2.push(name);

                setAddAttributes(newArr)
                setAttributeList(newArr2)
                setProduct({
                    ...product,
                    [name]: []
                })
            }
        }

        let newAtt = [...totalAttr]
        newAtt.map((item, i) => {
            var key = Object.keys(item)
            if (key.includes(name)) {
                newAtt.splice(i, 1);
            }
            return key
        })
        setTotalAttr(newAtt)

        var filteredArray = tag.filter(e => e.name !== name)
        setTag(filteredArray)
    }

    var nerArr = [...attributes]
    var nerArr2 = [...tag]
    var nerArr3 = [...addAttributes]

    var newTotalAttr = [...totalAttr]

    attributeList.map((attr, i) => {
        return Object.entries(product).map(([key, value]) => {
            if (attr === key && value.length > 0) {

                newTotalAttr.push({ [key]: value });
                setTotalAttr(newTotalAttr)
                if (value[0].attrType === 'additional') {
                    nerArr3.push(attr)
                    setAddAttributes(nerArr3)
                } else {
                    nerArr.push(attr)
                    setAttributes(nerArr)
                    if (value[0].price) {
                        setPricAttr(attr)
                    }
                }
                // console.log('nerArr2 ', nerArr)

                for (var i = 0; i < attributeList.length; i++) {

                    if (attributeList[i] === attr) {

                        attributeList.splice(i, 1);
                    }

                }

                value.map((item) => {
                    nerArr2.push({ name: attr, value: item[attr], price: item.price })
                    return attr
                })
                setTag(nerArr2)
            }
            return key
        });
    })

    // console.log("attributes ", attributes);

    const palette = {
        red: '#ff0000',
        blue: '#0000ff',
        green: '#00ff00',
        yellow: 'yellow',
        cyan: 'cyan',
        lime: 'lime',
        gray: 'gray',
        orange: 'orange',
        purple: 'purple',
        black: 'black',
        white: 'white',
        pink: 'pink',
        darkblue: 'darkblue',
    };

    const [colors, setColors] = useState('');

    const addColor = (val) => {
        // console.log('color ', val);

        setColors(val)
    }

    const changeColor = (e) => {
        setColors(e.target.value)
    }


    const uplColorImg = async (e, val) => {
        var file = e.currentTarget.files[0]
        // console.log('val ', val);

        if (file) {
            let node = document.querySelector('#colorImg');
            node.innerHTML = '<div class="spinner-border text-muted" style="width:2.8em !important; height:2.8em!important;border: 0.25em solid blue !important;border-right-color: transparent !important;"></div>'
            // console.log('node ', node);
            node.disabled = true;
            let formData;
            // let xhr;
            // xhr = new XMLHttpRequest();
            // xhr.withCredentials = false;
            // let token = window.localStorage.getItem('token')
            // // console.log('token ', token);
            // xhr.open('POST', `/api/product/upload-thumbnails/${userId}`);
            // xhr.setRequestHeader('x-auth-token', token);
            // xhr.onload = function () {
            //     var json;
            //     if (xhr.status !== 200) {
            //         return;
            //     }
            //     json = JSON.parse(xhr.responseText);
            //     node.disabled = false;

            //     let newArr = [...product.Color];

            //     newArr.map((item) =>
            //         item.Color === val.value ?
            //             item.img = json.product_images
            //             : ""
            //     )
            //     setProduct({
            //         ...product,
            //         Color: newArr
            //     })
            //     node.innerHTML = '';
            //     // console.log("image res ", json.product_images)
            // };
            formData = new FormData();
            formData.append('file', file);
            // xhr.send(formData);
            let res = await axios.post(`/api/product/upload-thumbnails/${userId}`, formData);
            if (res.status === 200) {
                let imgRes = res.data

                node.disabled = false;
                let newArr = [...product.Color];

                newArr.map((item) =>
                    item.Color === val.value ?
                        item.img = imgRes.product_images
                        : ""
                )
                setProduct({
                    ...product,
                    Color: newArr
                })
                node.innerHTML = '';
                // console.log("imgRes ", imgRes)
            }
        }
    }

    const dltColorImg = (items) => {
        let newArr = [...product.Color];
        newArr.map((item) =>
            item === items ?
                item.img = {}
                : ""
        )
        setProduct({
            ...product,
            Color: newArr
        })
    }

    const uplPackagingImg = async (e, val) => {
        var file = e.currentTarget.files[0]
        // console.log('val ', val);

        if (file) {
            let node = document.querySelector('#packagingImg');
            node.innerHTML = '<div class="spinner-border text-muted" style="width:2.8em !important; height:2.8em!important;border: 0.25em solid blue !important;border-right-color: transparent !important;"></div>'
            // console.log('node ', node);
            node.disabled = true;
            let formData;
            formData = new FormData();
            formData.append('file', file);
            // xhr.send(formData);
            let res = await axios.post(`/api/product/upload-thumbnails/${userId}`, formData);
            if (res.status === 200) {
                let imgRes = res.data

                node.disabled = false;
                let newArr = [...product.Packaging];

                newArr.map((item) =>
                    item.Packaging === val.value ?
                        item.img = imgRes.product_images
                        : ""
                )
                setProduct({
                    ...product,
                    Packaging: newArr
                })
                node.innerHTML = '';
                // console.log("imgRes ", imgRes)
            }
        }
    }

    const dltPackagingImg = (items) => {
        let newArr = [...product.Packaging];
        newArr.map((item) =>
            item === items ?
                item.img = {}
                : ""
        )
        setProduct({
            ...product,
            Packaging: newArr
        })
    }


    // Start Buy Samples
    const updateSample = (e) => {
        let newArr = [...product.samples];
        if (e.target.name === 'noOfUnits') {
            if (isNaN(e.target.value)) {
                newArr[0] && newArr[0].noOfUnits ? newArr[0].noOfUnits = '' : newArr[0] = { ...newArr[0], 'noOfUnits': '' }
                setError({ ...error, sampleErr: 'Please add Number' })
            } else if (e.target.value <= 0) {
                newArr[0] && newArr[0].noOfUnits ? newArr[0].noOfUnits = Number(e.target.value) : newArr[0] = { ...newArr[0], 'noOfUnits': Number(e.target.value) }
                setError({ ...error, sampleErr: 'Value should be greater than 0' })
            } else {
                newArr[0] && newArr[0].noOfUnits ? newArr[0].noOfUnits = Number(e.target.value) : newArr[0] = { ...newArr[0], 'noOfUnits': Number(e.target.value) }
                setError({ ...error, sampleErr: '' })
            }
        } else if (e.target.name === 'samplePrice') {
            if (isNaN(e.target.value)) {
                newArr[0] && newArr[0].samplePrice ? newArr[0].samplePrice = '' : newArr[0] = { ...newArr[0], 'samplePrice': '' }
                setError({ ...error, sampleErr: 'Please add Number' })
            } else if (e.target.value <= 0) {
                newArr[0] && newArr[0].samplePrice ? newArr[0].samplePrice = (e.target.value) : newArr[0] = { ...newArr[0], 'samplePrice': (e.target.value) }
                setError({ ...error, sampleErr: 'Value should be greater than 0' })
            } else {
                newArr[0] && newArr[0].samplePrice ? newArr[0].samplePrice = (e.target.value) : newArr[0] = { ...newArr[0], 'samplePrice': (e.target.value) }
                setError({ ...error, sampleErr: '' })
            }
        }
        setProduct({ ...product, samples: newArr })
    }


    var firstAttributeList = [];
    var secoundAttributeList = [];

    var combinations = []

    if (attributes.length > 0) {
        for (let i = 0; i < attributes.length; i++) {
            for (let j = 0; j < totalAttr.length; j++) {
                if (Object.keys(totalAttr[j])[0] === attributes[i]) {
                    if (!firstAttributeList.length) {
                        firstAttributeList = totalAttr[j][attributes[i]]
                    } else {
                        secoundAttributeList = totalAttr[j][attributes[i]]
                    }
                }

            }
        }
    }

    if (secoundAttributeList.length > 0) {
        for (let i = 0; i < firstAttributeList.length; i++) {
            for (let j = 0; j < secoundAttributeList.length; j++) {
                combinations.push({
                    // samplePrice: 0,
                    // noOfUnits: 0,
                    variationFirstAttribute: {
                        name: attributes[0],
                        value: firstAttributeList[i][attributes[0]],
                        img: firstAttributeList[i].img
                    },
                    variationSecoundAttribute: {
                        name: attributes[1],
                        value: secoundAttributeList[j][attributes[1]],
                        img: secoundAttributeList[j].img
                    }
                })
                // combinations.push({
                //     variationFirstAttribute: {
                //         name: attributes[1],
                //         value: secoundAttributeList[j][attributes[1]],
                //         img: secoundAttributeList[j].img
                //     },
                //     variationSecoundAttribute: {
                //         name: attributes[0],
                //         value: firstAttributeList[i][attributes[0]],
                //         img: firstAttributeList[i].img
                //     }
                // })
            }
        }
    } else {
        for (let i = 0; i < firstAttributeList.length; i++) {
            combinations.push({
                variationFirstAttribute: {
                    name: attributes[0],
                    value: firstAttributeList[i][attributes[0]],
                    img: firstAttributeList[i].img
                },
            })
        }
    }


    const updateVPSample = (item, index) => e => {
        let newArr = [...product.samples];
        if (e.target.name === 'noOfUnits') {
            if (isNaN(e.target.value)) {
                newArr[index] = { ...item, noOfUnits: '' };
                setError({ ...error, sampleErr: 'Please add Number' })
            } else if (e.target.value <= 0) {
                newArr[index] = { ...item, noOfUnits: Number(e.target.value) };
                setError({ ...error, sampleErr: 'Value should be greater than 0' })
            } else {
                newArr[index] = { ...item, noOfUnits: Number(e.target.value) };
                setError({ ...error, sampleErr: '' })
            }
        } else if (e.target.name === 'samplePrice') {
            if (isNaN(e.target.value)) {
                newArr[index] = { ...item, samplePrice: '' };
                setError({ ...error, sampleErr: 'Please add Number' })
            } else if (e.target.value <= 0) {
                newArr[index] = { ...item, samplePrice: (e.target.value) };
                setError({ ...error, sampleErr: 'Value should be greater than 0' })
            } else {
                newArr[index] = { ...item, samplePrice: (e.target.value) };
                setError({ ...error, sampleErr: '' })
            }
        } else if (e.target.name === 'variation') {
            var valueArr = e.target.value.split(',');
            if (valueArr.length > 1) {
                combinations.map((val) => {
                    if (val.variationFirstAttribute.value === valueArr[0] && val.variationSecoundAttribute.value === valueArr[1]) {
                        newArr[index] = { ...item, variationFirstAttribute: val.variationFirstAttribute, variationSecoundAttribute: val.variationSecoundAttribute }
                    }
                    return val
                })
            } else {
                combinations.map((val) => {
                    if (val.variationFirstAttribute.value === valueArr[0]) {
                        newArr[index] = { ...item, variationFirstAttribute: val.variationFirstAttribute, variationSecoundAttribute: {} }
                    }
                    return val
                })
            }
            setError({ ...error, sampleErr: '' })
        }
        setProduct({ ...product, samples: newArr })
    }

    const addSample = () => {
        let newArr = [...product.samples, { noOfUnits: '', samplePrice: '', variationFirstAttribute: {}, variationSecoundAttribute: {} }];
        setProduct({ ...product, samples: newArr })
    }

    function dltSample(i) {
        let newArr = [...product.samples];
        newArr.splice(i, 1);
        setProduct({ ...product, samples: newArr })
    }

    // console.log("firstAttributeList  =  ", firstAttributeList, '  secoundAttributeList  =  ', secoundAttributeList,);

    // console.log("product ", product);

    const goBack = () => {
        history.push("/listProducts");
    };


    const { categories } = product;

    return (
        <div className='container'>
            {alert.msg &&
                <div className="row justify-content-center">
                    <div className={`alert alert-${alert.alertType}`} style={{ zIndex: '9' }} role="alert">
                        {alert.msg}
                    </div>
                </div>
            }
            <form onSubmit={(e) => onSubmit(e)}>
                {loading && <ModalProgressBar />}

                {/* Basic Info */}
                <div className="card card-custom">
                    <div className="card-header py-5">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="text-primary">
                                Basic Info
                            </h3>
                        </div>
                    </div>

                    <div className="form">
                        <div className="card-body">

                            <div className="form-group row">
                                <label className="col-lg-1"></label>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>User Business Name :</label>

                                {product.merchant_id && product.merchant_id.images && product.merchant_id.images.profile_pic && product.merchant_id.images.profile_pic[100] ?
                                    <span className="symbol" style={{ marginTop: -4, paddingLeft: 11 }}>
                                        <img className="img" alt="Pic" style={{ width: 35, height: 35 }}
                                            src={process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + product.merchant_id.images.profile_pic[100]} />
                                    </span> : ''
                                }
                                {product.merchant_id && product.merchant_id.business_name ?
                                    <Link
                                        // className="menu-link"
                                        to={{ pathname: `./vendorView`, search: `?userId=${product.merchant_id._id}` }}
                                    >

                                        <span className="col-lg-6 col-md-6" style={{ fontSize: 16, fontWeight: 'bold' }}>{product.merchant_id.business_name} </span>
                                    </Link> :
                                    ''
                                }
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Category
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <AsyncSelect placeholder="Search specific category for your product" onChange={onSearchChange} isClearable defaultOptions
                                        loadOptions={loadOptions} name="main_categories" id="main_categories" className="mb-1" />

                                    {categories && <span className="text-info" style={{ fontWeight: 'bold', fontSize: 14 }}>{categories}</span>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Product Name
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <input type="text" className="form-control" name="title" value={product.title}
                                        onChange={changeProductName}
                                    />
                                </div>
                            </div>
                            {error.nameErr ?
                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <p style={{ color: 'red' }}>{error.nameErr}</p>
                                    </div>
                                </div> : ''
                            }

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    SKU
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <input type="text" className="form-control" name="sku" value={product.sku}
                                        onChange={changeSKU}
                                    />
                                    <Button variant="link" onClick={CreatSKU}>Generate SKU</Button>
                                </div>
                            </div>
                            {error.skuErr ?
                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <p style={{ color: 'red' }}>{error.skuErr}</p>
                                    </div>
                                </div> : ''
                            }

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Index
                                </label>
                                <div className="col-lg-4 col-md-4">
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="True"
                                                value="True"
                                                checked={product.index === true ? true : false}
                                                onChange={changeIndex}
                                            />
                                            <span></span>True
                                        </label>
                                    </div>
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="False"
                                                value="False"
                                                checked={product.index === false ? true : false}
                                                onChange={changeIndex}
                                            />
                                            <span></span> False
                                        </label>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4">
                                    <div className="checkbox-inline">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                name="isGray"
                                                checked={product.isGray}
                                                onChange={changeIsGray}
                                            />
                                            <span></span>
                                            <p className="form-text" style={{ fontSize: 13, marginBottom: 0 }}>Is Gray {product.isGray ? '(True)' : '(False)'}</p>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {error.indexErr ?
                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <p style={{ color: 'red' }}>{error.indexErr}</p>
                                    </div>
                                </div> : ''
                            }

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Select type of Product
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="simpleProduct"
                                                value="simpleProduct"
                                                checked={product.productType && product.productType === 'simpleProduct' ? true : false}
                                                onChange={changeProductType}
                                            />
                                            <span></span>Simple Product
                                        </label>
                                    </div>
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="variableProduct"
                                                value="variableProduct"
                                                checked={product.productType && product.productType === 'variableProduct' ? true : false}
                                                onChange={changeProductType}
                                            />
                                            <span></span> Variable Product (with fixed price)
                                        </label>
                                    </div>
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="productWithVeriablePrice"
                                                value="productWithVeriablePrice"
                                                checked={product.productType && product.productType === 'productWithVeriablePrice' ? true : false}
                                                onChange={changeProductType}
                                            />
                                            <span></span> Variable Product (with variable price)
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Ready to ship?
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="readytoship"
                                                value="Yes"
                                                checked={product.readytoship === true}
                                                onChange={changeReadyToShip}
                                            />
                                            <span></span> Yes
                                        </label>
                                    </div>
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="readytoship"
                                                value="No"
                                                checked={product.readytoship === false}
                                                onChange={changeReadyToShip}
                                            />
                                            <span></span> No (I have lead time)
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {product.readytoship === false ?
                                <>
                                    <div className="form-group row" ref={scrollRef}>
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <span style={{ fontWeight: 'bold' }}>Lead Time</span>
                                            <div className="separator separator-solid mt-4"></div>
                                        </div>
                                    </div>

                                    {product.leadTime[0] && product.leadTime.map((item, key, arr) =>
                                        <div className="form-group row" key={key}>
                                            <div className="col-lg-1"></div>
                                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                Tier {key + 1}
                                            </label>
                                            <div className="col-lg-3 col-md-3">
                                                {key === 0 ? <span style={{ opacity: .5 }}>Quantity</span> : <span></span>}
                                                <input type="text" className="form-control mt-1" name="quantity" value={item.quantity ? item.quantity : ''} style={{ position: 'relative' }}
                                                    onChange={updateleadTime(key, item, arr)}
                                                />
                                                {/* {item.quantity <= 0 ? <p style={{ color: 'red' }}>Quantity should be greater then 0.</p> : ''} */}

                                                {/* {arr[key - 1] !== undefined && arr[key - 1].quantity > item.quantity ?
                                                    <p style={{ color: 'red' }}>Quantity should be greater then {arr[key - 1].quantity}.</p>
                                                    : ''} */}

                                                {key === 0 ? <span style={{ position: 'absolute', right: 20, top: 35, opacity: .5 }}>1-{item.quantity}</span>
                                                    : <span style={{ position: 'absolute', right: 20, top: 15, opacity: .5 }}>{arr[key - 1] !== undefined ? arr[key - 1].quantity + 1 : 1}-{item.quantity}</span>}
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                {key === 0 ? <span style={{ opacity: .5 }}>Est. Time</span> : <span></span>}
                                                <input type="text" className="form-control mt-1" name="estimatedTime" value={item.estimatedTime ? item.estimatedTime : ''} style={{ position: 'relative' }}
                                                    onChange={updateleadTime(key, item, arr)}
                                                />
                                                {key === 0 ? <span style={{ position: 'absolute', right: 20, top: 35, opacity: .5 }}>{item.unit}</span>
                                                    : <span style={{ position: 'absolute', right: 20, top: 15, opacity: .5 }}>{item.unit}</span>}
                                            </div>
                                            {key >= 1 ? <span style={{ marginTop: 15, cursor: 'pointer' }} onClick={() => { dltleadTime(key) }}><i className="fa fa-trash"></i></span> : ''}
                                        </div>
                                    )}

                                    {error.leadErr ?
                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            </label>
                                            <div className="col-lg-6 col-md-6">
                                                <p style={{ color: 'red' }}>{error.leadErr}</p>
                                            </div>
                                        </div> : ''
                                    }

                                    {product.leadTime.length <= 2 ?
                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            </label>
                                            <div className="col-lg-6 col-md-6">
                                                <Button onClick={addleadTime} variant="link">Add Tier</Button>
                                            </div>
                                        </div> : ''
                                    }
                                </> : ''
                            }

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Get quotes or receive orders?
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <label className="checkbox-inline d-flex align-items-center">
                                        <input className="mr-2" type="checkbox" name="getQuotes" checked={product.getQuotes} onChange={e => setProduct({ ...product, getQuotes: e.target.checked })} /> Get Quotes
                                    </label>
                                    <label className="checkbox-inline d-flex align-items-center">
                                        <input className="mr-2" type="checkbox" name="getOrders" checked={product.getOrders} onChange={e => setProduct({ ...product, getOrders: e.target.checked })} /> Get Orders
                                    </label>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Customization Available?
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="isCustomization"
                                                value="Yes"
                                                checked={product.isCustomization === true}
                                                onChange={changeCustomization}
                                            />
                                            <span></span> Yes
                                        </label>
                                    </div>
                                    <div className="radio-inline">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="isCustomization"
                                                value="No"
                                                checked={product.isCustomization === false}
                                                onChange={changeCustomization}
                                            />
                                            <span></span> No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Buy Samples?
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <label className="checkbox-inline d-flex align-items-center">
                                        <input className="mr-2" type="checkbox" name="getQuotes" checked={product.BuySamples} onChange={setBuySample} /> Enable buy sample
                                    </label>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Keywords
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <input type="text" className="form-control" name="keyWords" placeholder="Enter Keywords closely related to your business"
                                        onKeyPress={changeKeywords}
                                    />
                                    {keyTag && keyTag.map((val, key) => {
                                        return <span className="cross_btn mt-3" key={key}>{val}<i className="fas fa-times-circle" onClick={() => { dltKeyTag(val) }}></i></span>
                                    })}
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Product Description (Short)
                                </label>
                                <div className="col-lg-6 col-xl-6">
                                    <Editor
                                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={product.shortDescription}
                                        init={{
                                            height: 200,
                                            menubar: false,
                                            // forced_root_block: false,
                                            plugins: [
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'bold italic',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                        onChange={ChangShortDisc}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {/* Upload Image */}
                <div className="card card-custom mt-5">
                    <div className="card-header py-5">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="text-primary">
                                Upload Image
                            </h3>
                        </div>
                    </div>

                    <div className="form">
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Upload front image/video
                                </label>
                                <div className="col-lg-5 col-md-5">
                                    <DropzoneArea
                                        acceptedFiles={['image/png', 'image/jpeg', 'image/gif', 'video/mp4', 'video/webm', '.ogg', '.ogv']}
                                        filesLimit={8}
                                        dropzoneText={"Optimal image size is 400px by 400px, video resolution will be 1280px by 720px. Supports JPG, PNG, MP4, MOV, GIF."}
                                        dropzoneParagraphClass='p-5'
                                        onChange={changeImageVideo}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    {product.product_main_image ?
                                        <div className='col mb-3'>
                                            <img src={BucketUrl + '/' + product.product_main_image} alt='img'
                                                style={{ width: '50px', height: '50px', border: '1px solid black', position: 'relative' }} />
                                            <i className="fas fa-times-circle" onClick={() => { dltImage(product.product_main_image) }}
                                                style={{ top: '-15px', right: '214px', fontSize: '14px', position: 'absolute', cursor: 'pointer' }} ></i>
                                        </div>
                                        : ''
                                    }
                                    {product.product_main_video ?
                                        <div className='col mb-3'>
                                            <video width="50px" height="50px" controls >
                                                <source src={BucketUrl + '/' + product.product_main_video} type="video/mp4" />
                                            </video>
                                            <i className="fas fa-times-circle" onClick={() => { dltVideo() }}
                                                style={{ top: '-15px', right: '214px', fontSize: '14px', position: 'absolute', cursor: 'pointer' }} ></i>
                                        </div>
                                        : ''
                                    }
                                    <div className='row' id='img'></div>
                                    {
                                        imgAlert.imgErr !== '' ?
                                            <h6 className='row' style={{ color: 'red' }}>{imgAlert.imgErr}</h6>
                                            : ''
                                    }
                                </div>
                            </div>

                            {error.imageErr ?
                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <p style={{ color: 'red' }}>{error.imageErr}</p>
                                    </div>
                                </div> : ''
                            }

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Upload product gallery
                                </label>
                                <div className="col-lg-5 col-md-5">
                                    <DropzoneArea
                                        acceptedFiles={['image/png', 'image/jpeg', 'image/gif']}
                                        filesLimit={20}
                                        dropzoneText={"Optimal size is 400px by 400px. Supports JPG, PNG"}
                                        dropzoneParagraphClass='p-5'
                                        onChange={changeImageGallery}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    {product.product_images.map((items, key) => {
                                        return items.original !== product.product_main_image ?
                                            <div className='col mb-3' key={key}>
                                                <img src={BucketUrl + '/' + items.original} key={items.original} alt='img'
                                                    style={{ width: '50px', height: '50px', border: '1px solid black', position: 'relative' }} />
                                                <i className="fas fa-times-circle" onClick={() => { dltImageGall(items) }} key={key}
                                                    style={{ top: '-15px', right: '214px', fontSize: '14px', position: 'absolute', cursor: 'pointer' }} ></i>
                                            </div>
                                            :
                                            ''
                                    })
                                    }
                                    <div className='row' id='img2'></div>
                                    {
                                        imgAlert.GallErr !== '' ?
                                            <h6 className='row' style={{ color: 'red' }}>{imgAlert.GallErr}</h6>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Shipping & Payment Details */}
                <div className="card card-custom mt-5">
                    <div className="card-header py-5">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="text-primary">
                                Shipping & Payment Details
                            </h3>
                        </div>
                    </div>

                    <div className="form">
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Logistic Service
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <AsyncSelect placeholder="Search logistic partners for product shipping" name="shipments" id="shipments"
                                        onChange={onSearchShipment} isClearable defaultOptions loadOptions={loadShipment} />

                                    {product.shipments && product.shipments.map((item, key) => {
                                        return <span className="cross_btn mt-3" key={key}>{item.serviceName}<i className="fas fa-times-circle" onClick={() => { dltShipment(item.serviceName) }}></i></span>
                                    })}

                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Payment Methods
                                </label>
                                <div className="col-lg-6 col-md-6">
                                    <AsyncSelect placeholder="Search payment methods that suits you" name="payments" id="payments"
                                        onChange={onSearchPayment} isClearable defaultOptions loadOptions={loadPayment} />

                                    {product.payments && product.payments.map((item, key) => {
                                        return <span className="cross_btn mt-3" key={key}>{item.methodName}<i className="fas fa-times-circle" onClick={() => { dltPayment(item.methodName) }}></i></span>
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Add Attributes */}
                {product.productType === 'variableProduct' || product.productType === 'productWithVeriablePrice' ?
                    <div className="card card-custom mt-5">
                        <div className="card-header py-5">
                            <div className="card-title align-items-start flex-column">
                                <h3 className="text-primary">
                                    Add Attributes
                                </h3>
                            </div>
                        </div>

                        <div className="form">
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <span style={{ fontWeight: 'bold' }}>Primary Attribute</span>
                                        <div className="separator separator-solid mt-4"></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Add Attribute
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <h5 style={{ textAlign: 'center' }}>Click Button to Add Attribute</h5>
                                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                            {attributeList.map((btnName, key) =>
                                                <Button variant="outline-primary btn-sm" style={{ width: '23%', margin: 6 }} key={key} onClick={() => { changePrimaryAttr(btnName) }}>
                                                    {btnName}
                                                </Button>
                                            )}
                                            {/* .sort((a, b) => a.localeCompare(b)) */}
                                        </div>
                                    </div>
                                </div>

                                {attributes && attributes.map((item, key) =>
                                    <div className="form-group row" key={key} style={product.productType === 'productWithVeriablePrice' && pricAttr === item ? { backgroundColor: 'aliceblue', padding: 7 } : {}}>
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            {item}
                                        </label>
                                        {item === 'Volume' ?
                                            <div className={product.productType === 'productWithVeriablePrice' ? 'col-lg-5 col-md-5' : 'col-lg-6 col-md-6'}>
                                                <InputGroup>
                                                    <FormControl aria-label="Text input with dropdown button"
                                                        placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'price') }} name={item} />

                                                    <Form.Select onChange={changeValume}>
                                                        <option value='m'>m</option>
                                                        <option value='dm'>dm</option>
                                                        <option value='cm'>cm</option>
                                                        <option value='mm'>mm</option>
                                                    </Form.Select>
                                                </InputGroup>
                                                {pricAttr === item ?
                                                    <>
                                                        {tag && tag.map((val, key) => {
                                                            return val && val.name === item ?
                                                                <div className="row" key={key}>
                                                                    <div className="col-lg-5 col-md-5 mt-2">
                                                                        {key === 0 ? <span style={{ opacity: .7 }}>Attribute</span> : <span></span>}
                                                                        <input type="text" className="form-control mt-2" name="attribute" value={val.value}
                                                                            disabled />
                                                                    </div>
                                                                    <div className="col-lg-5 col-md-5 mt-2">
                                                                        {key === 0 ? <span style={{ opacity: .7 }}>Unit Price</span> : <span></span>}
                                                                        <input type="text" className="form-control mt-2" name="unitPrice" value={val.price ? val.price : ''}
                                                                            onChange={(e) => { changeUnitValue(e, val) }} />
                                                                        {val.price ? '' : <p style={{ color: 'red', marginBottom: 0, marginTop: 3 }}>Please Add Unit Price</p>}
                                                                    </div>
                                                                    <div className="col-lg-1 col-xl-1">
                                                                        <i className={`fas fa-trash ${key === 0 ? 'mt-14' : 'mt-9'} cursor-pointer`} onClick={() => { dltTag(val) }}></i>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <span key={key}></span>
                                                        })}
                                                    </>
                                                    :
                                                    <>
                                                        {tag && tag.map((val, key) => {
                                                            return val && val.name === item ?
                                                                <span className="cross_btn mt-3" key={key}>{val.value}<i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i></span>
                                                                : <span key={key}></span>
                                                        })}
                                                    </>
                                                }
                                            </div>
                                            : item === 'Length' ?
                                                <div className={product.productType === 'productWithVeriablePrice' ? 'col-lg-5 col-md-5' : 'col-lg-6 col-md-6'}>
                                                    <InputGroup>
                                                        <FormControl aria-label="Text input with dropdown button"
                                                            placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'price') }} name={item} />

                                                        <Form.Select onChange={changeLength}>
                                                            <option value='km'>km</option>
                                                            <option value='m'>m</option>
                                                            <option value='inch'>inch</option>
                                                            <option value='cm'>cm</option>
                                                            <option value='mm'>mm</option>
                                                        </Form.Select>
                                                    </InputGroup>

                                                    {pricAttr === item ?
                                                        <>
                                                            {tag && tag.map((val, key) => {
                                                                return val && val.name === item ?
                                                                    <div className="row" key={key}>
                                                                        <div className="col-lg-5 col-md-5 mt-2">
                                                                            {key === 0 ? <span style={{ opacity: .7 }}>Attribute</span> : <span></span>}
                                                                            <input type="text" className="form-control mt-2" name="attribute" value={val.value}
                                                                                disabled />
                                                                        </div>
                                                                        <div className="col-lg-5 col-md-5 mt-2">
                                                                            {key === 0 ? <span style={{ opacity: .7 }}>Unit Price</span> : <span></span>}
                                                                            <input type="text" className="form-control mt-2" name="unitPrice" value={val.price ? val.price : ''}
                                                                                onChange={(e) => { changeUnitValue(e, val) }} />
                                                                            {val.price ? '' : <p style={{ color: 'red', marginBottom: 0, marginTop: 3 }}>Please Add Unit Price</p>}
                                                                        </div>
                                                                        <div className="col-lg-1 col-xl-1">
                                                                            <i className={`fas fa-trash ${key === 0 ? 'mt-14' : 'mt-9'} cursor-pointer`} onClick={() => { dltTag(val) }}></i>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <span key={key}></span>
                                                            })}
                                                        </>
                                                        :
                                                        <>
                                                            {tag && tag.map((val, key) => {
                                                                return val && val.name === item ?
                                                                    <span className="cross_btn mt-3" key={key}>{val.value}<i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i></span>
                                                                    : <span key={key}></span>
                                                            })}
                                                        </>
                                                    }
                                                </div>
                                                : item === 'Color' ?
                                                    <>
                                                        <div className={product.productType === 'productWithVeriablePrice' ? 'col-lg-5 col-md-5' : 'col-lg-6 col-md-6'}>
                                                            <div style={{ position: 'absolute', zIndex: 100 }}>
                                                                <ColorPicker
                                                                    palette={palette}
                                                                    deferred
                                                                    onChange={addColor}
                                                                    value={colors}
                                                                    hideTextfield
                                                                />
                                                            </div>
                                                            <input type="text" className="form-control"
                                                                name={item} value={colors.css && colors.css.backgroundColor ? colors.css.backgroundColor : colors.name}
                                                                placeholder='e.g. red, blue, lightgreen' onKeyPress={(e) => { attrTag(e, 'price') }}
                                                                onChange={changeColor}
                                                                style={{ position: 'relative', paddingLeft: 35 }}
                                                            />

                                                            {pricAttr === item ?
                                                                <>
                                                                    {tag && tag.map((val, key) => {
                                                                        return val && val.name === item ?
                                                                            <div className="row" key={key}>
                                                                                <div className="col-lg-3 col-md-3 mt-2">
                                                                                    {key === 0 ? <span style={{ opacity: .7 }}>Attribute</span> : <span></span>}
                                                                                    <input type="text" className="form-control mt-2" name="attribute" value={val.value}
                                                                                        disabled />
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3 mt-2">
                                                                                    {key === 0 ? <span style={{ opacity: .7 }}>Unit Price</span> : <span></span>}
                                                                                    <input type="text" className="form-control mt-2" name="unitPrice" value={val.price ? val.price : ''}
                                                                                        onChange={(e) => { changeUnitValue(e, val) }} />
                                                                                    {val.price ? '' : <p style={{ color: 'red', marginBottom: 0, marginTop: 3 }}>Please Add Unit Price</p>}
                                                                                </div>
                                                                                <div className="col-lg-5 col-md-5">
                                                                                    <input className={key === 0 ? 'mt-12' : 'mt-6'}
                                                                                        accept=".jpeg, .jpg, .png"
                                                                                        placeholder="Choose File"
                                                                                        name="logo"
                                                                                        id="logo"
                                                                                        type="file"
                                                                                        onChange={(e) => { uplColorImg(e, val) }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-1 col-xl-1">
                                                                                    <i className={`fas fa-trash ${key === 0 ? 'mt-14' : 'mt-8'} cursor-pointer`} onClick={() => { dltTag(val) }}></i>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <span key={key}></span>
                                                                    })}
                                                                </>
                                                                :
                                                                <>
                                                                    {tag && tag.map((val, key) => {
                                                                        return val && val.name === item ?
                                                                            <div className='row-lg-6 row-md-6 mt-3' key={key}>
                                                                                <span className="cross_btn">
                                                                                    <i className="fas fa-circle mr-2" style={{ color: val.value }}></i>
                                                                                    {val.value}
                                                                                    <i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i>
                                                                                </span>
                                                                                <input className='float-right mt-2'
                                                                                    accept=".jpeg, .jpg, .png"
                                                                                    placeholder="Choose File"
                                                                                    name="logo"
                                                                                    id="logo"
                                                                                    type="file"
                                                                                    onChange={(e) => { uplColorImg(e, val) }}
                                                                                />
                                                                            </div>
                                                                            : <span key={key}></span>
                                                                    })}
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                    : item === 'Packaging' ?
                                                        <div className={product.productType === 'productWithVeriablePrice' ? 'col-lg-5 col-md-5' : 'col-lg-6 col-md-6'}>
                                                            <input type="text" className="form-control" name={item}
                                                                placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'price') }}
                                                            />

                                                            {pricAttr === item ?
                                                                <>
                                                                    {tag && tag.map((val, key) => {
                                                                        return val && val.name === item ?
                                                                            <div className="row" key={key}>
                                                                                <div className="col-lg-3 col-md-3 mt-2">
                                                                                    {key === 0 ? <span style={{ opacity: .7 }}>Attribute</span> : <span></span>}
                                                                                    <input type="text" className="form-control mt-2" name="attribute" value={val.value}
                                                                                        disabled />
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3 mt-2">
                                                                                    {key === 0 ? <span style={{ opacity: .7 }}>Unit Price</span> : <span></span>}
                                                                                    <input type="text" className="form-control mt-2" name="unitPrice" value={val.price ? val.price : ''}
                                                                                        onChange={(e) => { changeUnitValue(e, val) }} />
                                                                                    {val.price ? '' : <p style={{ color: 'red', marginBottom: 0, marginTop: 3 }}>Please Add Unit Price</p>}
                                                                                </div>
                                                                                <div className="col-lg-5 col-md-5">
                                                                                    <input className={key === 0 ? 'mt-12' : 'mt-6'}
                                                                                        accept=".jpeg, .jpg, .png"
                                                                                        placeholder="Choose File"
                                                                                        name="logo"
                                                                                        id="logo"
                                                                                        type="file"
                                                                                        onChange={(e) => { uplPackagingImg(e, val) }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-1 col-xl-1">
                                                                                    <i className={`fas fa-trash ${key === 0 ? 'mt-14' : 'mt-8'} cursor-pointer`} onClick={() => { dltTag(val) }}></i>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <span key={key}></span>
                                                                    })}
                                                                </>
                                                                :
                                                                <>
                                                                    {tag && tag.map((val, key) => {
                                                                        return val && val.name === item ?
                                                                            <div className='row-lg-6 row-md-6 mt-3' key={key}>
                                                                                <span className="cross_btn">
                                                                                    {val.value}
                                                                                    <i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i>
                                                                                </span>
                                                                                <input className='float-right mt-2'
                                                                                    accept=".jpeg, .jpg, .png"
                                                                                    placeholder="Choose File"
                                                                                    name="logo"
                                                                                    id="logo"
                                                                                    type="file"
                                                                                    onChange={(e) => { uplPackagingImg(e, val) }}
                                                                                />
                                                                            </div>
                                                                            : <span key={key}></span>
                                                                    })}
                                                                </>
                                                            }
                                                        </div>
                                                        :
                                                        <div className={product.productType === 'productWithVeriablePrice' ? 'col-lg-5 col-md-5' : 'col-lg-6 col-md-6'}>
                                                            <input type="text" className="form-control" name={item}
                                                                placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'price') }}
                                                            />

                                                            {pricAttr === item ?
                                                                <>
                                                                    {tag && tag.map((val, key) => {
                                                                        return val && val.name === item ?
                                                                            <div className="row" key={key}>
                                                                                <div className="col-lg-5 col-md-5 mt-2">
                                                                                    {key === 0 ? <span style={{ opacity: .7 }}>Attribute</span> : <span></span>}
                                                                                    <input type="text" className="form-control mt-2" name="attribute" value={val.value}
                                                                                        disabled />
                                                                                </div>
                                                                                <div className="col-lg-5 col-md-5 mt-2">
                                                                                    {key === 0 ? <span style={{ opacity: .7 }}>Unit Price</span> : <span></span>}
                                                                                    <input type="text" className="form-control mt-2" name="unitPrice" value={val.price ? val.price : ''}
                                                                                        onChange={(e) => { changeUnitValue(e, val) }} />
                                                                                    {val.price ? '' : <p style={{ color: 'red', marginBottom: 0, marginTop: 3 }}>Please Add Unit Price</p>}
                                                                                </div>
                                                                                <div className="col-lg-1 col-xl-1">
                                                                                    <i className={`fas fa-trash ${key === 0 ? 'mt-14' : 'mt-9'} cursor-pointer`} onClick={() => { dltTag(val) }}></i>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <span key={key}></span>
                                                                    })}
                                                                </>
                                                                :
                                                                <>
                                                                    {tag && tag.map((val, key) => {
                                                                        return val && val.name === item ?
                                                                            <span className="cross_btn mt-3" key={key}>{val.value}<i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i></span>
                                                                            : <span key={key}></span>
                                                                    })}
                                                                </>
                                                            }
                                                        </div>
                                        }
                                        {item === 'Color' ?
                                            <div className="col-lg-1 col-md-1">
                                                <i className="fas fa-trash mt-3 cursor-pointer" onClick={() => { dltAttrInput(item) }}></i>
                                                {product.Color.map((items, key) => {
                                                    return <div className='col mb-3' key={key}>
                                                        {items.img && items.img.original ?
                                                            <>
                                                                <img src={BucketUrl + '/' + items.img.original} key={key} alt='img'
                                                                    style={{ width: '50px', height: '50px', marginLeft: 15, marginBottom: 2, border: '1px solid black', position: 'relative' }} />
                                                                <i className="fas fa-times-circle" key={items.Color} onClick={() => { dltColorImg(items) }}
                                                                    style={{ top: '-15px', right: '-5px', fontSize: '14px', position: 'absolute', cursor: 'pointer' }} ></i>
                                                            </>
                                                            : ''
                                                        }
                                                    </div>
                                                })}
                                                <div className='row' id='colorImg'></div>
                                            </div>
                                            : item === 'Packaging' ?
                                                <div className="col-lg-1 col-md-1">
                                                    <i className="fas fa-trash mt-3 cursor-pointer" onClick={() => { dltAttrInput(item) }}></i>
                                                    {product.Packaging.map((items, key) => {
                                                        return <div className='col mb-3' key={key}>
                                                            {items.img && items.img.original ?
                                                                <>
                                                                    <img src={BucketUrl + '/' + items.img.original} key={key} alt='img'
                                                                        style={{ width: '50px', height: '50px', marginLeft: 15, marginBottom: 2, border: '1px solid black', position: 'relative' }} />
                                                                    <i className="fas fa-times-circle" key={items.Packaging} onClick={() => { dltPackagingImg(items) }}
                                                                        style={{ top: '-15px', right: '-5px', fontSize: '14px', position: 'absolute', cursor: 'pointer' }} ></i>
                                                                </>
                                                                : ''
                                                            }
                                                        </div>
                                                    })}
                                                    <div className='row' id='packagingImg'></div>
                                                </div>
                                                :
                                                <div className="col-lg-1 col-xl-1">
                                                    <i className="fas fa-trash mt-3 cursor-pointer" onClick={() => { dltAttrInput(item) }}></i>
                                            </div>
                                        }

                                        {product.productType === 'productWithVeriablePrice' ?
                                            <div className="col-lg-2 col-md-2">
                                                <div className="radio-inline">
                                                    <label className="radio">
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value={item}
                                                            checked={pricAttr === item ? true : false}
                                                            onChange={() => { ChangePricingAttr(item) }}
                                                        />
                                                        <span></span>Pricing Attribute
                                                    </label>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                )}

                                {error.attrErr ?
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <p style={{ color: 'red' }}>{error.attrErr}</p>
                                        </div>
                                    </div> : ''
                                }

                                <div className="form-group row mt-10">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <span style={{ fontWeight: 'bold' }}>Additional Attribute</span>
                                        <div className="separator separator-solid mt-4"></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Add Attribute
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <h5 style={{ textAlign: 'center' }}>Click Button to Add Attribute</h5>
                                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                            {attributeList.map((btnName, key) =>
                                                <Button variant="outline-primary btn-sm" style={{ width: '23%', margin: 6 }} key={key} onClick={() => { changeAdditionalAttr(btnName) }}>
                                                    {btnName}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {addAttributes && addAttributes.map((item, key) =>
                                    <div className="form-group row" key={key}>
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            {item}
                                        </label>
                                        {item === 'Volume' ?
                                            <div className='col-lg-6 col-md-6'>
                                                <InputGroup>
                                                    <FormControl aria-label="Text input with dropdown button"
                                                        placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'additional') }} name={item} />

                                                    <Form.Select onChange={changeValume}>
                                                        <option value='m'>m</option>
                                                        <option value='dm'>dm</option>
                                                        <option value='cm'>cm</option>
                                                        <option value='mm'>mm</option>
                                                    </Form.Select>
                                                </InputGroup>
                                                {tag && tag.map((val, key) => {
                                                    return val && val.name === item ?
                                                        <span className="cross_btn mt-3" key={key}>{val.value}<i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i></span>
                                                        : <span key={key}></span>
                                                })}
                                            </div>
                                            : item === 'Length' ?
                                                <div className='col-lg-6 col-md-6'>
                                                    <InputGroup>
                                                        <FormControl aria-label="Text input with dropdown button"
                                                            placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'additional') }} name={item} />

                                                        <Form.Select onChange={changeLength}>
                                                            <option value='km'>km</option>
                                                            <option value='m'>m</option>
                                                            <option value='inch'>inch</option>
                                                            <option value='cm'>cm</option>
                                                            <option value='mm'>mm</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                    {tag && tag.map((val, key) => {
                                                        return val && val.name === item ?
                                                            <span className="cross_btn mt-3" key={key}>{val.value}<i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i></span>
                                                            : <span key={key}></span>
                                                    })}
                                                </div>
                                                :
                                                item === 'Color' ?
                                                    <>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div style={{ position: 'absolute', zIndex: 100 }}>
                                                                <ColorPicker
                                                                    palette={palette}
                                                                    deferred
                                                                    onChange={addColor}
                                                                    value={colors}
                                                                    hideTextfield
                                                                />
                                                            </div>
                                                            <input type="text" className="form-control"
                                                                name={item} value={colors.css && colors.css.backgroundColor ? colors.css.backgroundColor : colors.name}
                                                                placeholder='e.g. red, blue, lightgreen' onKeyPress={(e) => { attrTag(e, 'additional') }}
                                                                onChange={changeColor}
                                                                style={{ position: 'relative', paddingLeft: 35 }}
                                                            />
                                                            {tag && tag.map((val, key) => {
                                                                return val && val.name === item ?
                                                                    <div className='row-lg-6 row-md-6 mt-3' key={key}>
                                                                        <span className="cross_btn" key={key}>
                                                                            <i className="fas fa-circle mr-2" style={{ color: val.value }}></i>
                                                                            {val.value}
                                                                            <i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i>
                                                                        </span>
                                                                        <input className='float-right mt-2'
                                                                            accept=".jpeg, .jpg, .png"
                                                                            placeholder="Choose File"
                                                                            name="logo"
                                                                            id="logo"
                                                                            type="file"
                                                                            onChange={(e) => { uplColorImg(e, val) }}
                                                                        />
                                                                    </div>
                                                                    : <span key={key}></span>
                                                            })}
                                                        </div>
                                                    </>
                                                    : item === 'Packaging' ?
                                                        <div className='col-lg-6 col-md-6'>
                                                            <input type="text" className="form-control" name={item}
                                                                placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'additional') }}
                                                            />
                                                            {tag && tag.map((val, key) => {
                                                                return val && val.name === item ?
                                                                    <div className='row-lg-6 row-md-6 mt-3' key={key}>
                                                                        <span className="cross_btn" key={key}>
                                                                            {val.value}
                                                                            <i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i>
                                                                        </span>
                                                                        <input className='float-right mt-2'
                                                                            accept=".jpeg, .jpg, .png"
                                                                            placeholder="Choose File"
                                                                            name="logo"
                                                                            id="logo"
                                                                            type="file"
                                                                            onChange={(e) => { dltPackagingImg(e, val) }}
                                                                        />
                                                                    </div>
                                                                    : <span key={key}></span>
                                                            })}
                                                        </div>
                                                        :
                                                        <div className="col-lg-6 col-md-6">
                                                            <input type="text" className="form-control" name={item}
                                                                placeholder={`please enter value for the ${item}`} onKeyPress={(e) => { attrTag(e, 'additional') }}
                                                            />
                                                            {tag && tag.map((val, key) => {
                                                                return val && val.name === item ?
                                                                    <span className="cross_btn mt-3" key={key}>{val.value}<i className="fas fa-times-circle" onClick={() => { dltTag(val) }}></i></span>
                                                                    : <span key={key}></span>
                                                            })}
                                                        </div>
                                        }
                                        {item === 'Color' ?
                                            <div className="col-lg-2 col-md-2">
                                                <i className="fas fa-trash mt-3 cursor-pointer" onClick={() => { dltAddAttrInput(item) }}></i>
                                                {product.Color.map((items, key) => {
                                                    return <div className='col mb-3' key={key}>
                                                        {items.img && items.img.original ?
                                                            <>
                                                                <img src={BucketUrl + '/' + items.img.original} key={key} alt='img'
                                                                    style={{ width: '50px', height: '50px', marginLeft: 15, border: '1px solid black', position: 'relative' }} />
                                                                <i className="fas fa-times-circle" key={items.Color} onClick={() => { dltColorImg(items) }}
                                                                    style={{ top: '-15px', right: '94px', fontSize: '14px', position: 'absolute', cursor: 'pointer' }} ></i>
                                                            </>
                                                            : ''
                                                        }
                                                    </div>
                                                })}
                                                <div className='row' id='colorImg'></div>
                                            </div>
                                            : item === 'Packaging' ?
                                                <div className="col-lg-1 col-md-1">
                                                    <i className="fas fa-trash mt-3 cursor-pointer" onClick={() => { dltAddAttrInput(item) }}></i>
                                                    {product.Packaging.map((items, key) => {
                                                        return <div className='col mb-3' key={key}>
                                                            {items.img && items.img.original ?
                                                                <>
                                                                    <img src={BucketUrl + '/' + items.img.original} key={key} alt='img'
                                                                        style={{ width: '50px', height: '50px', marginLeft: 15, marginBottom: 2, border: '1px solid black', position: 'relative' }} />
                                                                    <i className="fas fa-times-circle" key={items.Packaging} onClick={() => { dltPackagingImg(items) }}
                                                                        style={{ top: '-15px', right: '-5px', fontSize: '14px', position: 'absolute', cursor: 'pointer' }} ></i>
                                                                </>
                                                                : ''
                                                            }
                                                        </div>
                                                    })}
                                                    <div className='row' id='packagingImg'></div>
                                                </div>
                                                :
                                                <div className="col-lg-1 col-xl-1">
                                                    <i className="fas fa-trash mt-3 cursor-pointer" onClick={() => { dltAddAttrInput(item) }}></i>
                                                </div>
                                        }
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    : <div></div>
                }

                {/* Lot Size and Set Prices*/}
                {product.productType === 'productWithVeriablePrice' ?
                    < div className="card card-custom mt-5">
                        <div className="card-header py-5">
                            <div className="card-title align-items-start flex-column">
                                <h3 className="text-primary">
                                    Lot Size
                                </h3>
                            </div>
                        </div>

                        <div className="form">
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Define Unit of measure
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <select className="form-control" name="measureUnit" 
                                            value={JSON.stringify({
                                                measuringUnit: product.measureUnit.measuringUnit,
                                                unitDescription: product.measureUnit.unitDescription,
                                                unitDisplay: product.measureUnit.unitDisplay,
                                                unit: product.measureUnit.unit
                                            })
                                            }
                                            onChange={changeUnit}>
                                            <option value={JSON.stringify({
                                                measuringUnit: '',
                                                unitDescription: '',
                                                unitDisplay: '',
                                                unit: ''
                                            })} label="Select Unit" />
                                            {
                                                unitVal && unitVal.map((items, key) =>
                                                    // <option value={JSON.stringify(items)}
                                                    <option value={
                                                        JSON.stringify({
                                                            measuringUnit: items._id,
                                                            unitDescription: items.unitDescription,
                                                            unitDisplay: items.unitDisplay,
                                                            unit: items.unit
                                                        })
                                                    }
                                                        key={key}>{items.unitDisplay}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                {error.unitErr ?
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <p style={{ color: 'red' }}>{error.unitErr}</p>
                                        </div>
                                    </div> : ''
                                }

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Lot Size
                                    </label>
                                    <div className="col-lg-3 col-md-3">
                                        <input type="text" className="form-control" name="lotsize" value={product.lotsize}
                                            placeholder="e.g. 20" onChange={changeLotSize}
                                        />
                                    </div>

                                    <div className="col-lg-3 col-md-3">
                                        <select className="form-control" name="packgingUnit" value={product.packgingUnit}
                                            onChange={changePackageUnit}>
                                            <option value="" label="Select Packaging Unit" />
                                            {
                                                packgingUnitValues.length && packgingUnitValues.map((item, i) => <option value={item} key={i}>{item}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                {error.lotsizeErr ?
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <p style={{ color: 'red' }}>{error.lotsizeErr}</p>
                                        </div>
                                    </div> : ''
                                }

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Min.order Quantity(for Tier Pricing)
                                    </label>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="input-group">
                                            <span className="input-group-text" style={{ backgroundColor: '#D3D3D3', cursor: 'pointer' }}
                                                onClick={() => { changeMinimumOrderValue("minus", "minOrder") }}>
                                                <i className="fa fa-minus" style={{ color: 'black' }}></i>
                                            </span>
                                            <input type="text" className="form-control" name="minOrderQuantity" style={{ textAlign: 'center' }}
                                                value={product.minOrderQuantity.value ? product.minOrderQuantity.value : ''} disabled
                                                onChange={e => setProduct({ ...product, minOrderQuantity: { value: Number(e.target.value) } })}
                                            />
                                            <span className="input-group-text" style={{ backgroundColor: '#D3D3D3', cursor: 'pointer' }}
                                                onClick={() => { changeMinimumOrderValue("plus", "minOrder") }}>
                                                <i className="fa fa-plus" style={{ color: 'black', cursor: 'pointer' }} ></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <input type="text" className="form-control" name="minOrderQuantity"
                                            value={product.minOrderQuantity.unit ? product.minOrderQuantity.unit : ''} disabled
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    :

                    <div className="card card-custom mt-5">
                        <div className="card-header py-5">
                            <div className="card-title align-items-start flex-column">
                                <h3 className="text-primary">
                                    Set Prices
                                </h3>
                            </div>
                        </div>

                        <div className="form">
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Define Unit of measure
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <select className="form-control" name="measureUnit"
                                            value={JSON.stringify({
                                                measuringUnit: product.measureUnit.measuringUnit,
                                                unitDescription: product.measureUnit.unitDescription,
                                                unitDisplay: product.measureUnit.unitDisplay,
                                                unit: product.measureUnit.unit
                                            })
                                            }
                                            onChange={changeUnit}>
                                            <option value={JSON.stringify({
                                                measuringUnit: '',
                                                unitDescription: '',
                                                unitDisplay: '',
                                                unit: ''
                                            })} label="Select Unit" />
                                            {
                                                unitVal && unitVal.map((items, key) =>
                                                    // <option value={JSON.stringify(items)}
                                                    <option value={
                                                        JSON.stringify({
                                                            measuringUnit: items._id,
                                                            unitDescription: items.unitDescription,
                                                            unitDisplay: items.unitDisplay,
                                                            unit: items.unit
                                                        })
                                                    }
                                                        key={key}>{items.unitDisplay}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                {error.unitErr ?
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <p style={{ color: 'red' }}>{error.unitErr}</p>
                                        </div>
                                    </div> : ''
                                }

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Lot Size
                                    </label>
                                    <div className="col-lg-3 col-md-3">
                                        <input type="text" className="form-control" name="lotsize" value={product.lotsize}
                                            placeholder="e.g. 20" onChange={changeLotSize}
                                        />
                                    </div>

                                    <div className="col-lg-3 col-md-3">
                                        <select className="form-control" name="packgingUnit" value={product.packgingUnit}
                                            onChange={changePackageUnit}>
                                            <option value="" label="Select Packaging Unit" />
                                            {
                                                packgingUnitValues.length && packgingUnitValues.map((item, i) => <option value={item} key={i}>{item}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                {error.lotsizeErr ?
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <p style={{ color: 'red' }}>{error.lotsizeErr}</p>
                                        </div>
                                    </div> : ''
                                }

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <span style={{ fontWeight: 'bold' }}>Tier Pricing</span>
                                        <div className="separator separator-solid mt-4"></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Min.order Quantity(for Tier Pricing)
                                    </label>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="input-group">
                                            <span className="input-group-text" style={{ backgroundColor: '#D3D3D3', cursor: 'pointer' }}
                                                onClick={() => { changeMinimumOrderValue("minus", "minOrder") }}>
                                                <i className="fa fa-minus" style={{ color: 'black' }}></i>
                                            </span>
                                            <input type="text" className="form-control" name="minOrderQuantity" style={{ textAlign: 'center' }}
                                                value={product.minOrderQuantity.value ? product.minOrderQuantity.value : ''} disabled
                                                onChange={e => setProduct({ ...product, minOrderQuantity: { value: Number(e.target.value) } })}
                                            />
                                            <span className="input-group-text" style={{ backgroundColor: '#D3D3D3', cursor: 'pointer' }}
                                                onClick={() => { changeMinimumOrderValue("plus", "minOrder") }}>
                                                <i className="fa fa-plus" style={{ color: 'black', cursor: 'pointer' }} ></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <input type="text" className="form-control" name="minOrderQuantity"
                                            value={product.minOrderQuantity.unit ? product.minOrderQuantity.unit : ''} disabled
                                        />
                                    </div>
                                </div>

                                {product.readytoship === true ?
                                    <>
                                        {product.tierPrice[0] && product.tierPrice.map((item, key, arr) =>
                                            <div className="form-group row" key={key}>
                                                <div className="col-lg-1"></div>
                                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                    {key === 0 ? 'Tier' : ''}
                                                </label>
                                                <div className="col-lg-3 col-md-3">
                                                    {key === 0 ? <span style={{ opacity: .5 }}>Add Tier</span> : <span></span>}
                                                    <input type="text" className="form-control mt-1" name="quantity" value={item.quantity ? item.quantity : ''} style={{ position: 'relative' }}
                                                        onChange={updateTier(key, item, arr)}
                                                    />

                                                    {/* {arr[key - 1] !== undefined && arr[key - 1].quantity > item.quantity ?
                                                    <p style={{ color: 'red' }}>Quantity should be greater then {arr[key - 1].quantity}.</p>
                                                    : ''} */}

                                                    {key === 0 ? <span style={{ position: 'absolute', right: 20, top: 35, opacity: .5 }}>1-{item.quantity}</span>
                                                        : <span style={{ position: 'absolute', right: 20, top: 15, opacity: .5 }}>{arr[key - 1] !== undefined ? arr[key - 1].quantity + 1 : 1}-{item.quantity}</span>}
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    {key === 0 ? <span style={{ opacity: .5 }}>Price/Unit</span> : <span></span>}
                                                    <input type="text" className="form-control mt-1" name="price" value={item.price ? item.price : ''} style={{ position: 'relative' }}
                                                        onChange={updateTier(key, item, arr)}
                                                    />
                                                </div>
                                                {key >= 1 ? <span style={{ marginTop: 15, cursor: 'pointer' }} onClick={() => { dltTier(key) }}><i className="fa fa-trash"></i></span> : ''}
                                            </div>
                                        )}

                                        {error.tierErr ?
                                            <div className="form-group row">
                                                <div className="col-lg-1"></div>
                                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                </label>
                                                <div className="col-lg-6 col-md-6">
                                                    <p style={{ color: 'red' }}>{error.tierErr}</p>
                                                </div>
                                            </div> : ''
                                        }

                                        {product.tierPrice.length <= 2 ?
                                            <div className="form-group row">
                                                <div className="col-lg-1"></div>
                                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                </label>
                                                <div className="col-lg-6 col-md-6">
                                                    <Button onClick={addTier} variant="link">Add Tier</Button>
                                                </div>
                                            </div> : ''
                                        }
                                    </>
                                    :
                                    <>
                                        {product.tierPrice[0] && product.tierPrice.map((item, key, arr) =>
                                            <div className="form-group row" key={key}>
                                                <div className="col-lg-1"></div>
                                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                    {key === 0 ? 'Tier' : ''}
                                                </label>
                                                <div className="col-lg-3 col-md-3">
                                                    {key === 0 ? <span style={{ opacity: .5 }}>Add Tier</span> : <span></span>}
                                                    <input type="text" className="form-control mt-1" name="quantity" value={item.quantity ? item.quantity : ''} style={{ position: 'relative' }}
                                                        onChange={updateTier(key, item)} disabled
                                                    />
                                                    {key === 0 ? <span style={{ position: 'absolute', right: 20, top: 35, opacity: .5 }}>1-{item.quantity}</span>
                                                        : <span style={{ position: 'absolute', right: 20, top: 15, opacity: .5 }}>{arr[key - 1] !== undefined ? arr[key - 1].quantity + 1 : 1}-{item.quantity}</span>}
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    {key === 0 ? <span style={{ opacity: .5 }}>Price/Unit</span> : <span></span>}
                                                    <input type="text" className="form-control mt-1" name="price" value={item.price ? item.price : ''} style={{ position: 'relative' }}
                                                        onChange={updateTier(key, item)}
                                                    />
                                                </div>
                                                {key >= 1 ? <span style={{ marginTop: 15, cursor: 'pointer' }} onClick={() => { dltTier(key) }}><i className="fa fa-trash"></i></span> : ''}
                                            </div>
                                        )}

                                        {error.tierErr ?
                                            <div className="form-group row">
                                                <div className="col-lg-1"></div>
                                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                </label>
                                                <div className="col-lg-6 col-md-6">
                                                    <p style={{ color: 'red' }}>{error.tierErr}</p>
                                                </div>
                                            </div> : ''
                                        }

                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            </label>
                                            <div className="col-lg-6 col-md-6">
                                                <Button onClick={() => { scrollRef.current.scrollIntoView() }} variant="link">Edit Tier</Button>
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    </label>
                                    <div className="col-lg-6 col-md-6">
                                        <span style={{ fontWeight: 'bold' }}>Group Pricing</span>
                                        <div className="separator separator-solid mt-4"></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-lg-1"></div>
                                    <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        Min.order Quantity(for Group Pricing)
                                    </label>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="input-group">
                                            <span className="input-group-text" style={{ backgroundColor: '#D3D3D3', cursor: 'pointer' }}
                                                onClick={() => { changeMinimumOrderValue("minus", "minGroup") }}>
                                                <i className="fa fa-minus" style={{ color: 'black' }}></i>
                                            </span>
                                            <input type="text" className="form-control" name="minOrderQuantity" style={{ textAlign: 'center' }}
                                                value={product.minGroupOrderQuantity.value ? product.minGroupOrderQuantity.value : ''} disabled
                                                onChange={e => setProduct({ ...product, minGroupOrderQuantity: { value: Number(e.target.value) } })}
                                            />
                                            <span className="input-group-text" style={{ backgroundColor: '#D3D3D3', cursor: 'pointer' }}
                                                onClick={() => { changeMinimumOrderValue("plus", "minGroup") }}>
                                                <i className="fa fa-plus" style={{ color: 'black' }}></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <input type="text" className="form-control" name="minOrderQuantity"
                                            value={product.minGroupOrderQuantity.unit ? product.minGroupOrderQuantity.unit : ''} disabled
                                        />
                                    </div>
                                </div>

                                {product.groupPrice[0] && product.groupPrice.map((item, key, arr) =>
                                    <div className="form-group row" key={key}>
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            {key === 0 ? 'Group Price' : ''}
                                        </label>
                                        <div className="col-lg-3 col-md-3">
                                            {key === 0 ? <span style={{ opacity: .5 }}>Assigned Group</span> : <span></span>}

                                            <select className="form-control" name="groupId" value={item.groupId._id}
                                                onChange={updateGroup(key, item)}>
                                                <option value="" label="Select Group" />
                                                {
                                                    grouplist && grouplist.map((items, key) =>
                                                        <option value={items._id} key={key}>{items.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            {key === 0 ? <span style={{ opacity: .5 }}>Price/Unit</span> : <span></span>}
                                            <input type="text" className="form-control mt-1" name="price" value={item.price ? item.price : ''} style={{ position: 'relative' }}
                                                onChange={updateGroup(key, item)}
                                            />
                                        </div>
                                        {key >= 1 ? <span style={{ marginTop: 15, cursor: 'pointer' }} onClick={() => { dltGroup(key) }}><i className="fa fa-trash"></i></span> : ''}
                                    </div>
                                )}

                                {error.groupErr ?
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <p style={{ color: 'red' }}>{error.groupErr}</p>
                                        </div>
                                    </div> : ''
                                }

                                {grouplist.length > 0 && product.groupPrice.length <= 2 ?
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="col-lg-6 col-md-6">
                                                {grouplist.length < 2 && product.groupPrice.length > 0 ?
                                                    ''
                                                    : <Button onClick={addGroup} variant="link">Add Group</Button>
                                                }
                                            </div>
                                        </div>
                                    </div> : ''
                                }

                            </div>
                        </div>
                    </div>
                }

                {/* Additional Information */}
                <div className="card card-custom mt-5">
                    <div className="card-header py-5">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="text-primary">
                                Additional Information
                            </h3>
                        </div>
                    </div>

                    <div className="form">
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-lg-1"></div>
                                <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                    Product description (long)
                                </label>
                                <div className="col-lg-6 col-xl-6">
                                    <Editor
                                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={product.description}
                                        init={{
                                            height: 400,
                                            min_height: 200,
                                            object_resizing: false,
                                            branding: false,
                                            menubar: false,
                                            contextmenu: false,
                                            force_br_newlines: true,
                                            plugins: [
                                                "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                                                "searchreplace wordcount visualblocks visualchars code fullscreen",
                                                "insertdatetime media nonbreaking save table directionality",
                                                "emoticons template paste textpattern"
                                            ],
                                            toolbar: ' bold italic strikethrough | numlist bullist | image | table | preview ',
                                            formats: { strikethrough: { inline: 'del' }, },
                                            table_row_limit: 2,
                                            table_appearance_options: false,
                                            table_use_colgroups: false,
                                            table_advtab: false,
                                            table_row_advtab: false,
                                            table_cell_advtab: false,
                                            table_tab_navigation: false,
                                            // table_responsive_width: false,
                                            image_advtab: false,
                                            image_description: false,
                                            image_dimensions: false,
                                            image_title: false,
                                            images_file_types: 'jpeg,jpg,png,gif',
                                            file_picker_callback: function (blobInfo) {
                                                // console.log("blobInfo ", blobInfo)
                                                var input = document.createElement('input');
                                                input.setAttribute('type', 'file');
                                                input.setAttribute('accept', 'image/*');
                                                input.click();
                                                var file;
                                                input.onchange = async function () {
                                                    file = input.files[0];
                                                    // as any
                                                    var node = document.querySelector('[title="Save"]');
                                                    node.innerHTML = '<div class="spinner-border text-muted" style="width:1.8em !important; height:1.8em!important;border: 0.25em solid white !important;border-right-color: transparent !important;"></div>'
                                                    node.disabled = true;
                                                    var formData;
                                                    // var xhr;
                                                    // xhr = new XMLHttpRequest();
                                                    // xhr.withCredentials = false;
                                                    // let url = serverUrl;
                                                    // let cName = cookieDomain + 'access_token';
                                                    // const name = cName + "=";
                                                    // const cDecoded = decodeURIComponent(document.cookie);
                                                    // const cArr = cDecoded.split('; ');
                                                    // let res;
                                                    // cArr.forEach(val => {
                                                    //     if (val.indexOf(name) === 0) res = val.substring(name.length);
                                                    // })
                                                    // let token = window.localStorage.getItem('token')
                                                    // console.log('token ', token);
                                                    // xhr.open('POST', `/api/product/uploadDiscImage/${userId}`);
                                                    // xhr.setRequestHeader('x-auth-token', token);
                                                    // xhr.onload = function () {
                                                    //     var json;
                                                    //     if (xhr.status !== 200) {
                                                    //         return;
                                                    //     }
                                                    //     json = JSON.parse(xhr.responseText);
                                                    //     node.innerHTML = 'Save';
                                                    //     node.disabled = false;
                                                    //     // console.log("DiscImg ", json.product_images)
                                                    //     product.product_description_images.push({ 'original': json.product_images.original })
                                                    //     blobInfo(BucketUrl + '/' + json.product_images.original);
                                                    // };
                                                    formData = new FormData();
                                                    formData.append('file', file);
                                                    // xhr.send(formData);
                                                    let res = await axios.post(`/api/product/uploadDiscImage/${userId}`, formData);
                                                    if (res.status === 200) {
                                                        let imgRes = res.data

                                                        node.innerHTML = 'Save';
                                                        node.disabled = false;
                                                        product.product_description_images.push({ 'original': imgRes.product_images.original })
                                                        blobInfo(BucketUrl + '/' + imgRes.product_images.original);
                                                        // console.log("imgRes ", imgRes)
                                                    }
                                                }
                                            }
                                        }}
                                        onChange={ChangDisc}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Customization */}
                {
                    product.isCustomization === true ?
                        <div className="card card-custom mt-5">
                            <div className="card-header py-5">
                                <div className="card-title align-items-start flex-column">
                                    <h3 className="text-primary">
                                        Customization
                                    </h3>
                                </div>
                            </div>

                            <div className="form">
                                <div className="card-body">
                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            Add Customization
                                        </label>
                                        <div className="col-lg-2 col-md-2 mt-5">
                                            <label className="checkbox-inline d-flex align-items-center">
                                                <input className="mr-2" type="checkbox" name="logoCustomization" checked={product.logoCustomization.customization}
                                                    onChange={e => setProduct({ ...product, logoCustomization: { ...product.logoCustomization, customization: e.target.checked } })} /> Customized Logo
                                            </label>
                                        </div>
                                        {product.logoCustomization.customization === true ?
                                            <div className="col-lg-3 col-md-3">
                                                <span style={{ opacity: .5 }}>Min. Quantity for customization</span>
                                                <input type="text" className="form-control mt-1" name="logoCustomization" placeholder='e.g.40'
                                                    value={product.logoCustomization.minimumQuantity ? product.logoCustomization.minimumQuantity : ''}
                                                    onChange={e => setProduct({ ...product, logoCustomization: { ...product.logoCustomization, minimumQuantity: Number(e.target.value) } })}
                                                />
                                            </div>
                                            : ''
                                        }
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-2 col-md-2 mt-5">
                                            <label className="checkbox-inline d-flex align-items-center">
                                                <input className="mr-2" type="checkbox" name="graphicsCutomization" checked={product.graphicsCutomization.customization}
                                                    onChange={e => setProduct({ ...product, graphicsCutomization: { ...product.graphicsCutomization, customization: e.target.checked } })} /> Customized Graphics
                                            </label>
                                        </div>
                                        {product.graphicsCutomization.customization === true ?
                                            <div className="col-lg-3 col-md-3">
                                                <span style={{ opacity: .5 }}>Min. Quantity for customization</span>
                                                <input type="text" className="form-control mt-1" name="graphicsCutomization" placeholder='e.g.40'
                                                    value={product.graphicsCutomization.minimumQuantity ? product.graphicsCutomization.minimumQuantity : ''}
                                                    onChange={e => setProduct({ ...product, graphicsCutomization: { ...product.graphicsCutomization, minimumQuantity: Number(e.target.value) } })}
                                                />
                                            </div>
                                            : ''
                                        }
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-lg-1"></div>
                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                        </label>
                                        <div className="col-lg-2 col-md-2 mt-5">
                                            <label className="checkbox-inline d-flex align-items-center">
                                                <input className="mr-2" type="checkbox" name="packagingCustomization" checked={product.packagingCustomization.customization}
                                                    onChange={e => setProduct({ ...product, packagingCustomization: { ...product.packagingCustomization, customization: e.target.checked } })} /> Customized Packaging
                                            </label>
                                        </div>
                                        {product.packagingCustomization.customization === true ?
                                            <div className="col-lg-3 col-md-3">
                                                <span style={{ opacity: .5 }}>Min. Quantity for customization</span>
                                                <input type="text" className="form-control mt-1" name="packagingCustomization" placeholder='e.g.40'
                                                    value={product.packagingCustomization.minimumQuantity ? product.packagingCustomization.minimumQuantity : ''}
                                                    onChange={e => setProduct({ ...product, packagingCustomization: { ...product.packagingCustomization, minimumQuantity: Number(e.target.value) } })}
                                                />
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div></div>
                }


                {/* Buy Samples */}
                {
                    product.BuySamples === true ?
                        <div className="card card-custom mt-5">
                            <div className="card-header py-5">
                                <div className="card-title align-items-start flex-column">
                                    <h3 className="text-primary">
                                        Buy Samples
                                    </h3>
                                </div>
                            </div>
                            <div className="form">
                                <div className="card-body">
                                    {product.productType === 'simpleProduct' ?
                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                Define Your Sample
                                            </label>
                                            <div className="col-lg-3 col-md-3">
                                                <span style={{ opacity: .7 }}>No. of units willing to sell</span>
                                                <input type="text" className="form-control" name="noOfUnits" value={product.samples[0] ? product.samples[0].noOfUnits : ''}
                                                    onChange={updateSample} />
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <span style={{ opacity: .7 }}>Unit Price</span>
                                                <input type="text" className="form-control" name="samplePrice" value={product.samples[0] ? product.samples[0].samplePrice : ''}
                                                    onChange={updateSample} />
                                            </div>
                                        </div>
                                        : product.productType === 'variableProduct' || product.productType === 'productWithVeriablePrice' ?
                                            <>
                                                {product.samples[0] && product.samples.map((item, key) =>
                                                    <div className="form-group row" key={key}>
                                                        <div className="col-lg-1"></div>
                                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                            {key === 0 ? 'Define Your Sample' : ''}
                                                        </label>
                                                        <div className="col-lg-2 col-md-2">
                                                            {key === 0 ? <span style={{ opacity: .7 }}>Select Variation</span> : <span></span>}
                                                            <select className="form-control" name="variation"
                                                                onChange={updateVPSample(item, key)}
                                                                value={`${item.variationFirstAttribute ? item.variationFirstAttribute.value : ''}${item.variationSecoundAttribute && item.variationSecoundAttribute.value ? `,${item.variationSecoundAttribute.value}` : ''}`}
                                                            >
                                                                <option value="" label="Select Variation" />
                                                                {
                                                                    combinations && combinations.map((comb, key) =>
                                                                        <option value={`${comb.variationFirstAttribute.value}${comb.variationSecoundAttribute ? `,${comb.variationSecoundAttribute.value}` : ''}`} key={key}>
                                                                            {`${comb.variationFirstAttribute.value} ${comb.variationSecoundAttribute ? `,${comb.variationSecoundAttribute.value}` : ''}`}
                                                                        </option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            {key === 0 ? <span style={{ opacity: .7 }}>No. of units willing to sell</span> : <span></span>}
                                                            <input type="text" className="form-control" name="noOfUnits" value={item.noOfUnits}
                                                                onChange={updateVPSample(item, key)}
                                                            />
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            {key === 0 ? <span style={{ opacity: .7 }}>Unit Price</span> : <span></span>}
                                                            <input type="text" className="form-control" name="samplePrice" value={item.samplePrice}
                                                                onChange={updateVPSample(item, key)}
                                                            />
                                                        </div>
                                                        {key >= 1 ? <span style={{ marginTop: 15, cursor: 'pointer' }} onClick={() => { dltSample(key) }}><i className="fa fa-trash"></i></span> : ''}
                                                    </div>
                                                )}
                                                {product.samples.length <= 2 ?
                                                    <div className="form-group row">
                                                        <div className="col-lg-1"></div>
                                                        <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                                        </label>
                                                        <div className="col-lg-6 col-md-6">
                                                            {combinations.length < 2 && product.samples.length > 0 ?
                                                                ''
                                                                : <Button onClick={addSample} variant="link">Add Variation</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                            </>
                                            : ''}

                                    {error.sampleErr ?
                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                            </label>
                                            <div className="col-lg-6 col-md-6">
                                                <p style={{ color: 'red' }}>{error.sampleErr}</p>
                                            </div>
                                        </div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        : <div></div>
                }


                {/* Submission */}
                <div className="card card-custom mt-5" style={{ alignItem: 'center' }}>
                    <div className="form-group row" >
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button className="btn btn-default float-right mt-5" onClick={goBack}><i className="fa fa-chevron-left" />Back</button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button className="btn btn-primary float-left mt-5"><i className="fa fa-chevron-right" />Update</button>
                        </div>
                    </div>
                </div>

            </form >
        </div >
    )
}

ProductEdit.propTypes = {
    searchCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps, {
    searchCategory
})(ProductEdit);

// export default ProductEdit;