import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { forgotpassword } from "../../actions/auth";

const ResetPassword = ({ forgotpassword }) => {
  const [formData, setFormData] = useState({ email: "" });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const { email } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();
    forgotpassword(email);
  };

  return (
    <div style={{height:"100vh",width:"100vw"}}>
    <div className="row justify-content-center align-items-center m-0" style={{height:"100%"}}>
      <div className="col-12 col-sm-8 col-lg-6 col-xl-4 m-0 p-0">
            <div className="card card-custom">
                  <div className="card-header border-0 pt-4">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="text-primary">
                        Forgot it? Reset Your Account
                      </h3>
                      <h5 className="mt-0 font-size-sm text-muted">
                        Enter Your Email
                      </h5>
                    </div>
                </div>
                <div className="card-body">
                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label className="bmd-label-floating" htmlFor="email">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <input
                      type="submit"
                      className='btn btn-primary  pull-right"'
                      value="Send Verification Link"
                    />
                    <span></span>
                    <Link to="/login" className="pull-right">
                      <i className="fas fa-user " />{" "}
                      <span className="hide-sm ">Sign In</span>
                    </Link>

                    <div className="clearfix"></div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="card card-profile">
                <div className="card-avatar">
                  <Link to="/resetpassword">
                    <img className="img"
                      className="img" alt="resetpassword"
                      src="../assets/img/faces/mubashare.jpg"
                    />

                  </Link>
                </div>
                <div className="card-body">
                  <h6 className="card-category text-gray">CEO / Co-Founder</h6>
                  <h4 className="card-title">Mubashar Ehsan</h4>
                  <p className="card-description">
                    Don't be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                  </p>
                  <Link to="/resetpassword" className="btn btn-primary btn-round">
                    Follow
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>

  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, { forgotpassword })(ResetPassword);
