import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Select from "react-select";
import AsyncSelect from "react-select/async";
import { updateBulk, searchUsers, searchCatalogs } from "../../../actions/auth";
import fileDownload from 'js-file-download';
import axios from 'axios';

const BulkUpdateScript = (props) => {
  const { updateBulk, searchUsers } = props;   // searchCatalogs
  // const { catalogs } = props;
  const [uploadFormData, setUploadFormData] = useState({
    // user_email_doc: "",
    user_email: "",
    // catalog: "",
    csvFile: null,
  });
  // const [catalogsList, setCatalogsList] = useState([catalogs]);
  const [loading, setloading] = useState(false);

  let usersList = [];

  // search users - loading emails...
  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback(usersList);
    } else {
      searchUsers(inputValue, (users) => {
        usersList = users.map((user) => ({
          label: user.sellerSubscriptionPaymentStatus === 'Paid' ? `${user.email} (Paid)` : `${user.email} (Not Paid)`,
          value: user.sellerSubscriptionPaymentStatus === 'Paid' ? user._id : '',
        }));
        callback(usersList);
      });
      // setCatalogsList(catalogs);
    }
  };

  const onSearchChange = (selectedItem, event) => {
    // console.log('event : ', event)
    if (selectedItem === null && event.action === "clear") {
      // clear event is fired, reset the selected item...
      usersList = [];
    } else {
      // item is selected, set state here...
      // console.log("selectedItem.value================================")
      // console.log(selectedItem.value);
      // console.log(event.name);

      // if (event.name === "user_email") {
      //   searchCatalogs(selectedItem.value, (catalogs) => {

      //     if (catalogs === undefined) {
      //       setCatalogsList([]);
      //     }

      //     else {
      //       if (catalogs.length > 0) {
      //         setCatalogsList(
      //           catalogs.map((catalog) => ({
      //             // label: catalog.slug,
      //             label: catalog.categoryLink ? catalog.categoryLink : catalog.catalogCategory,
      //             link: catalog.categoryLink,
      //             value: catalog._id,
      //           }))
      //         );
      //       } else {
      //         alert("No catalog found.")
      //       }
      //     }
      //   });
      // }
      // update file state...
      setUploadFormData({
        ...uploadFormData,
        [event.name]: selectedItem.value,
      });
    }
  };


  // const onCatalogChange = (selectedCatalog, event) => {
  //   // console.log("onCatalogChange: ", selectedCatalog);
  //   setUploadFormData({
  //     ...uploadFormData,
  //     [event.name]: selectedCatalog.value,
  //   });
  // };

  const onFileChange = (e) => {
    setUploadFormData({
      ...uploadFormData,
      [e.target.name]: e.currentTarget.files[0],
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const formData = new FormData();
    // console.log("finally submitting form: ", uploadFormData);
    // formData.append("user_email_doc", uploadFormData.user_email_doc);
    formData.append("user_email", uploadFormData.user_email);
    // formData.append("catalog", uploadFormData.catalog);
    formData.append("csvFile", uploadFormData.csvFile);

    if (uploadFormData.user_email === '') {
      alert('please select email field')
      setloading(false);
    } else if (uploadFormData.csvFile === null) {
      alert('please choose csv file')
      setloading(false);
    } else {
      updateBulk(formData, resp => {
        if (resp) {
          setloading(false);
        }
      })
    }
  };

  const downloadSample = async (e) => {
    e.preventDefault();
    const body = {filename: 'bulkproduct-update-samplefile.csv'};
    axios.post('/api/product/downloadSimpleProductSample', body)
      .then(function (res) {
        // console.log('res ', res.data);
        if (res.status === 200) {
          fileDownload(res.data, "BulkProduct-Update-Samplefile.csv");
        } else {
          alert("File Not Exists");
        }
      }).catch(e => console.log(e.message));
  }

  return (
    <Fragment>
      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-4">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="text-primary">
                      Product Bulk update Page
                    </h3>
                    <h5 className="mt-0 font-size-sm text-muted">
                      choose CSV file to update Bulk products
                    </h5>
                  </div>
                  <div className="card-toolbar">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={downloadSample}
                    >
                      Download sample
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    {/* <div className="form-group">
                      <label htmlFor="user_email_doc">
                        Email address (document)
                      </label>
                      <AsyncSelect
                        placeholder="Select email you receive"
                        loadingMessage={() => "Searching through users"}
                        name="user_email_doc"
                        id="user_email_doc"
                        onChange={onSearchChange}
                        isClearable
                        defaultOptions={false}
                        loadOptions={loadOptions}
                      />
                      <small
                        id="user_email_doc"
                        className="form-text text-muted"
                      >
                        This email is used to to send document.
                      </small>
                    </div> */}

                    <div className="form-group">
                      <label htmlFor="user_email">Email address</label>
                      <AsyncSelect
                        placeholder="Select user email"
                        loadingMessage={() => "Searching through users"}
                        name="user_email"
                        id="user_email"
                        onChange={onSearchChange}
                        isClearable
                        defaultOptions
                        loadOptions={loadOptions}
                      />
                      <small id="user_email" className="form-text text-muted">
                        {/* This email is used to get an email if anything goes wrong. */}
                        Select Email to upload Products File
                      </small>
                    </div>

                    {/* <div className="form-group">
                      <label htmlFor="catalog">Catalog*</label>
                      <Select
                        name="catalog"
                        options={catalogsList}
                        onChange={onCatalogChange}
                      />
                    </div> */}

                    {/* <div className="form-group">
                      <input
                        type="file"
                        placeholder="Choose File"
                        name="csvFile"
                        id="csvfile"
                        onChange={onFileChange}
                      />
                    </div> */}
                    <div className="row mb-4 ml-1">
                      <input
                        accept=".xlsx, .xls, .csv"
                        placeholder="Choose File"
                        name="csvFile"
                        id="csvfile"
                        multiple
                        type="file"
                        onChange={onFileChange}
                      />
                    </div>
                    <div className="row justify-content-end mr-4">
                      {loading ? (
                        <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                      ) :
                        <button
                          type="submit"
                          className='btn btn-primary'
                          disabled={loading}
                        >
                          <div className="d-flex align-content-center">
                            <i className="fas fa-upload pr-2"></i>
                            <span>Update</span>
                          </div>
                        </button>
                      }
                    </div>
                    {/* <div className="clearfix"></div> */}
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="card card-profile">
                <div className="card-avatar">
                  <Link to="/uploadscript">
                    <img className="img"  alt="uploadscript" src="../assets/img/faces/mubashare.jpg" />
                  </Link>
                </div>
                <div className="card-body">
                  <h6 className="card-category text-gray">CEO / Co-Founder</h6>
                  <h4 className="card-title">Mubashar Ehsan</h4>
                  <p className="card-description">
                    Don't be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                  </p>
                  <Link to="/uploadscript" className="btn btn-primary btn-round">
                    Follow
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

BulkUpdateScript.propTypes = {
  updateBulk: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  searchCatalogs: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  catalogs: state.auth.usersCatalog
});

export default connect(mapStateToProps, {
  updateBulk,
  searchUsers,
  searchCatalogs,
})(BulkUpdateScript);

