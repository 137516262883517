import React, { useEffect } from "react";
import { connect } from "react-redux";

const Users = ({ auth: { users, user } }) => {
  const checkRole =
    user.role === "admin"? (
      <div className="card-body">
        <div className="table-responsive">
          <table id="example2" className="table">
            <thead className=" text-primary">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="text-primary">{user.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ) : (
        <div className="text-danger">
          {" "}
        You don't have permission to View this table
        </div>
      );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/content.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header card-header-primary">
                <h4 className="card-title ">Users Table</h4>
                <p className="card-category">
                  {" "}
                  Here is a subtitle for this table
                </p>
              </div>
              {checkRole}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(Users);
