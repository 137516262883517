/* eslint-disable */
"use strict";

import SBScrolltop from "../../components/scrolltop.js";
import { SBUtil } from "../../components/util.js";

var SBLayoutScrolltop = function() {
    // Private properties
    var _element;
    var _object;

    // Private functions
    var _init = function() {
        _object = new SBScrolltop(_element, {
          offset: 300,
          speed: 600,
        });
    }

    // Public methods
	return {
		init: function(id) {
            _element = SBUtil.getById(id);

            if (!_element) {
                return;
            }

            // Initialize
            _init();
		},

        getElement: function() {
            return _element;
        }
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = SBLayoutScrolltop;
}

export default SBLayoutScrolltop;