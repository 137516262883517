import React from 'react';
import axios from 'axios';
import setAuthToken from "../../../utils/setAuthToken";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { connect } from "react-redux";
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';


const dateFormater = (cell, row) => (
    <span>{new Date(row._source && row._source.created_at ? row._source.created_at : row.created_at).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' })}</span>
)

const emailFormatter = (cell, row) => (
    row && row._source && row._source.email ?
        <span>
            {row._source.email}
        </span>
        : row.email ?
            <span>
                {row.email}
            </span>
            : ''
);

const unameFormatter = (cell, row) => (
    row && row._source && row._source.user_name ?
        <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }} title='Link'>
            <Link
                // className="menu-link"
                to={{ pathname: `./vendorView`, search: `?userId=${row._id}` }}
            >
                {row._source.user_name}
            </Link>
        </div>
        // <span>
        //     {row._source.user_name}
        // </span>
        : row.username ?
            <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }} title='Link'>
                <Link
                    // className="menu-link"
                    to={{ pathname: `./vendorView`, search: `?userId=${row._id}` }}
                >
                    {row.username}
                </Link>
            </div>
            // <span>
            //     {row.username}
            // </span>
            : ''
);

const bnameFormatter = (cell, row) => (
    row && row._source && row._source.business_name ?
        <span>
            {row._source.business_name}
        </span>
        : row.business_name ?
            <span>
                {row.business_name}
            </span>
            : ''
);


const viewProfile = (cell, row) => {
    // console.log(row);
    return (
        (row.role === 'admin' || row.role === 'seomanager_user' || row.role === 'admin_readOnly' || row.role === 'readOnly_user' || row.role === "dataentry_user") ?
        <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }} title='View User Categories'>
            <Link to={{ pathname: `./listCatalogs`, search: `?page=1&sizePerPage=10&filters=${JSON.stringify({ 'userId.email': { filterVal: encodeURIComponent(row._source && row._source.email ? row._source.email : row.email), filterType: "TEXT", comparator: "LIKE", caseSensitive: false } })}` }}
                target="_blank" className="btn btn-default my-1">
                <i className="far fa-eye pr-2" aria-hidden="true"></i>
            </Link>
        </div>
        : ''
    )
}

// const enableFormatter = (cell, row) => (
//     row &&
//     <span>
//         <p>{row.status.active ? 'True' : 'False'}</p>
//     </span>
// );

const columns = [

    {
        dataField: 'images',
        text: 'Image',
        headerStyle: {
            fontWeight: 'bold',
            width: '10rem',
            textAlign: 'center'
        },
        formatter: imageFormatter,
        headerAttrs: { width: 200 },
        attrs: { width: 150, className: "EditRow" }
    },

    {
        dataField: 'email',
        text: 'Email',
        filter: textFilter({
            delay: 2000
        }),
        formatter: emailFormatter,
        headerStyle: {
            fontWeight: 'bold',
            width: '6rem',
            textAlign: 'center'
        }
    },

    {
        dataField: 'business_name',
        text: 'Business Name ',
        // filter: textFilter({ delay: 2000 }),
        filter: textFilter({
            delay: 2000
        }),
        formatter: bnameFormatter,
        headerStyle: {
            fontWeight: 'bold',
            width: '8rem',
            textAlign: 'center'
        }
    },
    {
        dataField: 'username',
        text: 'User Name',
        filter: textFilter({
            delay: 2000
        }),
        formatter: unameFormatter,
        headerStyle: {
            fontWeight: 'bold',
            width: '6rem',
            textAlign: 'center'
        }
    },

    {
        dataField: 'slugCount',
        text: 'page Count',
        headerStyle: {
            fontWeight: 'bold',
            width: '6rem',
            textAlign: 'center'
        }
    },

    {
        dataField: 'created_at',
        text: 'Created At',
        sort: true,
        formatter: dateFormater,
        headerStyle: {
            fontWeight: 'bold',
            width: '6rem',
            textAlign: "center",
        },
    },

    {
        dataField: "_id",
        text: "Actions",
        headerStyle: {
            fontWeight: "bold",
        },
        formatter: viewProfile,
        headerAttrs: { width: 80 },
        attrs: { width: 150, className: "EditRow" },
    }

];

const defaultSorted = [{
    dataField: 'created_at',
    order: 'desc'
}];

const NoDataIndication = ({ loading, totalSize }) => (
    <div>
        {loading && totalSize === 0 ?
            <div className="spinner-grow align-center" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            :
            <div>No record found</div>
        }
    </div>
);

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize, selectRow, loading, options }) => (

    <>
        <PaginationProvider
            pagination={
                paginationFactory({
                    custom: true,
                    page,
                    sizePerPage,
                    totalSize,
                    showTotal: true,
                    // sizePerPageOptionRenderer,
                    sizePerPageList: [
                        {
                            text: '10', value: 10
                        }, {
                            text: '20', value: 20
                        }, {
                            text: '30', value: 30
                        }, {
                            text: '50', value: 50
                        }, {
                            text: '100', value: 100
                        }, {
                            text: '200', value: 200
                        }]
                })
            }
        >
            {
                ({
                    paginationTableProps,
                    paginationProps
                }) => (
                    <>
                        <BootstrapTable
                            bootstrap4
                            remote
                            loading={loading}
                            keyField="_id"
                            data={data}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            filter={filterFactory()}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            selectRow={selectRow}
                            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
                            noDataIndication={(loading, totalSize) => <NoDataIndication />}
                            hover
                            condensed
                        />
                        <div className="row react-bootstrap-table-pagination mt-4">
                            <div className="col-md-1 col-xs-1 col-sm-1 col-lg-1" style={{}}>
                                <SizePerPageDropdownStandalone className="pageSizer" {...paginationProps} />
                            </div>
                            <div className="col-md-5 col-xs-5 col-sm-5 col-lg-5" style={{ marginTop: "13px" }}>
                                <PaginationTotalStandalone {...paginationProps} />
                            </div>
                            <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        </div>
                    </>
                )
            }
        </PaginationProvider>
    </>
);

function imageFormatter(cell, row, rowIndex, formatExtraData) {
    if (row._source && row._source.profile_pic && row._source.profile_pic !== undefined) {
        return (
            <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}>
                {/* <Link className="" to={{ pathname: `${process.env.REACT_APP_BUCKET_URL}${process.env.REACT_APP_PRODUCT_IMAGE_URL}/${row._source.profile_pic}` }} target="_blank"> */}
                    <img className="img" src={`${process.env.REACT_APP_BUCKET_URL}${process.env.REACT_APP_PRODUCT_IMAGE_URL}/${row._source.profile_pic}`} alt="" />
                {/* </Link> */}
            </div>
        );
    } else if (row.images !== undefined && row.images.profile_pic !== undefined) {
        let image_resolution = "";
        if (row.images.profile_pic[250]) {
            image_resolution = row.images.profile_pic[250];
        } else if (row.images.profile_pic[100]) {
            image_resolution = row.images.profile_pic[100];
        }

        if (!image_resolution) {
            return (
                <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}>
                    <img className="img" src={`/assets/img/default.png`} alt="" />
                </div>
            );
        }
        return (
            <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}>
                {/* <Link className="" to={{ pathname: `${process.env.REACT_APP_BUCKET_URL}${process.env.REACT_APP_PRODUCT_IMAGE_URL}/${image_resolution}` }} target="_blank"> */}
                    <img className="img" src={`${process.env.REACT_APP_BUCKET_URL}${process.env.REACT_APP_PRODUCT_IMAGE_URL}/${image_resolution}`} alt="" />
                {/* </Link> */}
            </div>
        );
    } else {
        return (
            <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}>
                <img className="img" src={`/assets/img/default.png`} alt="" />
            </div>
        );
    }
}

class PaidVendorsList extends React.Component {
    constructor(props) {
        super(props);
        // console.log("buyer props ", props);
        this.state = {
            page: 1,
            data: [],
            totalSize: 0,
            sizePerPage: 10,
            selected: [],
            selectedData: [],
            loading: true,
            defaultSorted: {},
            filters: {},
            sortField: '',
            sortOrder: '',
            catalogsList: [],
            usersList: [],
            userId: '',
            catalogId: '',
            alert: {},
            timeOut: null,
            userSlugs: [],
            userCatalogs: []
        };
        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);

    }


    componentWillUnmount() {
        this.state.timeOut && clearTimeout(this.state.timeOut);
    }

    static getDerivedStateFromProps(props, state) {
        let { email = '' } = props.userDetail || {};
        let { _email = '' } = state.userData || {};
        // if (props.id !== state.id) {
        //     return {
        //         id: props.id
        //     }
        // }
        if (email !== _email) {
            return {
                userData: props.userDetail
            };
        }
        return null;
    }

    // this prevent extra rerender because of getDerivedStateFromProps
    componentDidUpdate(prevProps, currState) {
        // if (prevProps.id !== this.state.id) {
        //     this.getUserContacts({ page: currState.page, sizePerPage: currState.sizePerPage, filters: currState.filters, sortField: currState.sortField, sortOrder: currState.sortOrder, id: this.state.id });
        // }
        if (this.props.userDetail.email !== prevProps.userDetail.email) {
            this.selectNew();
        }
    }

    handleADD = (_) => {
        if (this.state.selected.length > 0) {

            const addUser = this.state.selectedData[0];
            // if (addUser.slugCount >= 10) {
            //     alert('This User Already added in 10 listing pages');
            //     return []
            // } else {
                let b_name = addUser.business_name ? addUser.business_name : addUser._source.business_name
                this.props.parentCallback({ _id: addUser._id, link: b_name });
            // }

        } else {
            alert("Please select User to Add.");
        }
    }

    handleOnSelect = (row, isSelect) => {
        // console.log('row   === ', row);
        // var abc = []
        // this.state.selectedData && this.state.selectedData.map((item)=> {
        //     abc.push(item._id);
        //     return []
        // })
        // if (abc.includes(row._id)) {
        //     alert('This User Already Selected');
        //     return []
        // }
        if (isSelect) {
            this.setState(() => ({
                selected: [row._id],
                selectedData: [row],
                userSlugs: row.slugIds,
                userCatalogs: row.catalogs
                // selected: [...this.state.selected, row._id]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row._id),
                userSlugs: [],
                userCatalogs: []
            }));
        }
    }

    // handleOnSelectAll = (isSelect, rows) => {
    //     const ids = rows.map(r => r._id);
    //     if (isSelect) {
    //         this.setState(() => ({
    //             selected: ids
    //         }));
    //     } else {
    //         this.setState(() => ({
    //             selected: []
    //         }));
    //     }
    // }

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {

        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        // console.log(this.state.userId);
        const body = { page, sizePerPage, filters, sortField, sortOrder };

        axios.post('/api/users/getUsersForVendorListing', body)
            .then(res => {
                // console.log(res.data.data)
                const data = res.data.data || [];
                const { userData = {} } = this.state;
                for (const itr of data) {
                    itr.role = userData.role || '';
                }
                this.setState({
                    page: res.data.page,
                    data: res.data.data,
                    totalSize: res.data.recordsTotal,
                    sizePerPage,
                    filters,
                    sortField,
                    sortOrder,
                    loading: false,
                    defaultSorted: defaultSorted,
                    selected: [],
                })
            }).catch(e => console.log(e));
        this.setState({ loading: true, data: [] });

    }


    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            style: { backgroundColor: '#c8e6c9', align: 'middle' },
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            // onSelectAll: this.handleOnSelectAll
        };

        const { data, sizePerPage, totalSize, page, loading, alert, selected } = this.state;//, stats

        return (
            <div>
                {alert.msg &&
                    <div className="d-flex justify-content-center">
                        <div className={`alert alert-${alert.alertType}`} style={{ zIndex: '9' }} role="alert">
                            {alert.msg}
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-custom gutter-b">
                            {/* <div className="card-header border-0 pt-4">
                                <div className="card-title align-items-start flex-column">
                                    <h3 className="text-primary">
                                        Vendors
                                    </h3>
                                    <h5 className="mt-0 font-size-sm text-muted">
                                        Listing of Paid Vendors
                                    </h5>
                                </div>
                            </div> */}
                            <div className="card-body" style={{ padding: '0.9375rem 1.875rem' }}>

                                <div className="float-left">
                                    <h3 className="text-primary">
                                        Vendors
                                    </h3>
                                    <h5 className="mt-0 font-size-sm text-muted">
                                        Listing of Paid Vendors
                                    </h5>
                                </div>

                                <div className="float-right my-2">
                                    <Button
                                        className="mr-2"
                                        // data-backdrop='static'
                                        disabled={selected.length < 1}
                                        onClick={this.handleADD}
                                    // data-keyboard='false'
                                    >
                                        ADD
                                    </Button>

                                    <Button
                                        className="mr-2"
                                        variant="secondary"
                                        data-toggle="modal"
                                        data-target="#detailModal"
                                        data-backdrop='static'
                                        disabled={this.state.userSlugs.length < 1}
                                        data-keyboard='false'>
                                        <i className="fas fa-eye pr-2" aria-hidden="true" ></i>Detail
                                    </Button>

                                    <Button
                                        className="mr-2"
                                        variant="secondary"
                                        data-toggle="modal"
                                        data-target="#viewCatalogModal"
                                        data-backdrop='static'
                                        disabled={this.state.userCatalogs.length < 1}
                                        data-keyboard='false'>
                                        <i className="fas fa-eye pr-2" aria-hidden="true" ></i>User Categories
                                    </Button>
                                </div>

                                <div className="table-responsive" style={{ overflowX: "hidden", minHeight: "auto", padding: "4px", margin: "0" }}>
                                    <RemotePagination
                                        data={data}
                                        page={page}
                                        sizePerPage={sizePerPage}
                                        totalSize={totalSize}
                                        onTableChange={this.handleTableChange}
                                        selectRow={selectRow}
                                        loading={loading}
                                    />
                                </div>

                                <div className="modal fade" id="detailModal" tabIndex="-1" role="dialog" aria-labelledby="ddetailModalLabel" aria-hidden="true" >
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="ddetailModalLabel">User Exist in Below Pages</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body py-10 px-lg-17">
                                                {
                                                    this.state.userSlugs.length && this.state.userSlugs.map((item, i) => {
                                                        return <div style={{fontSize: 17}} className='row mt-2' key={i}>
                                                            <span>seebiz.com/vendors/{item.businessTypeName}/{item.slug}</span>
                                                            <Link to={{ pathname: `./editVendorsDesc`, search: `?slugId=${item._id}` }}
                                                                target="_blank"
                                                                className="ml-2 d-flex align-items-center font-weight-bolder">
                                                                {/* <i className="fas fa-sort-amount-down-alt" /> */}
                                                                Edit
                                                            </Link>
                                                            {/* {item} */}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleDelete} >YES</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="viewCatalogModal" tabIndex="-1" role="dialog" aria-labelledby="viewCatalogModalLabel" aria-hidden="true" >
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="viewCatalogModalLabel">List of User Categories</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body py-10 px-lg-17">
                                                {
                                                    this.state.userCatalogs.length && this.state.userCatalogs.map((item, i) => {
                                                        return <div style={{fontSize: 17}} className='row mt-2' key={i}>
                                                            <h6><li>{item.categoryBreadcrumb}</li></h6>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleDelete} >YES</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetail: state.auth.user,
    };
};

export default connect(mapStateToProps, {})(PaidVendorsList);

