import axios from 'axios';
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

import { BUSINESS_INFO_UPDATE, BUSINESS_INFO_ERROR, SEARCH_USER_BY_MULTIPLE, SEARCH_USER_BY_MULTIPLE_ERROR } from './types';

// search user
export const searchUsers = (search, callback) => async dispatch => {
  // console.log('localStorage.getItem("token")',localStorage.getItem('token'));
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
    try {
        let res = await axios.get("api/users/search_by_multiple?search=" + search);
        // dispatch({
        //     type: SEARCH_USER_BY_MULTIPLE,
        //     payload: res.data
        // })
        dispatch({ type: SEARCH_USER_BY_MULTIPLE });

        callback(res.data);

    } catch (err) {
        const errors = err.response.data.errors;
    
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
    
      dispatch({ type: SEARCH_USER_BY_MULTIPLE_ERROR });
    }
  };

  // Upload File
export const updateBusinessInfo = (formData) => async dispatch => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    // console.log(localStorage.getItem('token'));
    try {  
      const res = await axios.post("api/users/updateBusinessInfo", formData);
      dispatch({ type: BUSINESS_INFO_UPDATE });
      dispatch(setAlert(res.data.message, res.data.status, 10000));
    } catch (err) {
        // console.log(err);
        const errors = err.response.data.errors;
    
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
      dispatch({ type: BUSINESS_INFO_ERROR });
    }
  };