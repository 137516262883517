import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import { useSubheader } from "./../../_seebizsol/layout";
import ImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';


export default function ProductDetail() {

    const [productData, setProductData] = useState({
        _id: '', Color: [], Diameter: [], Length: [], Material: [], Power: [], Size: [], Voltage: [], Volume: [], Packaging: [], category: '', description: '',
        getOrders: false, getQuotes: false, logoCustomization: {}, graphicsCutomization: {}, packagingCustomization: {}, groupPrice: [],
        tierPrice: [], isBanner: '', isFeature: '', isCustomization: false, isSamples: false, isTrashed: false, keyWords: [], leadTime: [],
        lotsize: '', measureUnit: {}, minOrderQuantity: {}, minGroupOrderQuantity: {}, merchant_id: {}, msrp: '', newArrival: '', notification: false,
        oldSlug: '', payments: [], shipments: [], productType: '', product_images: [], product_main_image: '', product_main_video: '',
        product_video: {}, readytoship: false, samples: [], shippingDetail: [], shortDescription: '', sku: '', slug: '', title: '',
        totalCost: '', type: '', uniqueUrl: '', published_date: '', created_at: '', updated_at: '', createdBy: '', updatedBy: '', packgingUnit: ''
    })

    const [catalogCategory, setCatalogCategory] = useState({
        category: '',
        categoryBreadcrumb: '',
        categoryLink: '',
        createdDate: '',
        faulty: false,
        isTrashed: false,
        old: false,
        orphan: false,
        products: '',
        updatedDate: '',
        userId: '',
        type: '',
        CategorySlug: '',
        description: '',
        parent: ''
    })

    const [images, setImages] = useState([])

    let productId = (new URLSearchParams(useLocation().search)).get("productId");
    const subheader = useSubheader();
    useEffect(() => { subheader.setTitle("Product Details"); })
    // subheader.setBreadcrumbs([
    //     { pathname: "product-detail?productId=" + productId, title: "Product Details" }
    // ])


    useEffect(() => {

        async function getPost() {
            try {
                const res = await axios.get(`/api/product/productDetail/${productId}`)

                const {
                    _id, Color, Diameter, Length, Material, Power, Size, Voltage, Volume, category, description, getOrders, getQuotes, logoCustomization,
                    graphicsCutomization, packagingCustomization, groupPrice, tierPrice, isBanner, isFeature, isCustomization, isSamples, isTrashed,
                    keyWords, leadTime, lotsize, measureUnit, minOrderQuantity, minGroupOrderQuantity, merchant_id, msrp, newArrival, notification,
                    oldSlug, payments, shipments, productType, product_images, product_main_image, product_main_video, product_video, readytoship,
                    samples, shippingDetail, shortDescription, sku, slug, title, totalCost, type, uniqueUrl, published_date, updated_at, created_at,
                    createdBy, updatedBy, packgingUnit, Packaging
                } = res.data;

                // console.log('res :>> ', res.data);

                if (description) {
                    var disc = description.replace(/<[^>]+>/g, '')
                    disc = disc.replace(/&nbsp;/g, '');
                }

                if (shortDescription) {
                    var shortDisc = shortDescription.replace(/<[^>]+>/g, '')
                    shortDisc = shortDisc.replace(/&nbsp;/g, '');
                }

                setProductData({
                    _id: _id || '', Color: Color || [], Diameter: Diameter || [], Length: Length || [], Material: Material || [], Power: Power || [], Size: Size || [],
                    Voltage: Voltage || [], Volume: Volume || [], category: category || '', description: disc || '', getOrders: getOrders || false, getQuotes: getQuotes || false,
                    logoCustomization: logoCustomization || {}, graphicsCutomization: graphicsCutomization || {}, packagingCustomization: packagingCustomization || {},
                    groupPrice: groupPrice || [], tierPrice: tierPrice || [], isBanner: isBanner || '', isFeature: isFeature || '', isCustomization: isCustomization || false,
                    isSamples: isSamples || false, isTrashed: isTrashed || false, keyWords: keyWords || [], leadTime: leadTime || [], lotsize: lotsize || '',
                    measureUnit: measureUnit || {}, minOrderQuantity: minOrderQuantity || {}, minGroupOrderQuantity: minGroupOrderQuantity || {}, merchant_id: merchant_id || {},
                    msrp: msrp || '', newArrival: newArrival || '', notification: notification || false, oldSlug: oldSlug || '', payments: payments || [], sku: sku || '',
                    shipments: shipments || [], productType: productType || '', product_images: product_images || [], product_main_image: product_main_image || '',
                    product_main_video: product_main_video || '', product_video: product_video || {}, readytoship: readytoship || false, samples: samples || [],
                    shippingDetail: shippingDetail || [], shortDescription: shortDisc || '', slug: slug || '', title: title || '', packgingUnit: packgingUnit || '',
                    totalCost: totalCost || '', type: type || '', uniqueUrl: uniqueUrl || '', createdBy: createdBy || '', updatedBy: updatedBy || '', Packaging: Packaging || [],
                    published_date: new Date(published_date).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' }) || '',
                    updated_at: new Date(updated_at).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' }) || '',
                    created_at: new Date(created_at).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' }) || ''
                });

                if (res.data.catalogCategory && res.data.catalogCategory.length > 0) {
                    const { catalogCategory, categoryBreadcrumb, categoryLink, createdDate, faulty, isTrashed, old, orphan, products, updatedDate, userId, type,
                        slug, description, parent } = res.data.catalogCategory[0];
                    setCatalogCategory({
                        category: catalogCategory || '', categoryBreadcrumb: categoryBreadcrumb || '', categoryLink: categoryLink || '',
                        isTrashed: isTrashed || false, old: old || false, orphan: orphan || false, products: products || '', faulty: faulty || false,
                        userId: userId || '', type: type || '', CategorySlug: slug || '', description: description || '', parent: parent || '',
                        createdDate: new Date(createdDate).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' }) || '',
                        updatedDate: new Date(updatedDate).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' }) || ''
                    });
                }

                let imagesArray = [];
                product_images && product_images.map(
                    (item) => imagesArray.push({
                        original: process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + item['original'],
                        thumbnail: process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + item['250']
                    }))
                // console.log('images ==========================================', imagesArray);
                setImages(imagesArray);

            } catch (err) {
                console.log(err);
            }
        }
        getPost();
    }, [productId])

    // console.log('disc :>> ', productData.description);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
                        <div className='col-lg-6 mt-4'>
                            <div className="card card-custom gutter-b">

                                <div className="card-header">
                                    <div className="card-title mt-4">
                                        <h3 className="text-primary">
                                            Details
                                        </h3>
                                    </div>
                                </div>

                                <div className="card-body" style={{ padding: '0.9375rem 1.875rem', fontSize: 16, fontWeight: 'bold' }}>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Title:</label>
                                        <span className="col-lg-8 fw-bolder">{productData.title}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Merchant Business Name :</label>

                                        {productData.merchant_id.images && productData.merchant_id.images.profile_pic && productData.merchant_id.images.profile_pic[100] ?
                                            <span className="symbol" style={{ marginTop: -4, paddingLeft: 11 }}>
                                                <img className="img" alt="Pic" style={{ width: 35, height: 35 }}
                                                    src={process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + productData.merchant_id.images.profile_pic[100]} />
                                            </span> : ''
                                        }
                                        {productData.merchant_id.business_name ?
                                            <Link
                                                to={{ pathname: `./vendorView`, search: `?userId=${productData.merchant_id._id}` }}
                                            >

                                                <span className="col-lg-8">{productData.merchant_id.business_name} </span>
                                            </Link> :
                                            ''
                                        }
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Merchant Email :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.merchant_id.email ? productData.merchant_id.email : ''}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">SKU :</label>
                                        <span className="col-lg-8 fw-bolder"> {productData.sku}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Product Type :</label>
                                        <span className="col-lg-8 fw-bolder"> {productData.productType}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Lot Size :</label>
                                        <span className="col-lg-8 fw-bolder"> {productData.lotsize}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Min. Order :</label>
                                        <span className="col-lg-8 fw-bolder"> {productData.minOrderQuantity.value ? productData.minOrderQuantity.value : ''}
                                            &nbsp; {productData.minOrderQuantity.unit ? productData.minOrderQuantity.unit : ''}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Min Group Order :</label>
                                        <span className="col-lg-8 fw-bolder"> {productData.minGroupOrderQuantity.value ? productData.minGroupOrderQuantity.value : ''}
                                            &nbsp; {productData.minGroupOrderQuantity.unit ? productData.minGroupOrderQuantity.unit : ''}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Packaging Unit :</label>
                                        <span className="col-lg-8 fw-bolder"> {productData.packgingUnit}</span>
                                    </div>

                                    {productData.productType === 'productWithVeriablePrice' ?
                                        ""
                                        :
                                        <>
                                            <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                <label className="col-lg-1 text-muted"></label>
                                                <label className="col-lg-3 fw-bold text-muted">Tier Price :</label>
                                                <div className="col-lg-8 d-flex flex-wrap">
                                                    {
                                                        productData.tierPrice.map((item, key, arr) =>
                                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-2 mr-7" key={key}>
                                                                {item.price ? <div style={{ fontSize: 20 }}>${item.price}</div> : ''}

                                                                {item.quantity && key === 0 ? <span className="text-muted" style={{ fontSize: 15 }}>1-{item.quantity} Pieces</span>
                                                                    : <span className="text-muted" style={{ fontSize: 15 }}>{arr[key - 1] !== undefined ? arr[key - 1].quantity + 1 : 1}-{item.quantity} Pieces</span>}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                            <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                <label className="col-lg-1 text-muted"></label>
                                                <label className="col-lg-3 fw-bold text-muted">Group Price :</label>
                                                <div className="col-lg-8 d-flex flex-wrap">
                                                    {
                                                        productData.groupPrice.map((item, key) =>
                                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-2 mr-7" key={key}>
                                                                {item.price ? <div style={{ fontSize: 20 }}>${item.price}</div> : ''}

                                                                {item.groupId && item.groupId.name ? <div className="text-muted" style={{ fontSize: 15 }}>{item.groupId.name}</div> : ''}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Slug :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.slug}</span>
                                    </div>

                                    {/* <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Total Cost :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.totalCost}</span>
                                    </div> */}

                                    {/* <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Type :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.type}</span>
                                    </div> */}

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Unique Url :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.uniqueUrl}</span>
                                    </div>

                                    {
                                        productData.productType === 'variableProduct' || productData.productType === 'productWithVeriablePrice' ?
                                            <>
                                                {productData.Color.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Color :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Color[0] && productData.Color.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item && item.img && item.img[100]?
                                                                            <span className="symbol" style={{ marginTop: -4 }}>
                                                                                <img className="img" alt="Pic" style={{ width: 35, height: 35 }}
                                                                                    src={process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + item.img[100]} />
                                                                            </span> : item.Color
                                                                        }
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Packaging.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Packaging :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Packaging[0] && productData.Packaging.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item && item.img && item.img[100]?
                                                                            <span className="symbol" style={{ marginTop: -4 }}>
                                                                                <img className="img" alt="Pic" style={{ width: 35, height: 35 }}
                                                                                    src={process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + item.img[100]} />
                                                                            </span> : item.Packaging
                                                                        }
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Diameter.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Diameter :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Diameter[0] && productData.Diameter.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item.Diameter}
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Length.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Length :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Length[0] && productData.Length.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item.Length}
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Material.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Material :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Material[0] && productData.Material.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item.Material}
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Power.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Power :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Power[0] && productData.Power.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item.Power}
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Size.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Size :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Size[0] && productData.Size.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item.Size}
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Voltage.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Voltage :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Voltage[0] && productData.Voltage.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item.Voltage}
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }

                                                {productData.Volume.length > 0 ?
                                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                        <label className="col-lg-1 text-muted"></label>
                                                        <label className="col-lg-3 fw-bold text-muted">Volume :</label>
                                                        <span className="col-lg-8 fw-bolder">
                                                            {
                                                                productData.Volume[0] && productData.Volume.map((item, key) =>
                                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                                        {item.Volume}
                                                                        <span className="text-muted ml-2">{item.price ? ` price: $${item.price}` : ''}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    </div> : ''
                                                }
                                            </>
                                            : ''
                                    }

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Key Words :</label>
                                        <span className="col-lg-8 fw-bolder">
                                            {
                                                productData.keyWords[0] && productData.keyWords.map((item, key) =>
                                                    <span className="col-lg-2 fw-bolder" key={key}>
                                                        {item.word}
                                                    </span>
                                                )
                                            }
                                        </span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Ready to Ship :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.readytoship === true ? 'Yes' : 'No'}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Lead Time :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.leadTime[0] && productData.leadTime[0].estimatedTime ?
                                            `${productData.leadTime[0].estimatedTime} ${productData.leadTime[0].unit ? productData.leadTime[0].unit : ''}` : ''}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Measure Unit :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.measureUnit && productData.measureUnit.unitDisplay ? productData.measureUnit.unitDisplay : ''}</span>
                                    </div>

                                    {/* <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Msrp :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.msrp}</span>
                                    </div> */}

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Old Slug :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.oldSlug}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Payments :</label>
                                        <span className="col-lg-8 fw-bolder">
                                            {productData.payments[0] && productData.payments.map((item, key) => {
                                                return <span className="col-lg-2 fw-bolder" key={key}>{item.methodName ? item.methodName : ''}</span>
                                            })}
                                        </span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Shipments :</label>
                                        <span className="col-lg-8 fw-bolder">
                                            {productData.shipments[0] && productData.shipments.map((item, key) => {
                                                return <span className="col-lg-2 fw-bolder" key={key}>{item.serviceName ? item.serviceName : ''}</span>
                                            })}
                                        </span>
                                    </div>

                                    {/* <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Shipping Detail :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.shippingDetail[0] && productData.shippingDetail[0].service ?
                                            productData.shippingDetail[0].service : ''}</span>
                                    </div> */}

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Get Orders :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.getOrders === true ? 'True' : 'False'}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Get Quotes :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.getQuotes === true ? 'True' : 'False'}</span>
                                    </div>

                                    {/* <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Is Banner :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.isBanner}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Is Feature :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.isFeature}</span>
                                    </div> */}

                                    {/* <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">New Arrival :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.newArrival}</span>
                                    </div> */}
                                    {/* 
                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Notification :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.notification === true ? 'True' : 'False'}</span>
                                    </div> */}

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Is Customization :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.isCustomization === true ? 'Yes' : 'No'}</span>
                                    </div>

                                    {
                                        productData.isCustomization ?
                                            <>
                                                <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                    <label className="col-lg-1 text-muted"></label>
                                                    <label className="col-lg-3 fw-bold text-muted">Logo Customization :</label>
                                                    <span className="col-lg-8 fw-bolder">{productData.logoCustomization.minimumQuantity ?
                                                        productData.logoCustomization.minimumQuantity : ''}</span>
                                                </div>

                                                <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                    <label className="col-lg-1 text-muted"></label>
                                                    <label className="col-lg-3 fw-bold text-muted">Packaging Customization :</label>
                                                    <span className="col-lg-8 fw-bolder">{productData.packagingCustomization.minimumQuantity ?
                                                        productData.packagingCustomization.minimumQuantity : ''}</span>
                                                </div>

                                                <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                    <label className="col-lg-1 text-muted"></label>
                                                    <label className="col-lg-3 fw-bold text-muted">Graphics Cutomization :</label>
                                                    <span className="col-lg-8 fw-bolder">{productData.graphicsCutomization.minimumQuantity ?
                                                        productData.graphicsCutomization.minimumQuantity : ''}</span>
                                                </div>
                                            </>
                                            : ''
                                    }

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Is Samples :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.samples && productData.samples.length ? 'True' : 'False'}</span>
                                    </div>

                                    {
                                        productData.samples && productData.samples.length ?
                                            <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                                <label className="col-lg-1 text-muted"></label>
                                                <label className="col-lg-3 fw-bold text-muted">Sample Units:</label>
                                                <span className="col-lg-8 fw-bolder">
                                                    {
                                                        productData.samples[0] && productData.samples.map((item, key) =>
                                                            <span className="col-lg-2 fw-bolder" key={key}>
                                                                {item.noOfUnits}
                                                                <span className="text-muted ml-2">{item.samplePrice ? ` price: ${item.samplePrice}` : ''}</span>
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                            </div>
                                            : ''
                                    }

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Is Trashed :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.isTrashed === true ? 'True' : 'False'}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Created By :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.createdBy}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Updated By :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.updatedBy}</span>
                                    </div>

                                    {/* <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Published Date :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.published_date}</span>
                                    </div> */}

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Created At :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.created_at}</span>
                                    </div>

                                    <div className="row mt-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Updated At :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.updated_at}</span>
                                    </div>

                                    <div className="row mt-5">
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Short Detail : </label>
                                        <span className="col-lg-8 fw-bolder">{productData.shortDescription}</span>
                                    </div>

                                    {/* <div className="row mt-5">
                                        <label className="col-lg-1 text-muted"></label>
                                        <label className="col-lg-3 fw-bold text-muted">Description :</label>
                                        <span className="col-lg-8 fw-bolder">{productData.description}</span>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='card card-custom mt-4'>

                                <div className='card-header'>
                                    <h1 className='fw-bolder mt-6'>Product Images</h1>
                                </div>

                                <div className='card-body p-10 p-lg-15'>
                                    <div className='mb-3'>
                                        {images.length > 0 ? <ImageGallery items={images} />
                                            : <h3 style={{ textAlign: 'center', color: 'red' }}>Images not available for this Product.</h3>}
                                    </div>
                                </div>

                            </div>

                            <div className='card card-custom mt-4'>

                                <div className='card-header'>
                                    <h1 className='fw-bolder mt-6'>Product Video</h1>
                                </div>

                                <div className='card-body p-10 p-lg-15'>
                                    {productData.product_video.video ?
                                        <div className="player-wrapper mb-3">
                                            <ReactPlayer
                                                url={process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + productData.product_video.video}
                                                className='react-player'
                                                width='100%'
                                                height='100%'
                                                controls={true}
                                            />
                                        </div>
                                        : <h3 style={{ textAlign: 'center', color: 'red' }}>Video not available for this Product.</h3>
                                    }
                                </div>

                            </div>

                            <div className='card card-custom mt-4'>

                                <div className='card-header'>
                                    <h1 className='fw-bolder mt-6'>Catalog Category</h1>
                                </div>

                                <div className="card-body pt-0" style={{ padding: '0.9375rem 1.875rem', fontSize: 16, fontWeight: 'bold' }}>
                                    <div className="table-responsive">

                                        <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                            <tbody className="fw-bold text-gray-600">
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">Catalog Category :</div> </td>
                                                    <td className="fw-bolder text-end">{catalogCategory.category}</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">Type : </div> </td>
                                                    <td className="fw-bolder text-end">{catalogCategory.type}</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">Products : </div> </td>
                                                    <td className="fw-bolder text-end">{catalogCategory.products}</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">Slug : </div> </td>
                                                    <td className="fw-bolder text-end">{catalogCategory.CategorySlug}</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">Created Date : </div> </td>
                                                    <td className="fw-bolder text-end">{catalogCategory.createdDate}</td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>

                            <div className='card card-custom mt-4'>

                                <div className='card-header'>
                                    <h1 className='fw-bolder mt-6'>Product Description</h1>
                                </div>

                                <div className='card-body p-10 p-lg-15'>
                                    <p>{productData.description}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}