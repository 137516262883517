import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SBLayoutAsideToggle from "../../../_assets/js/layout/base/aside-toggle";
const list = [];
let toggle;
export function Brand({ short, hideOn }) {
  const link = useRef();
  useEffect(() => {
    const disableHide = (hideOn !== 'on' && hideOn !== 'off')
    if (!disableHide) return register(link.current, hideOn);
    return;
  })
  return (
    <>
      {/* begin::Brand */}
      <div
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-2 py-lg-6`}
      >
        {/* begin::Logo */}
        <Link ref={link} to="" className={"brand-logo "}>
          <img
            alt="logo"
            src={short ? "/favicon.ico" : "/assets/images/logo.svg"}
            // className="max-h-30px"
            style={{ width: "90%", margin: "0 5%" }}
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
var register = function () {
  if (!toggle)
    SBLayoutAsideToggle.onInit((t) => {
      toggle = t;
      t.on('beforeOn', () => handleToggle('on'));
      t.on('beforeOff', () => handleToggle('off'));
    })
  return (el, hideOn) => {
    let index = list.push({ el, hideOn }) - 1;
    if ((toggle && hideOn === toggle.getState()) || hideOn === 'off') {
      el.classList.add('invisible')
    } else {
      el.classList.remove('invisible')
    }
    return () => { delete list[index] }
  }
}();
function handleToggle(futureState) {
  for (let o of list) {
    if (o)
      if (o.hideOn === futureState) {
        o.el.classList.add('invisible')
      } else {
        o.el.classList.remove('invisible')
      }
  }
}
