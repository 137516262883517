import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";

const Login = (props) => {
  const { auth: { user }, login, isAuthenticated, invalidCredentials } = props;

  const [formData, setFormData] = useState({ email: "", password: "" });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    if (user.role === "sem_user")
      return <Redirect to="/metaTags" />;
    else if (user.role === "helpdesk_user")
      return <Redirect to="/articles" />;
    else if (user.role === "basic_user")
      return <Redirect to="/listVendors" />;      // uploadproduct
    else
      return <Redirect to="/dashboard" />;
  }
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <div className="row justify-content-center align-items-center m-0" style={{ height: "100%" }}>
        <div className="col-12 col-sm-8 col-lg-6 col-xl-4 m-0 p-0">
          <div className="card card-custom">
            <div className="card-header border-0 pt-4">
              <div className="card-title align-items-start flex-column">
                <h3 className="text-primary">
                  Sign In
                </h3>
                <h5 className="mt-0 font-size-sm text-muted">
                  Log into your profile
                </h5>
              </div>
            </div>
            <div className="card-body">
              <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="row">
                  <div className="col-md">
                    {invalidCredentials ?
                      <div className="form-group">
                        <span style={{ color: 'red', fontFamily: "emoji", fontSize: '16px' }}>Email/Password Incorrect</span>
                      </div> :
                      null
                    }
                    <div className="form-group">
                      {/* old className className="bmd-label-floating" */}
                      <label style={{ margin: '0', lineHeight: '1.4', fontWeight: '400', top: '-1rem', left: '0', fontSize: '0.6875rem' }}>
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      {/* old className className="bmd-label-floating" */}
                      <label style={{ margin: '0', lineHeight: '1.4', fontWeight: '400', top: '-1rem', left: '0', fontSize: '0.6875rem' }}>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <input
                  type="submit"
                  className='btn btn-primary  pull-right"'
                  value="Login"
                />
                <span></span>
                <Link to="/resetpassword" className="pull-right">
                  <i className="fas fa-user " />{" "}
                  <span className="hide-sm ">Forgot Password</span>
                </Link>
                <div className="clearfix"></div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated,
    invalidCredentials: state.auth.invalidCredentials,
  };
};

export default connect(mapStateToProps, { login })(Login);
