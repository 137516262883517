import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { SeebizsolI18nProvider } from './_seebizsol/i18n';
import { SeebizsolLayoutProvider, SeebizsolSplashScreenProvider, SeebizsolSubheaderProvider } from './_seebizsol/layout';


import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import * as _redux from "./redux";
// import store, { persistor } from "./redux/store";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_seebizsol/_assets/plugins/seebizthemes-icons/font/sbi.css";
// import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "./_seebizsol/_assets/plugins/sbicon/sbicon.css";
// import "./_seebizsol/_assets/plugins/sbicon2/sbicon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
    // <SeebizsolI18nProvider>
        <SeebizsolLayoutProvider>
            <SeebizsolSubheaderProvider>
                <SeebizsolSplashScreenProvider>
                    <App />         
                </SeebizsolSplashScreenProvider>
            </SeebizsolSubheaderProvider>
        </SeebizsolLayoutProvider>
    // </SeebizsolI18nProvider>
    , document.getElementById('root'));
serviceWorker.unregister();
