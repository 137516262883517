import axios from 'axios';
import { STATIC_DATA_ADDED, STATIC_DATA_ERROR, GET_STATIC_DATA, GET_STATIC_PAGE_DATA } from './types';

export const static_pages_content = ({ title, metaData, content }) => async dispatch => {
     try {
          const body = { title, metaData, content };
          const res = await axios.post("/api/staticPages/about", body);

          dispatch({
               type: STATIC_DATA_ADDED,
               payload: res.data
          });

     } catch (error) {
          // const errors = error.response.data.errors;
          // if (errors) {
          //      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
          // }

          dispatch({
               type: STATIC_DATA_ERROR
          });
     }
}

export const getStaticData = () => async dispatch => {
     try {
          let res = await axios.get("/api/staticPages/staticPagesData");
          dispatch({
               type: GET_STATIC_DATA,
               payload: res.data
          })
     } catch (error) {

          dispatch({
               type: STATIC_DATA_ERROR
          })
     }
}

export const getStaticPageDataById = (id) => async dispatch => {

     try {
          let res = await axios.get(`/api/staticPages/getSelectedPageData/${id}`);
          dispatch({
               type: GET_STATIC_PAGE_DATA,
               payload: res.data
          })
     } catch (error) {
          dispatch({
               type: STATIC_DATA_ERROR
          })
     }
}