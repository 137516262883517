import React from "react";
import Routes from "./Routes";
// import Sidebar from "../layout/Sidebar";
// import Footer from "../layout/Footer";
// import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import {Layout} from '../../_seebizsol/layout'
// import Navbar from "../layout/Navbar";

const MainPanel = () => {
  return (
    <>
      <Layout>
        {/* <Navbar></Navbar> */}
        <Routes /></Layout>
        {/* <Sidebar />
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        <Footer /> */}
    </>
  );
};
export default MainPanel;
