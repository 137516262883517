/* eslint-disable */
"use strict";

import SBMenu from "../../components/menu.js";
import { SBUtil } from "../../components/util.js";
import SBLayoutAside from "./aside.js";

var SBLayoutAsideMenu = function() {
    // Private properties
    var _element;
    var _menuObject;

	// Initialize
	var _init = function() {
		_menuObject = new SBMenu(_element, {
			// Submenu setup
			submenu: {
				desktop: 'accordion',
				tablet: 'accordion', // menu set to accordion in tablet mode
				mobile: 'accordion' // menu set to accordion in mobile mode
			},

			// Accordion setup
			accordion: {
				expandAll: false // allow having multiple expanded accordions in the menu
			}
		});

		 // Close aside offcanvas panel before page reload On tablet and mobile
        _menuObject.on('linkClick', function(menu) {
            if (SBUtil.isBreakpointDown('lg')) { // Tablet and mobile mode
                SBLayoutAside.getOffcanvas().hide(); // Hide offcanvas after general link click
            }
        });
	}

    // Public methods
	return {
		init: function(id) {
            _element = SBUtil.getById(id);

            if (!_element) {
                return;
            }

            // Initialize menu
            _init();
		},

		getElement: function() {
			return _element;
		},

        getMenu: function() {
			return _menuObject;
		},

        pauseDropdownHover: function(time) {
			if (_menuObject) {
				_menuObject.pauseDropdownHover(time);
			}
		},

		closeMobileOffcanvas: function() {
			if (_menuObject && SBUtil.isMobileDevice()) {
				_menuObject.hide();
			}
		}
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = SBLayoutAsideMenu;
}

export default SBLayoutAsideMenu;