/* eslint-disable */
"use strict";

import { SBUtil } from "../../components/util.js";
import SBLayoutSubheader from "./subheader.js";
import SBLayoutFooter from "./footer.js";

var SBLayoutContent = function() {
    // Private properties
    var _element;

	// Private functions
	var _getHeight = function() {
		var height;

		height = SBUtil.getViewPort().height;

        if (_element) {
            height = height - parseInt(SBUtil.css(_element, 'paddingTop')) - parseInt(SBUtil.css(_element, 'paddingBottom'));
        }

        height = height - SBLayoutSubheader.getHeight();
        height = height - SBLayoutFooter.getHeight();

		return height;
	}

    // Public methods
	return {
		init: function(id) {
            _element = SBUtil.getById(id);
		},

		getHeight: function() {
			return _getHeight();
		},

        getElement: function() {
            return _element;
        }
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = SBLayoutContent;
}

export default SBLayoutContent;