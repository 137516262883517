import React from "react";
import { Link } from "react-router-dom";
import { Brand } from "../../_seebizsol/layout/components/brand/Brand";
export const Landing = () => {
  return <div style={{overflowX:"hidden"}}>
    <div className="row justify-content-center align-items-center p-0 m-0" style={{
      background: `url("/media/bg/bg-3.jpg") no-repeat center center/cover`,
      minHeight: "100vh",
      width:"100vw",
      overflowX:"hidden"
    }}>
      <div>
        <Brand />
        <h1 className="px-8">SeeBiz AdminPanel</h1>
        <h3 className="px-8">AdminPanel for First Social Network for B2B, share posts and get
          help from other</h3>
        <div className="d-flex justify-content-center">
          <Link to="/login" className="btn btn-success">
            Login
          </Link>
        </div>
      </div>
    </div>
    <div className="d-flex flex-column p-8 justify-content-center" style={{background:"rgb(250,250,250)"}}>
      <h1>About</h1>
      <p>
        This is an admin-panel used by different teams of SeeBiz inc.The admin panel is where the content is created and the website is managed.
        This is the key to how a content management system (CMS) works.Feel free
        to use this panel for your productivity.
      </p>

      <h2>Follow Me On Social Media</h2>

      <div className="social ">
        <a href="/" className="btn btn-icon btn-light-success m-1">
          <i className="fab fa-twitter fa-3x"></i>
        </a>
        <a href="/" className="btn btn-icon btn-light-success m-1">
          <i className="fab fa-facebook fa-3x"></i>
        </a>
        <a href="/" className="btn btn-icon btn-light-success m-1">
          <i className="fab fa-linkedin fa-3x"></i>
        </a>
      </div>
      </div>
  </div>
};
export default Landing;
