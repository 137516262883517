/* eslint-disable */
"use strict";

import { SBUtil } from "../../components/util.js";
import SBLayoutContent from "./content.js";

var SBLayoutStretchedCard = function() {
    // Private properties
	var _element;

	// Private functions
	var _init = function() {
		var scroll = SBUtil.find(_element, '.card-scroll');
		var cardBody = SBUtil.find(_element, '.card-body');
		var cardHeader = SBUtil.find(_element, '.card-header');

		var height = SBLayoutContent.getHeight();

		height = height - parseInt(SBUtil.actualHeight(cardHeader));

		height = height - parseInt(SBUtil.css(_element, 'marginTop')) - parseInt(SBUtil.css(_element, 'marginBottom'));
		height = height - parseInt(SBUtil.css(_element, 'paddingTop')) - parseInt(SBUtil.css(_element, 'paddingBottom'));

		height = height - parseInt(SBUtil.css(cardBody, 'paddingTop')) - parseInt(SBUtil.css(cardBody, 'paddingBottom'));
		height = height - parseInt(SBUtil.css(cardBody, 'marginTop')) - parseInt(SBUtil.css(cardBody, 'marginBottom'));

		height = height - 3;

		SBUtil.css(scroll, 'height', height + 'px');
	}

    // Public methods
	return {
		init: function(id) {
            _element = SBUtil.getById(id);

            if (!_element) {
                return;
            }

            // Initialize
			_init();

            // Re-calculate on window resize
            SBUtil.addResizeHandler(function() {
				_init();
			});
		},

		update: function() {
			_init();
		}
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = SBLayoutStretchedCard;
}

export default SBLayoutStretchedCard;