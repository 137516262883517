import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import setAuthToken from "../../utils/setAuthToken";
// import { getContentTitles } from "../../actions/help";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CustomAccordion from "../../helper/accordion";

import axios from "axios";

const FindAnswers = (props) => {
  // const {  getContentTitles } = props; //content_title,
  const [articles_dropdown, setArticlesDropdown] = React.useState("");
  const [category, setCategory] = React.useState("");

  const [articles, setArticles] = React.useState("");
  const [newCategories, setNewCategories] = React.useState("");

  const [content_based_title, setContent_based_title] = React.useState("");
  let [commentsAnswer, setCommentsAnswer] = React.useState("");

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    axios.get("api/help/getAllArticles")
      .then(res => setArticlesDropdown(res.data))
      .catch(err => console.log(err));
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
  };

  const handleCategoryChange = (category) => {
    setCategory(category);
    getContentBasedOnTitle(category);
  };

  const handleArticleChange = (articles) => {
    setArticles(articles);
    getContentTitle(articles);
    // commentsAnswer = [];
    setCommentsAnswer([]);
    // getContentBasedOnTitle(category);
  };

  async function getContentBasedOnTitle(category) {
    const res = await axios.get("api/help/getAnswers/" + category);
    setContent_based_title(res.data);
  }

  async function getContentTitle(article_id) {
    const res = await axios.get(
      "api/help/getArticleContentTitles/" + article_id
    );
    setNewCategories(res.data);
  }

  return (
    <Fragment>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <div className="card card-custom gutter-b">
                  <div className="card-header border-0 pt-4">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="text-primary">
                        All Answers Submitted
                      </h3>
                      <h5 className="mt-0 font-size-sm text-muted">
                        You can find all answers submitted here
                      </h5>
                    </div>
                </div>
                <div className="card-body pt-1">
                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label className="" htmlFor="article_title">
                            Article Title
                          </label>

                          <select
                            name="article_title"
                            id="article_title"
                            className = "form-control"
                            value={articles}
                            onChange={(event) =>
                              handleArticleChange(event.target.value)
                            }
                          >
                            <option value="">Select</option>
                            {typeof articles_dropdown.articles != "undefined"
                              ? articles_dropdown.articles.map(function (data) {
                                return (
                                  <option key={data._id} value={data._id}>
                                    {data.title}
                                  </option>
                                );
                              })
                              : "Nothing"}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label className="" htmlFor="content_title">
                            Section Title
                          </label>

                          <select
                            name="content_title"
                            id="content_title"
                            className="form-control"
                            value={category}
                            onChange={(event) =>
                              handleCategoryChange(event.target.value)
                            }
                          >
                            <option value="">Select</option>
                            {typeof newCategories.contentTitles != "undefined"
                              ? newCategories.contentTitles.map(function (
                                data
                              ) {
                                return (
                                  <option key={data._id} value={data._id}>
                                    {data.title}
                                  </option>
                                );
                              })
                              : "Nothing"}
                          </select>
                        </div>
                      </div>
                    </div>

                    {typeof content_based_title.msg != "undefined"
                      ? content_based_title.msg.map(function (data, index) {
                        return data === null ? (
                          <p className="questionsStyleDisplay" key={index}>
                            Yes
                          </p>
                        ) : (
                            <CustomAccordion
                              key={data._id}
                              commentTitle={data.comment}
                            />
                          );
                      })
                      : "Please select an option"}

                    {/* If Else conditon */}

                    <div>
                      {typeof commentsAnswer.msg != "undefined"
                        ? commentsAnswer.msg.map(function (comment) {
                          return (
                            <li
                              className="questionsStyleDisplay"
                              key={comment._id}
                            >
                              {comment.comment}{" "}
                            </li>
                          );
                        })
                        : null}
                    </div>

                    <div className="clearfix"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    content_title: state.help.content_title,
  };
};

export default connect(mapStateToProps, {  })(FindAnswers); //getContentTitles
