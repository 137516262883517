import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

import {
  CONTENT_FAIL,
  CONTENT_ADDED,
  ALL_ARTICLES,
  ARTICLES_ERROR,
  GET_CONTENT_TITLES,
  GET_CONTENT_BASED_ON_TITLES,
} from "./types";
// import setAuthToken from "../utils/setAuthToken";

// Get all articles
export const getArticles = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const res = await axios.get("api/help/getAllArticles");
    dispatch({ type: ALL_ARTICLES, payload: res.data });
  } catch (err) {
    dispatch({ type: ARTICLES_ERROR });
  }
};

// Get content titles

export const getContentTitles = () => async (dispatch) => {
  try {
    const res = await axios.get("api/help/getAllQuestionAnswers");
    dispatch({ type: GET_CONTENT_TITLES, payload: res.data });
  } catch (err) {
    dispatch({ type: ARTICLES_ERROR });
  }
};

// Get Content based on titles
export const getContentBasedOnTitle = (content_id) => async (dispatch) => {
  try {
    const res = await axios.get("api/help/getAnswers/");
    dispatch({ type: GET_CONTENT_BASED_ON_TITLES, payload: res.data });
  } catch (err) {
    dispatch({ type: ARTICLES_ERROR });
  }
};

// Post content
export const addContent = (
  author,
  title,
  article_id,
  content,
  commentable,
  status
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    author,
    title,
    article: article_id,
    content,
    commentable,
    status: "publish",
  });
  try {
    const res = await axios.post("api/help/addContent", body, config);
    dispatch({
      type: CONTENT_ADDED,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, "success", 10000));

  } catch (err) {
    const error = err.response.data.error;
    if (error) {
      dispatch(setAlert(error, "danger"));
    }

    dispatch({
      type: CONTENT_FAIL,
    });
  }
};

// export const searchCatalogs = (userId, callback) => async dispatch => {
//   try {
//     const res = await axios.get("api/catalogs/search/" + userId);

//     dispatch({ type: UPLOAD_SUCCESSFUL });

//     callback(res.data.catalogs);
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
//     }

//     dispatch({ type: UPLOAD_FAIL });
//   }
// };
