import axios from 'axios';
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import { META_TAGS_UPDATE, META_TAGS_ERROR, SEARCH_USER_BY_MULTIPLE, SEARCH_USER_BY_MULTIPLE_ERROR, GET_CATEGORY_PAGE_DATA, CATEGORY_DATA_ERROR } from './types';


export const searchCategory = (search, callback) => async dispatch => {
    // console.log('localStorage.getItem("token")',localStorage.getItem('token'));
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        let res = await axios.get("api/category/searchCategory?search=" + search);
        // dispatch({
        //     type: SEARCH_USER_BY_MULTIPLE,
        //     payload: res.data
        // })
        dispatch({ type: SEARCH_USER_BY_MULTIPLE });

        callback(res.data);

    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }

        dispatch({ type: SEARCH_USER_BY_MULTIPLE_ERROR });
    }
};

// Upload File
export const addFormData = (formData) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    // console.log(formData);
    try {
        const res = await axios.post("api/category/add", formData);
        dispatch({ type: META_TAGS_UPDATE });
        //   dispatch(reset())
        dispatch({ type: 'CATEGORY_FORM_RESET' });
        dispatch(setAlert(res.data.message, res.data.status, 10000));
    } catch (err) {
        // console.log(err);
        const errors = err.response.data.errors;
  
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({ type: META_TAGS_ERROR });
    }
};


export const addFormCatalog = (formData) => async dispatch => {
if (localStorage.token) {
    setAuthToken(localStorage.token);
}
// console.log(formData);
try {
    const res = await axios.post("api/catalogs/add", formData);
    dispatch({ type: META_TAGS_UPDATE });
    //   dispatch(reset())
    dispatch({ type: 'CATEGORY_FORM_RESET' });
    dispatch(setAlert(res.data.message, res.data.error?res.data.status?'warning': 'danger':'info', 10000));
} catch (err) {
    // console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({ type: META_TAGS_ERROR });
}
};

export const updateFormProductPrice = (formData) => async dispatch => {
  if (localStorage.token) {
      setAuthToken(localStorage.token);
  }
//   console.log(formData);
  try {
      const res = await axios.post("api/product/updateProductPrice", formData);
      dispatch({ type: META_TAGS_UPDATE });
      //   dispatch(reset())
      dispatch({ type: 'CATEGORY_FORM_RESET' });
      dispatch(setAlert(res.data.message, res.data.status, 10000));
  } catch (err) {
      // console.log(err);
      const errors = err.response.data.errors;

      if (errors) {
          errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({ type: META_TAGS_ERROR });
  }
};


export const addCatFormData = (formData) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.post("api/category/update", formData);
        dispatch({ type: META_TAGS_UPDATE });
        //   dispatch(reset())
        dispatch({ type: 'CATEGORY_FORM_RESET' });
        dispatch(setAlert(res.data.message, res.data.status, 10000));
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({ type: META_TAGS_ERROR });
    }
};

export const getCategoryByName = (search, callback) => async dispatch => {
    try {
        let res = await axios.get("api/category/getCategoryByName?id=" + search);
        if (!localStorage.token) {
        setAuthToken(localStorage.token);
        }
        dispatch({
        type: GET_CATEGORY_PAGE_DATA,
        payload: res.data
        })

    } catch (err) {
      // const errors = err.response.data.errors;
      // if (errors) {
      //     errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      // }
        dispatch({ type: CATEGORY_DATA_ERROR });
    }
};

// export const getCategoryById = (id) => async dispatch => {
//   try {
//        let res = await axios.get(`/api/staticPages/getSelectedPageData/${id}`);
//        dispatch({
//             type: GET_STATIC_PAGE_DATA,
//             payload: res.data
//        })
//   } catch (error) {
//        dispatch({
//             type: STATIC_DATA_ERROR
//        })
//   }
// }