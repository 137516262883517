import React, { useEffect } from "react";
import axios from "axios";

const EditArticle = ({ articleId, handleBack }) => {
  const [formData, setFormData] = React.useState({ article_id: "", title: "" });
  let { title } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();
    updateArticle(articleId, title);
  };

  useEffect(() => {
    async function getArticle() {
      const res = await axios.get("api/help/getArticle/" + articleId);
      setFormData(res.data.article);
    }

    getArticle();
  },[articleId]); ///, []

  const onChange = (e) => {
    return setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  async function updateArticle(article_id, title) {
    const body = JSON.stringify({ article_id, title });

    await axios({
      method: "post",
      url: "api/help/updateArticle",
      data: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
    handleBack();
  }

  return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <div className="card card-custom gutter-b">
                  <div className="card-header border-0 pt-4">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="text-primary">
                        Edit Article
                      </h3>
                      <h5 className="mt-0 font-size-sm text-muted">
                        You can update your article here
                      </h5>
                    </div>
                </div>
                <div className="card-body">
                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label className="bmd-label-floating">
                            Articles Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            htmlFor="article"
                            name="title"
                            value={title}
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mr-4">
                    <button onClick={handleBack} className="btn d-flex align-items-center btn-default">
                      <i className="fa fa-chevron-left" />
                      BACK{" "}
                    </button>
                      <div className="row justify-content-end">
                        <button
                          type="submit"
                          className='btn btn-primary'
                        >
                          <div className="d-flex align-content-center">
                            <i className="fas fa-save pr-2"></i>
                            <span>Save Article</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default EditArticle;
