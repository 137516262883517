import React, { useState, useRef } from "react";//, useEffect
import { connect } from "react-redux";
// import EditorContainer from "../../helper/editor";
import { Editor } from '@tinymce/tinymce-react';
import { addContent, getArticles } from "../../actions/help";

let flagCount = 1;
const Help = (props) => {
  const { articles, getArticles, addContent } = props;
  
  const editorRef = useRef(null);

  // GET ARTICLES
  const [formData, setFormData] = useState({
    author: "",
    title: "",
    article_id: "",
    content: "",
    commentable: "",
    status: "publish",
  });
  let { author, title, article_id, content, commentable, status } = formData;
  const [category, setCategory] = useState("");
  const [html, setHtml] = useState("");

  if (flagCount === 1) {
    getArticles();
    flagCount = 0;
  }

  // Functions react

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    article_id = category;
    commentable = 'false';
    content = html;
    commentable = false; // Added new line

    addContent(author, title, article_id, content, commentable, status);
    // Reset form
    setFormData({
      title: "",
      author: "",
      content: "",
      article_id: "",
      commentable: "",
    });
  };

  const handleCategoryChange = (category) => {
    setCategory(category);
  };

  // function test(check) {
  //   setHtml(check);
  // }

  function ChangeContent(e) {
    setHtml(e.target.getContent());
  }

  return (

        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-12">
            <div className="card card-custom gutter-b">
                  <div className="card-header border-0 pt-4">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="text-primary">
                        Add content to Articles
                      </h3>
                      <h5 className="mt-0 font-size-sm text-muted">
                        You can add your content here
                      </h5>
                    </div>
                </div>
                <div className="card-body pt-0">
                  <form className="form" onSubmit={(e) => onSubmit(e)}>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label className="bmd-label-floating" htmlFor="article_id">
                            Articles Name
                          </label>

                          <select
                            name="article_id"
                            id="article_id"
                            className="form-control"
                            value={category ? category : ""}
                            onChange={(event) =>
                              handleCategoryChange(event.target.value)
                            }
                          >
                            <option value="">Select</option>
                            {articles.map(function (data) {
                              return (
                                <option key={data._id} value={data._id}>
                                  {data.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label className="bmd-label-floating" 
                            htmlFor="author"
                            >
                            Enter Author Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="author"
                            name="author"
                            value={author}
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label 
                          className="bmd-label-floating"
                          htmlFor="content-title"
                          >Title</label>
                          <input
                            type="text"
                            className="form-control"
                            id="content-title"
                            name="title"
                            value={title}
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="col-md">
                        <label className="bmd-label-floating"
                            htmlFor="content"
                            >Content</label>
                        <EditorContainer test={test} 
                            id="content"
                            />
                      </div>
                    </div> */}


                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label htmlFor="contnt" style={{ fontWeight: 'bold', fontSize: 14 }}> Content </label>
                      </div>
                    </div>
                    <Editor
                      apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                      onInit={(evt, editor) => editorRef.current = editor}
                      initialValue={formData.content}
                      init={{
                        max_height: 200,
                        menubar: false,
                        // forced_root_block: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | preview',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
                      }}
                      onChange={ChangeContent}
                    />
                  </div> 

                  <div className="form-group">
                    <button
                      className='btn btn-primary  pull-right'
                      style={{ float: 'right', marginRight: '0.1rem' }}
                    >
                      <div className="d-flex align-content-center">
                        <i className="fas fa-save pr-2"></i>
                        <span>Update</span>
                      </div>
                    </button>
                  </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

  );
};

const mapStateToProps = (state) => {
  return {
    articles: state.help.articles,
  };
};

export default connect(mapStateToProps, { addContent, getArticles })(Help);
