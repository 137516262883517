/* eslint-disable */
"use strict";

import SBToggle from "../../components/toggle.js";
import SBLayoutStickyCard from "./sticky-card.js";
import SBLayoutAsideMenu from "./aside-menu.js";
import { SBCookie } from "../../components/cookie.js";
import { SBUtil } from "../../components/util.js";

var SBLayoutAsideToggle = function() {
    // Private properties
    var _body;
    var _element;
    var _toggleObject;
    let _initialized = false;
    const _initCallBacks = []
	// Initialize
	var _init = function() {
		_toggleObject = new SBToggle(_element, _body, {
			targetState: 'aside-minimize',
			toggleState: 'aside-toggle-active'
		});

		_toggleObject.on('toggle', function(toggle) {
            // Update sticky card
            if (typeof SBLayoutStickyCard !== 'undefined') {
                setTimeout(function() {
                    SBLayoutStickyCard.update();
                }, 500);
            }

            // Pause header menu dropdowns
            if (typeof SBLayoutHeaderMenu !== 'undefined') {
                SBLayoutHeaderMenu.pauseDropdownHover(800);
            }

            // Pause aside menu dropdowns
            if (typeof SBLayoutAsideMenu !== 'undefined') {
                SBLayoutAsideMenu.pauseDropdownHover(800);
            }

            // Remember state in cookie
			SBCookie.setCookie('sbt_aside_toggle_state', toggle.getState());
			// to set default minimized left aside use this cookie value in your
			// server side code and add "sbt-primary--minimize aside-minimize" classes to
			// the body tag in order to initialize the minimized left aside mode during page loading.
		});

		_toggleObject.on('beforeToggle', function(toggle) {
			//if (SBUtil.hasClass(_body, 'aside-minimize') === false && SBUtil.hasClass(_body, 'aside-minimize-hover')) {
			//	SBUtil.removeClass(_body, 'aside-minimize-hover');
			//}
		});
	}

    // Public methods
	return {
		init: function(id) {
            _element = SBUtil.getById(id);
            _body = SBUtil.getBody();

            if (!_element) {
                return;
            }

            // Initialize
            _init();
            _initialized = true;
            for(let cb of _initCallBacks){
                cb?.(_toggleObject);
            }
		},
        isInitialized : ()=>_initialized,
        onInit : (handle)=>{_initCallBacks.push(handle)},
        getElement: function() {
            return _element;
        },

        getToggle: function() {
			return _toggleObject;
		},

		onToggle: function(handler) {
			if (typeof _toggleObject.element !== 'undefined') {
				_toggleObject.on('toggle', handler);
			}
		}
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = SBLayoutAsideToggle;
}

export default SBLayoutAsideToggle;