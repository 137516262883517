import React, { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import { useSubheader } from "../../_seebizsol/layout";
import axios from 'axios';

const CreateUser = ({ setAlert, register, isAuthenticated }) => {
  const subheader = useSubheader();
  subheader.setTitle("Create User Account")
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    role: "",
  });

  const { name, email, password, password2, role } = formData;


  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('JSON.stringify(formData', JSON.stringify(formData));
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (name === '' || email === '' || password === '' || password2 === '' || role === '') {
      setAlert("Please Fill All Required Fields", "danger");
    } else if (password !== password2) {
      setAlert("Passwords did not match", "danger");
    } else if (password.length < 6) {
      setAlert("Please enter a password with 6 or more characters ", "danger");
    } else if (!email.match(validRegex)) {
      setAlert("Please enter a Valid Email address ", "danger");
    } else {
      // register({ name, email, password, role });
      const res = await axios.post("/api/users/creatUser", formData);
      // console.log("res ", res.data);
      if (res.data.status === true) {
        setAlert(res.data.msg, 'success', 10000);
        setFormData({
          name: "",
          email: "",
          password: "",
          password2: "",
          role: "",
        })
      } else {
        setAlert(res.data.msg, 'danger', 10000);
      }
    }
  };

  return (
    <div className="container-fluid container-lg">
      <div className="row justify-content-center">
        <div className="col-md-8 mt-2">
          <div className="card card-custom">
            <div className="card-header border-0 pt-4">
              <div className="card-title align-items-start flex-column">
                <h3 className="text-primary">
                  Create User Account
                </h3>
                <h5 className="mt-0 font-size-sm text-muted">
                  Now you can create it
                </h5>
              </div>
            </div>
            <div className="card-body pt-1">
              <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label className="bmd-label-floating">
                        Enter Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label className="bmd-label-floating">
                        Email address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label className="bmd-label-floating"
                      >Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label className="bmd-label-floating">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password2"
                        value={password2}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label className="bmd-label-floating">
                        Select User Role <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="role"
                        value={role}
                        onChange={(e) => onChange(e)}
                      >
                        <option value="">* Select User Role</option>
                        <option value="basic_user">Basic User</option>
                        <option value="sem_user">SEM User</option>
                        <option value="dataentry_user">Data Entry User</option>
                        <option value="readOnly_user">Read Only User</option>
                        <option value="seomanager_user">SEO Manager</option>
                        <option value="helpdesk_user">Help Desk User</option>
                        <option value="admin_readOnly">Admin Read Only</option>
                      </select>
                      <small className="form-text">
                        Give you an idea of what a user can perform
                      </small>
                    </div>
                  </div>
                </div>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Create User"
                />
                <div className="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateUser.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, { setAlert, register })(CreateUser);
