/* eslint-disable */
"use strict";

import { SBUtil } from "../../components/util.js";
import SBOffcanvas from "../../components/offcanvas.js";

var SBLayoutQuickActions = function() {
    // Private properties
    var _element;
    var _offcanvasObject;

    // Private functions
    var _init = function() {
        var header = SBUtil.find(_element, '.offcanvas-header');
        var content = SBUtil.find(_element, '.offcanvas-content');

        _offcanvasObject = new SBOffcanvas(_element, {
            overlay: true,
            baseClass: 'offcanvas',
            placement: 'right',
            closeBy: 'sbt_quick_actions_close',
            toggleBy: 'sbt_quick_actions_toggle'
        });

        SBUtil.scrollInit(content, {
            disableForMobile: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                var height = parseInt(SBUtil.getViewPort().height);

                if (header) {
                    height = height - parseInt(SBUtil.actualHeight(header));
                    height = height - parseInt(SBUtil.css(header, 'marginTop'));
                    height = height - parseInt(SBUtil.css(header, 'marginBottom'));
                }

                if (content) {
                    height = height - parseInt(SBUtil.css(content, 'marginTop'));
                    height = height - parseInt(SBUtil.css(content, 'marginBottom'));
                }

                height = height - parseInt(SBUtil.css(_element, 'paddingTop'));
                height = height - parseInt(SBUtil.css(_element, 'paddingBottom'));

                height = height - 2;

                return height;
            }
        });
    }

    // Public methods
    return {
        init: function(id) {
            _element = SBUtil.getById(id);

            if (!_element) {
                return;
            }

            // Initialize
            _init();
        },

        getElement: function() {
            return _element;
        }
    };
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = SBLayoutQuickActions;
}

export default SBLayoutQuickActions;