import {
     STATIC_DATA_ADDED,
     STATIC_DATA_ERROR,
     STATIC_DATA_CHANGED,
     GET_STATIC_DATA,
     GET_STATIC_PAGE_DATA
} from '../actions/types';

// const initialState = [];

export default function (state = {}, action) {
     const { type, payload } = action;
     switch (type) {
          case STATIC_DATA_ADDED:
               return {
                    ...state,
                    ...payload
               }



          case STATIC_DATA_ERROR:
               return {
                    ...state,
                    // staticPages: 
               }



          case STATIC_DATA_CHANGED:
               return {
                    ...state,
                    ...payload
               }

          case GET_STATIC_DATA:
               let newState = Object.assign(state, payload.staticPages);
               return {
                    ...newState
               }


          case GET_STATIC_PAGE_DATA:
               // let State = Object.assign(state, staticPage: payload.payload);
               return {
                    ...state,
                    staticPage: payload.payload
               }
          default:
               return state;
     }
}

