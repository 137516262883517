export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const REDIRECT_LOGOUT = 'REDIRECT_LOGOUT';
export const UPLOAD_SUCCESSFUL = 'UPLOAD_SUCCESSFUL';
export const UPLOAD_FAIL = 'UPLOAD_FAIL';
export const VERIFICATION_LINK_SEND = 'VERIFICATION_LINK_SEND';
export const VERIFICATION_LINK_NOT_SEND = 'VERIFICATION_LINK_NOT_SEND';
export const PASSWORD_UPDATED = 'PASSWORD_UPDATED';
export const PASSWORD_NOT_SET = 'PASSWORD_NOT_SET';
export const LOAD_ALLUSERS = 'LOAD_ALLUSERS';
export const LOAD_ALLUSERS_ERROR = 'LOAD_ALLUSERS_ERROR';
// Help
export const CONTENT_ADDED = 'CONTENT_ADDED';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const CONTENT_FAIL = 'CONTENT_FAIL';
export const ALL_ARTICLES = 'ALL_ARTICLES';
export const ARTICLES_ERROR = 'ARTICLES_ERROR';
export const FIND_ANSWERS = 'FIND_ANSWERS';
export const GET_CONTENT_TITLES = 'GET_CONTENT_TITLES';
export const GET_CONTENT_BASED_ON_TITLES = 'GET_CONTENT_BASED_ON_TITLES';

/** static pages  */
export const STATIC_DATA_ADDED = 'STATIC_DATA_ADDED';
export const STATIC_DATA_ERROR = 'STATIC_DATA_ERROR';
export const STATIC_DATA_CHANGED = 'STATIC_DATA_CHANGED';
export const GET_STATIC_DATA = 'GET_STATIC_DATA';
export const GET_STATIC_PAGE_DATA = 'GET_STATIC_PAGE_DATA';

/** uploaded files  */
export const UPLOADED_FILES_LISTING = 'UPLOADED_FILES_LISTING';
export const UPLOADED_FILES_ERROR   = 'UPLOADED_FILES_ERROR';

/** meta tags  */
export const META_TAGS_UPDATE = 'META_TAGS_UPDATE';
export const META_TAGS_ERROR   = 'META_TAGS_ERROR';
export const SEARCH_USER_BY_MULTIPLE   = 'SEARCH_USER_BY_MULTIPLE';
export const SEARCH_USER_BY_MULTIPLE_ERROR   = 'SEARCH_USER_BY_MULTIPLE_ERROR';

/** product listing  */
export const ALL_PRODUCTS_LISTING = 'ALL_PRODUCTS_LISTING';
export const ALL_PRODUCTS_ERROR   = 'ALL_PRODUCTS_ERROR';

/** business info  */

export const BUSINESS_INFO_UPDATE = 'BUSINESS_INFO_UPDATE';
export const BUSINESS_INFO_ERROR   = 'BUSINESS_INFO_ERROR';
// export const SEARCH_USER_BY_MULTIPLE   = 'SEARCH_USER_BY_MULTIPLE';
// export const SEARCH_USER_BY_MULTIPLE_ERROR   = 'SEARCH_USER_BY_MULTIPLE_ERROR';


/** edit category  */
export const CATEGORY_FORM_RESET   = 'CATEGORY_FORM_RESET';
export const CATEGORY_DATA_ADDED = 'CATEGORY_DATA_ADDED';
export const CATEGORY_DATA_ERROR = 'CATEGORY_DATA_ERROR';
export const CATEGORY_DATA_CHANGED = 'CATEGORY_DATA_CHANGED';
export const GET_CATEGORY_DATA = 'GET_CATEGORY_DATA';
export const GET_CATEGORY_PAGE_DATA = 'GET_CATEGORY_PAGE_DATA';