import React, { Component } from "react";
import {
     EditorState,
     convertFromHTML,
     convertToRaw,
     ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { withRouter } from "react-router";

class StaticEditorTextArea extends Component {
     constructor(props) {
          super(props);
          this.state = {
               editorState: EditorState.createEmpty(),
          };
     }

     onEditorStateChange = (editorState) => {

          this.setState({
               editorState,
          });
     };

     componentDidMount() {
          let { test } = this.props;
          let blocksFromHTML = convertFromHTML(test);
          let state = ContentState.createFromBlockArray(
               blocksFromHTML.contentBlocks,
               blocksFromHTML.entityMap
          );
          this.setState({
               editorState: EditorState.createWithContent(state),
          });
     }

     render() {
          let { editorState } = this.state;
          let { edited } = this.props;
          return (
               <div className="editor">
                    <Editor
                         editorState={editorState}
                         onEditorStateChange={this.onEditorStateChange}
                         toolbar={{
                              options: [
                                   "inline",
                                   "blockType",
                                   "fontSize",
                                   "list",
                                   "textAlign",
                                   "link",
                                   "remove",
                                   "history",
                              ],
                              inline: { inDropdown: true },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                         }}
                    />
                    <hr />
                    <div className="row justify-content-between mx-1">
                         <button 
                              type="button"
                              onClick={this.props.history.goBack}
                              className="btn btn-light d-flex align-items-center"
                         ><i className="fa fa-chevron-left pr-2"/>Back</button>
                    <button
                         type="submit"
                         className="btn btn-primary d-flex align-items-center"
                         onClick={(e) => {
                              edited(draftToHtml(convertToRaw(editorState.getCurrentContent())));
                         }}
                    ><i className="fa fa-save pr-2"/>
                         Save
                    </button>
                    </div>
               </div>
          );
     }
}
export default withRouter(StaticEditorTextArea);
