import React, { useLayoutEffect } from "react";
import { SBUtil } from "../../_assets/js/components/util";
import SBLayoutAside from "../../_assets/js/layout/base/aside";
import SBLayoutAsideMenu from "../../_assets/js/layout/base/aside-menu";
import SBLayoutContent from "../../_assets/js/layout/base/content";
import SBLayoutFooter from "../../_assets/js/layout/base/footer";
import SBLayoutSubheader from "../../_assets/js/layout/base/subheader";
import SBLayoutScrolltop from "../../_assets/js/layout/extended/scrolltop";
import SBLayoutStickyCard from "../../_assets/js/layout/base/sticky-card";
import SBLayoutStretchedCard from "../../_assets/js/layout/base/stretched-card";
import SBLayoutQuickPanel from "../../_assets/js/layout/extended/quick-panel";
import SBLayoutQuickUser from "../../_assets/js/layout/extended/quick-user";
import SBLayoutHeaderMobile from "../../_assets/js/layout/base/header-mobile";
import SBLayoutAsideToggle from "../../_assets/js/layout/base/aside-toggle";
import SBLayoutQuickSearch from "../../_assets/js/layout/extended/quick-search";
import SBLayoutQuickNotifications from "../../_assets/js/layout/extended/quick-notifications";
import SBLayoutQuickActions from "../../_assets/js/layout/extended/quick-actions";

export function LayoutInit() {
  useLayoutEffect(() => {
    // Initialization
    SBUtil.ready(function() {
      ////////////////////////////////////////////////////
      // Layout Base Partials(mandatory for core layout)//
      ////////////////////////////////////////////////////

      // Init Mobile Header
      SBLayoutHeaderMobile.init("sbt_header_mobile");

      // Init Aside
      SBLayoutAside.init("sbt_aside");

      // Init Aside Toggle
      SBLayoutAsideToggle.init("sbt_aside_toggle");

      // Init Aside Menu
      SBLayoutAsideMenu.init("sbt_aside_menu");

       // Init Aside Menu2
      SBLayoutAsideMenu.init("sbt_aside_menu2");

      // Init Content
      SBLayoutContent.init("sbt_content");

      // Init Footer
      SBLayoutFooter.init("sbt_footer");

      //////////////////////////////////////////////
      // Layout Extended Partials(optional to use)//
      //////////////////////////////////////////////

      // Init subheader
      SBLayoutSubheader.init("sbt_subheader");

      // Init Scrolltop
      SBLayoutScrolltop.init("sbt_scrolltop");

      // Init Sticky Card
      SBLayoutStickyCard.init("sbt_page_sticky_card");

      // Init Stretched Card
      SBLayoutStretchedCard.init("sbt_page_stretched_card");

      // Init Quick Actions Offcanvas Panel
      SBLayoutQuickActions.init("sbt_quick_actions");

      // Init Quick Offcanvas Panel
      SBLayoutQuickPanel.init("sbt_quick_panel");

      // Init Quick User Panel
      SBLayoutQuickUser.init("sbt_quick_user");

      // Init Quick Search Panel
      SBLayoutQuickSearch.init("sbt_quick_search");

      // Init Quick Notifications Offcanvas Panel
      SBLayoutQuickNotifications.init("sbt_quick_notifications");
    });
  }, []);
  return <></>;
}
